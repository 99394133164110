import React, {useEffect, useState} from 'react';
import {
    Button,
    DatePicker,
    Descriptions,
    Drawer,
    Form,
    Input,
    InputNumber,
    Modal,
    Popconfirm,
    Select,
    Space,
    Tag,
    Typography
} from 'antd';
import {formatRupee,} from '../../utils/utils';
import {
    approvePettyCashTransaction,
    cancelPettyCashTransaction,
    closePettyCashTransaction,
    issuePettyCashTransaction,
    rejectPettyCashTransaction,
} from "./cashTransactionsAPI";
import {formatDate} from "../../utils/dateUtils";
import moment from "moment";
import {fetchCashAccountsApi} from "../common/commonApis";

const {Option} = Select;
const {TextArea} = Input;
const {Text} = Typography;


const PettyCashTransactionDetailsDrawer = ({
                                               visible,
                                               onClose,
                                               transaction,
                                               refreshTransactions,
                                               setErrorMessage,
                                               setSuccessMessage,
                                           }) => {

    const [isRejectModalVisible, setIsRejectModalVisible] = useState(false);
    const [isIssueModalVisible, setIsIssueModalVisible] = useState(false);
    const [bankAccounts, setBankAccounts] = useState([]);

    const [form] = Form.useForm();
    const [issueForm] = Form.useForm();
    const user = JSON.parse(localStorage.getItem('user')); // Retrieve user data
    const userRole = user?.user_role;


    useEffect(() => {
        const fetchBankAccounts = async (selectedCompanyId) => {
            try {
                const accounts = await fetchCashAccountsApi(selectedCompanyId);
                console.log("accounts: ", accounts);
                const filteredAccounts = accounts.filter(account =>
                    account.account_sub_type_name.toLowerCase().includes('bank account')
                );
                console.log("filteredAccounts: ", filteredAccounts);
                setBankAccounts(filteredAccounts);
            } catch (error) {
                setErrorMessage('Failed to fetch bank accounts');
            }
        };

        if (isIssueModalVisible && transaction) {
            fetchBankAccounts(transaction.company_id);
        }
    }, [isIssueModalVisible, transaction]);


    const handleApprove = async () => {
        try {
            await approvePettyCashTransaction(transaction.id);
            setSuccessMessage('Transaction approved');
            refreshTransactions();
            onClose();
        } catch (error) {
            setErrorMessage('Failed to approve transaction');
        }
    };

    const handleTransactionClose = async () => {
        try {
            await closePettyCashTransaction(transaction.id);
            setSuccessMessage('Transaction closed');
            refreshTransactions();
            onClose();
        } catch (error) {
            setErrorMessage('Failed to close transaction');
        }
    };

    const showIssueModal = () => {
        setIsIssueModalVisible(true);
    };

    const handleIssue = async (values) => {
        const {bankAccount, amountIssued, transactionDate, transactionNumber} = values;
        try {
            // Here, implement your API call function to issue the petty cash
            // For example:
            await issuePettyCashTransaction(transaction.id, {
                bankAccount,
                amountIssued,
                transactionDate: transactionDate.format('YYYY-MM-DD'),  // Ensure you format the date correctly
                transactionNumber
            });
            setSuccessMessage('Petty cash issued successfully');
            refreshTransactions();
            onClose();
            setIsIssueModalVisible(false);
        } catch (error) {
            setErrorMessage('Failed to issue petty cash');
        }
    };


    const showRejectModal = () => {
        setIsRejectModalVisible(true);
    };

    const handleReject = async (values) => {
        try {
            // values will contain the form fields
            const {rejectionReason} = values;
            if (!rejectionReason.trim()) {
                setErrorMessage('Rejection reason is required');
                return;
            }
            await rejectPettyCashTransaction(transaction.id, rejectionReason);
            setSuccessMessage('Transaction rejected');
            refreshTransactions();
            onClose();
            setIsRejectModalVisible(false);
        } catch (error) {
            setErrorMessage('Failed to reject transaction');
        }
    };

    const handleCancel = async () => {
        try {
            await cancelPettyCashTransaction(transaction.id);
            setSuccessMessage('Transaction canceled');
            refreshTransactions();
            onClose();
        } catch (error) {
            setErrorMessage('Failed to cancel transaction');
        }
    };

    // Decide which buttons to show based on the status
    const showActionButtons = () => {
        if (!transaction || !userRole) {
            return null; // or appropriate fallback UI elements
        }

        // Define roles that are allowed to approve, reject, or issue petty cash
        const canModifyTransaction = ['super_admin', 'admin', 'back_office', 'accounts_manager'].includes(userRole);


        switch (transaction.status) {
            case 'pending':
                return (
                    <>
                        {canModifyTransaction && (
                            <>
                                <Popconfirm
                                    title="Are you sure you want to approve this transaction?"
                                    onConfirm={handleApprove}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <Button type="primary">Approve</Button>
                                </Popconfirm>
                                <Button danger onClick={showRejectModal}>Reject</Button>
                            </>
                        )}

                        <Popconfirm
                            title="Are you sure you want to cancel this transaction?"
                            onConfirm={handleCancel}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button>Cancel</Button>
                        </Popconfirm>
                    </>
                );
            case 'approved':
                if (!transaction.transaction_date) {
                    return (
                        <>
                            {canModifyTransaction && (
                                <Button type="primary" onClick={showIssueModal}>Issue Petty Cash</Button>
                            )}
                            <Popconfirm
                                title="Are you sure you want to cancel this transaction?"
                                onConfirm={handleCancel}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button>Cancel</Button>
                            </Popconfirm>
                        </>
                    );
                } else {
                    // Transaction date is set, show the Close button
                    return (
                        <Popconfirm
                            title="Are you sure you want to close this transaction?"
                            onConfirm={handleTransactionClose}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button>Close</Button>
                        </Popconfirm>
                    );
                }
            case 'rejected':
                return <Popconfirm
                    title="Are you sure you want to cancel this transaction?"
                    onConfirm={handleCancel}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button>Cancel</Button>
                </Popconfirm>;
            default:
                return null; // Handles unexpected statuses.
        }
    };

    return (
        <Drawer
            title="Petty Cash Transaction Details"
            width={720}
            onClose={onClose}
            open={visible}
            extra={
                <Space>
                    {showActionButtons()}
                </Space>
            }
        >
            {transaction ? (
                <Descriptions layout="vertical" bordered size="small" column={2}>
                    <Descriptions.Item label="Transaction ID" span={1}>{transaction.id}</Descriptions.Item>
                    <Descriptions.Item label="Status" span={1}>
                        <Tag
                            color={transaction.status === 'approved' ? 'green' : transaction.status === 'rejected' ? 'red' : 'geekblue'}>
                            {transaction.display_status}
                        </Tag>
                    </Descriptions.Item>
                    <Descriptions.Item label="Request Date"
                                       span={1}>{formatDate(transaction.request_date)}</Descriptions.Item>
                    <Descriptions.Item label="Requested By" span={1}>{transaction.requested_by_name}</Descriptions.Item>
                    <Descriptions.Item label="Branch">{transaction.company_name}</Descriptions.Item>
                    <Descriptions.Item label="Branch">{transaction.branch_name}</Descriptions.Item>
                    <Descriptions.Item
                        label="Requested Amount">{formatRupee(transaction.amount_requested)}</Descriptions.Item>
                    <Descriptions.Item
                        label="Issued Amount">{formatRupee(transaction.amount_issued || 0)}</Descriptions.Item>
                    <Descriptions.Item
                        label="Tranasction Date">{transaction.transaction_date ? formatDate(transaction.transaction_date) : 'N/A'}</Descriptions.Item>
                    <Descriptions.Item
                        label="Transaction Number">{transaction.transaction_number || 'N/A'}</Descriptions.Item>
                    <Descriptions.Item
                        label="Approval Date">{transaction.approval_date ? formatDate(transaction.approval_date) : 'N/A'}</Descriptions.Item>
                    <Descriptions.Item label="Approved By">{transaction.approved_by_name || 'N/A'}</Descriptions.Item>
                    <Descriptions.Item
                        label="Reason for Rejection">{transaction.reason ? transaction.reason : 'N/A'}</Descriptions.Item>
                </Descriptions>
            ) : (
                <p>No transaction details available.</p>
            )}
            <Modal
                title="Reject Transaction"
                open={isRejectModalVisible}
                onCancel={() => setIsRejectModalVisible(false)}
                footer={[
                    <Button key="back" onClick={() => setIsRejectModalVisible(false)}>
                        Cancel
                    </Button>,
                    <Button key="submit" type="primary" form="rejectForm" htmlType="submit">
                        Reject
                    </Button>
                ]}
            >
                <Form
                    id="rejectForm"
                    form={form}
                    layout="vertical"
                    onFinish={handleReject}
                    initialValues={{rejectionReason: ''}}
                >
                    <Form.Item
                        name="rejectionReason"
                        label="Reason for Rejection"
                        rules={[{required: true, message: 'Please enter a reason for rejection'}]}
                    >
                        <TextArea rows={4} placeholder="Enter the reason for rejection"/>
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                title="Issue Petty Cash"
                open={isIssueModalVisible}
                onCancel={() => setIsIssueModalVisible(false)}
                footer={[
                    <Button key="back" onClick={() => setIsIssueModalVisible(false)}>Cancel</Button>,
                    <Button key="submit" type="primary" form="issueForm" htmlType="submit">Issue</Button>
                ]}
            >
                <Form
                    id="issueForm"
                    form={issueForm}
                    layout="vertical"
                    onFinish={handleIssue}
                    initialValues={{
                        bankAccount: '',
                        amountIssued: '',
                        transactionDate: '',
                        transactionNumber: ''
                    }}
                >
                    {transaction && transaction.amount_requested !== undefined && (
                        <div style={{marginBottom: '16px'}}>
                            <Text strong style={{fontSize: '16px', color: '#cd821f'}}>
                                Requested Amount: {formatRupee(transaction.amount_requested || 0)}
                            </Text>
                        </div>
                    )}

                    <Form.Item
                        name="bankAccount"
                        label="Bank Account"
                        rules={[{required: true, message: 'Bank account is required'}]}
                    >
                        <Select placeholder="Select bank account" allowClear>
                            {bankAccounts.map((acct) => (
                                <Option key={acct.id} value={acct.id}>
                                    {acct.account_name}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        name="amountIssued"
                        label="Amount Issued"
                        rules={[
                            {required: true, message: 'Please input the amount issued'},
                            () => ({
                                validator(_, value) {
                                    if (!value || transaction.amount_requested >= value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('Amount issued cannot be greater than the amount requested',));
                                }
                            })
                        ]}
                    >
                        <InputNumber min={0} prefix="Rs." suffix="INR" style={{width: '100%'}}/>
                    </Form.Item>
                    <Form.Item
                        name="transactionDate"
                        label="Transaction Date"
                        rules={[
                            {required: true, message: 'Transaction date is required'},
                            () => ({
                                validator(_, value) {
                                    if (value && value.isAfter(moment(), 'day')) {
                                        return Promise.reject(new Error('Transaction date cannot be in the future'));
                                    }
                                    return Promise.resolve();
                                }
                            })
                        ]}
                    >
                        <DatePicker style={{width: '100%'}} format="DD-MM-YYYY"/>
                    </Form.Item>
                    <Form.Item
                        name="transactionNumber"
                        label="Transaction Number"
                        rules={[{required: true, message: 'Transaction number is required'}]}
                    >
                        <Input/>
                    </Form.Item>
                </Form>
            </Modal>


        </Drawer>
    );
};

export default PettyCashTransactionDetailsDrawer;

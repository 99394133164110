import React, { useEffect, useState } from 'react';
import { Button, Select, Input, Table, Tag, Spin } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import InventoryInboundTable from "./InventoryInboundTable";
import {fetchInventoryInboundApi} from "./inventoryAPI";  // Assume API utility is implemented

const { Search } = Input;
const { Option } = Select;

const InventoryInboundList = () => {
    const [inbounds, setInbounds] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
    const [searchTerm, setSearchTerm] = useState('');

    // Fetch inventory inbound records
    const fetchInbounds = async (page = 1) => {
        setLoading(true);
        try {
            const data = await fetchInventoryInboundApi(page, searchTerm);
            setInbounds(data.results);
            setPagination((prev) => ({
                ...prev,
                total: data.count,
                current: page,
            }));
        } catch (error) {
            console.error('Failed to fetch inventory inbound data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchInbounds(pagination.current);
    }, [pagination.current, searchTerm]);

    const handleTableChange = (pagination) => {
        setPagination({ current: pagination.current, pageSize: pagination.pageSize });
    };

    const handleSearch = (value) => {
        setSearchTerm(value);
        setPagination({...pagination, current: 1});
    };

    return (
        <div>
            <Search
                placeholder="Search by item or receipt type..."
                enterButton={<SearchOutlined />}
                size="large"
                onSearch={handleSearch}
                style={{ width: 400, marginBottom: 20 }}
            />
            <InventoryInboundTable
                data={inbounds}
                loading={loading}
                pagination={pagination}
                onTableChange={handleTableChange}
            />
        </div>
    );
};

export default InventoryInboundList;

import React, {useEffect, useState} from 'react';
import {
    Badge,
    Button,
    Descriptions,
    Drawer,
    Empty,
    Form,
    Input,
    message,
    Modal,
    Spin,
    Table,
    Tag,
    Tooltip,
    Typography
} from 'antd';
import {
    fetchPurchaseReceiptDetailsApi,
    fetchPurchaseReceiptSerialNumbersApi,
    recordSerialNumbersApi,
    updateInvoiceDetailsApi
} from './procurementApi';
import {formatRupee, truncateText} from '../../utils/utils';
import {formatDate, formatDateTime} from '../../utils/dateUtils';
import {QrcodeOutlined} from "@ant-design/icons";
import ReceiptActionsForDetailsDrawer from "./ReceiptActionsForDetailsDrawer";
import UpdateInvoiceModal from "./UpdateInvoiceModal";

const {Text, Title} = Typography;

const PurchaseReceiptDetailsDrawer = ({
                                          visible,
                                          receiptId,
                                          onClose,
                                          refreshReceipts,
                                          setErrorMessage,
                                          setSuccessMessage,
                                          handleEditReceipt,
                                      }) => {
    const [receiptDetails, setReceiptDetails] = useState(null);
    const [loading, setLoading] = useState(false);
    const [serialNumbers, setSerialNumbers] = useState({});
    const [serialLoading, setSerialLoading] = useState({});
    const [serialFetched, setSerialFetched] = useState({});
    const [refreshKey, setRefreshKey] = useState(0);
    const [currentItemId, setCurrentItemId] = useState(null);
    const [serialModalVisible, setSerialModalVisible] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);


    const openUpdateInvoiceModal = () => {
        setIsModalVisible(true);
    };

    // In your React component where you manage the invoice details form
    const handleUpdateInvoiceDetails = async (updatedDetails) => {
        console.log('Sending update with:', updatedDetails);  // Log to confirm data structure
        try {
            const result = await updateInvoiceDetailsApi(receiptId, {
                invoice_number: updatedDetails.invoice_number,
                invoice_amount: parseFloat(updatedDetails.invoice_amount), // Ensure amount is a float
                invoice_date: updatedDetails.invoice_date,
                invoice_due_date: updatedDetails.invoice_due_date
            });
            console.log('Invoice details updated successfully:', result);
            setSuccessMessage('Invoice details updated successfully');
            setIsModalVisible(false);
            refreshDetails();
        } catch (error) {
            setErrorMessage(`Failed to update invoice details: ${error.message || 'Unknown error'}`);
            setErrorMessage('Error updating invoice details:', error);
        }
    };


    // Trigger a refresh by updating the key
    const refreshDetails = () => {
        setRefreshKey((prev) => prev + 1);
    };

    // Fetch purchase receipt details
    useEffect(() => {
        const fetchDetails = async () => {
            if (!receiptId) return;
            setLoading(true);
            try {
                const data = await fetchPurchaseReceiptDetailsApi(receiptId);
                setReceiptDetails(data);
            } catch (error) {
                setErrorMessage('Failed to load purchase receipt details');
            } finally {
                setLoading(false);
            }
        };

        if (visible) fetchDetails();
    }, [visible, receiptId, refreshKey, setErrorMessage]);

    const fetchSerialNumbers = async (itemId, force = false) => {
        // Only skip if !force and we have already fetched or are loading
        if (!force && (serialFetched[itemId] || serialLoading[itemId])) {
            return;
        }

        setSerialLoading((prev) => ({...prev, [itemId]: true}));
        try {
            const data = await fetchPurchaseReceiptSerialNumbersApi(itemId);
            setSerialNumbers((prev) => ({...prev, [itemId]: data}));
            setSerialFetched((prev) => ({...prev, [itemId]: true}));
        } catch (error) {
            setErrorMessage(`Failed to load serial numbers for item ${itemId}`);
        } finally {
            setSerialLoading((prev) => ({...prev, [itemId]: false}));
        }
    };


    const columns = [
        {
            title: 'Item Details',
            key: 'item_details',
            render: (_, record) => (
                <div style={{display: 'flex', alignItems: 'center', gap: '12px', padding: '4px 0'}}>
                    {/* Item Code and Name */}
                    <div style={{flex: 1}}>
                        <div style={{fontWeight: 'bold', fontSize: '14px', lineHeight: '1.2'}}>
                            <Tooltip title={record.item_code || 'N/A'}>
                                {truncateText(record.item_code || 'N/A', 35)} ({record.brand_name || 'N/A'})
                            </Tooltip>
                        </div>
                        <div style={{color: 'gray', fontSize: '12px', lineHeight: '1.2', marginTop: '4px'}}>
                            <Tooltip title={record.item_name || 'N/A'}>
                                {truncateText(record.item_name || 'N/A', 35)}
                            </Tooltip>
                        </div>
                    </div>

                    {/* Serialized Item Icon */}
                    {record.has_serial_no && (
                        <Tooltip title="This is a serialized item">
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    backgroundColor: '#f0f5ff', // Light complementary background
                                    border: '1px solid #adc6ff', // Softer complementary border
                                    width: '24px',
                                    height: '24px',
                                    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)', // Subtle shadow for depth
                                    marginRight: '8px', // Adds margin from the right
                                }}
                                onClick={() => handleSerialIconClick(record.id)}
                            >
                                <QrcodeOutlined
                                    style={{fontSize: '16px', color: '#597ef7'}} // Complementary icon color

                                />
                            </div>
                        </Tooltip>
                    )}
                </div>
            ),
        },
        {
            title: 'Quantity',
            dataIndex: 'received_quantity',
            key: 'received_quantity',
            align: 'right',
            width: 100,
        },
        {
            title: 'MRP',
            dataIndex: 'mrp',
            key: 'mrp',
            align: 'right',
            render: (value) => formatRupee(value),
        },
        {
            title: 'Item Price',
            dataIndex: 'item_price',
            key: 'item_price',
            align: 'right',
            render: (value) => formatRupee(value),
        },
        {
            title: 'Sub Total',
            dataIndex: 'sub_total',
            key: 'sub_total',
            align: 'right',
            render: (value) => formatRupee(value),
        },
        {
            title: 'Tax Amount',
            dataIndex: 'tax_amount',
            key: 'tax_amount',
            align: 'right',
            render: (value) => formatRupee(value),
        },
        {
            title: 'Total Amount',
            dataIndex: 'total_amount',
            key: 'total_amount',
            align: 'right',
            render: (value) => formatRupee(value),
        },
    ];

    const expandedRowRender = (record) => {
        const serialData = serialNumbers[record.id] || [];
        const isLoading = serialLoading[record.id];

        return (
            <div style={{padding: '8px'}}>
                {isLoading ? (
                    <Spin/>
                ) : serialData.length > 0 ? (
                    serialData.map((item) => (
                        <Tag key={item.serial_number_value} style={{marginBottom: '8px'}}>
                            {item.serial_number_value}
                        </Tag>
                    ))
                ) : (
                    <Text type="secondary">No serial numbers assigned</Text>
                )}
            </div>
        );
    };


    // Function to open modal and load serial numbers
    const handleSerialIconClick = async (itemId) => {
        console.log(`Icon clicked for item ID: ${itemId}`);
        setCurrentItemId(itemId);
        console.log(`Updated Current item ID: ${currentItemId}`);
        setSerialModalVisible(true);
        if (!serialFetched[itemId]) { // Only fetch if not already fetched
            try {
                const serials = await fetchPurchaseReceiptSerialNumbersApi(itemId);
                setSerialNumbers((prev) => ({...prev, [itemId]: serials}));
                setSerialFetched((prev) => ({...prev, [itemId]: true}));
            } catch (error) {
                message.error('Failed to load serial numbers');
                console.error('Error fetching serial numbers:', error);
            }
        }
    };

    // Function to submit new serial numbers
    const handleSubmitSerialNumbers = async (values) => {
        try {
            const result = await recordSerialNumbersApi(currentItemId, values.serialNumbers.split('\n'));
            console.log('Success:', result);
            setSuccessMessage('Serial numbers recorded successfully');
            setSerialModalVisible(false);
            // Force a re-fetch to get the updated data
            await fetchSerialNumbers(currentItemId, true);
        } catch (error) {
            setErrorMessage(error.error || 'Failed to record serial numbers');
        }
    };

    // JSX for the Serial Number Recording Modal
    const SerialNumberModal = () => (
        <Modal
            title="Record Serial Numbers"
            open={serialModalVisible}
            onCancel={() => setSerialModalVisible(false)}
            footer={null}
        >
            <Form onFinish={handleSubmitSerialNumbers}>
                <Form.Item
                    name="serialNumbers"
                    rules={[{required: true, message: 'Please input serial numbers, one per line'}]}
                >
                    <Input.TextArea rows={6} placeholder="Enter serial numbers, one per line"/>
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );


    return (
        <Drawer
            title={
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <Title level={4} style={{margin: 0}}>Purchase Receipt Details</Title>

                    {receiptDetails && (
                        <ReceiptActionsForDetailsDrawer
                            receiptDetails={receiptDetails}
                            receiptId={receiptId}
                            setReceiptDetails={setReceiptDetails}
                            refreshReceipts={refreshReceipts}
                            setErrorMessage={setErrorMessage}
                            setSuccessMessage={(msg) => {
                                setSuccessMessage(msg);
                                refreshDetails(); // Refresh details after successful action
                            }}
                            handleEditReceipt={handleEditReceipt}
                            setIsModalVisible={openUpdateInvoiceModal}
                            handleUpdateInvoiceDetails={handleUpdateInvoiceDetails}
                        />
                    )}
                </div>
            }
            width={window.innerWidth > 1200 ? '70%' : '85%'}
            open={visible}
            onClose={onClose}
        >
            {loading ? (
                <Spin/>
            ) : receiptDetails ? (
                <>
                    {/* Receipt Summary */}
                    <Descriptions
                        bordered
                        size="small"
                        column={4}
                        style={{tableLayout: 'fixed', width: '100%'}}
                    >
                        {/* Column 1 */}
                        <Descriptions.Item label="Order Number" span={2}
                                           labelStyle={{width: '20%'}} contentStyle={{width: '30%'}}
                        >
                            {receiptDetails.order_number}
                        </Descriptions.Item>
                        <Descriptions.Item label="Status" span={2}
                                           labelStyle={{width: '20%'}} contentStyle={{width: '30%'}}
                        >
                            <Badge
                                status={
                                    receiptDetails.status === 'received'
                                        ? 'success'
                                        : receiptDetails.status === 'cancelled'
                                            ? 'error'
                                            : 'warning'
                                }
                                style={{marginRight: 8}}
                            />
                            {receiptDetails.status_display_name}
                        </Descriptions.Item>
                        <Descriptions.Item label="Supplier" span={2}>
                            <Tooltip title={receiptDetails.supplier_company_name}>
        <span>
            {truncateText(receiptDetails.supplier_company_name, 20)}
        </span>
                            </Tooltip>
                            {` (${receiptDetails.supplier_branch_code})`}
                        </Descriptions.Item>
                        <Descriptions.Item label="Invoice Date" span={2}>
                            {formatDate(receiptDetails.invoice_date)}
                        </Descriptions.Item>
                        {/* Column 2 */}
                        <Descriptions.Item label="Recipient" span={2}>
                            {receiptDetails.recipient_company_name}
                        </Descriptions.Item>
                        <Descriptions.Item label="Invoice Number" span={2}>
                            {receiptDetails.invoice_number}
                        </Descriptions.Item>

                        <Descriptions.Item label="Received At" span={2}>
                            {receiptDetails.recipient_branch_name}
                        </Descriptions.Item>
                        <Descriptions.Item label="Invoice Status" span={2}
                                           labelStyle={{width: '20%'}} contentStyle={{width: '30%'}}
                        >
                            <Badge
                                status={
                                    receiptDetails.invoice_status === 'cancelled'
                                        ? 'error'
                                        : receiptDetails.invoice_status === 'draft'
                                            ? 'warning'
                                            : 'success'
                                }
                                style={{marginRight: 8}}
                            />
                            {receiptDetails.invoice_status_display_name}
                        </Descriptions.Item>
                        <Descriptions.Item label="Order Value" span={2}>
                            {formatRupee(receiptDetails.total_amount)}
                        </Descriptions.Item>
                        <Descriptions.Item label="Invoice Amount" span={2}>
                            {formatRupee(receiptDetails.invoice_amount)}
                        </Descriptions.Item>
                        <Descriptions.Item label="Sub Total" span={2}>
                            {formatRupee(receiptDetails.sub_total)}
                        </Descriptions.Item>

                        <Descriptions.Item label="Due Date" span={2}>
                            {formatDate(receiptDetails.invoice_due_date)}
                        </Descriptions.Item>
                        <Descriptions.Item label="Total Quantity" span={2}>
                            {receiptDetails.total_quantity}
                        </Descriptions.Item>
                        <Descriptions.Item label="Tax Inclusion" span={2}>
                            {receiptDetails.is_inclusive_tax ? 'Inclusive of Tax' : 'Exclusive of Tax'}
                        </Descriptions.Item>

                        <Descriptions.Item label="Remarks">
                            {receiptDetails.remarks || 'N/A'}
                        </Descriptions.Item>

                        {/* Column 3 */}


                    </Descriptions>


                    <Title level={5} style={{marginTop: 20}}>
                        Items
                    </Title>
                    <Table
                        columns={columns}
                        dataSource={receiptDetails.items}
                        rowKey="id"
                        pagination={false}
                        expandable={{
                            expandedRowRender,
                            onExpand: async (expanded, record) => {
                                if (expanded && !serialFetched[record.id]) {
                                    await fetchSerialNumbers(record.id);
                                }
                            },
                        }}
                        size="small"
                        locale={{
                            emptyText: <Empty description="No items available."/>,
                        }}
                    />

                    <Descriptions bordered size="small" column={4} style={{marginTop: 20}}>
                        <Descriptions.Item label="Created Date" span={2}>
                            {formatDateTime(receiptDetails.created_date)}
                        </Descriptions.Item>
                        <Descriptions.Item label="Created By" span={2}>
                            {receiptDetails.created_by_name || 'N/A'}
                        </Descriptions.Item>
                        <Descriptions.Item label="Updated Date" span={2}>
                            {formatDateTime(receiptDetails.updated_date)}
                        </Descriptions.Item>
                        <Descriptions.Item label="Updated By" span={2}>
                            {receiptDetails.updated_by_name || 'N/A'}
                        </Descriptions.Item>
                        <Descriptions.Item label="Closed Date" span={2}>
                            {formatDateTime(receiptDetails.closed_date)}
                        </Descriptions.Item>
                        <Descriptions.Item label="Closed By" span={2}>
                            {receiptDetails.closed_by_name || 'N/A'}
                        </Descriptions.Item>
                    </Descriptions>
                </>
            ) : (
                <Text>No data available</Text>
            )}
            <SerialNumberModal/>
            <UpdateInvoiceModal
                isVisible={isModalVisible}
                onClose={() => setIsModalVisible(false)}
                invoiceDetails={{
                    invoice_number: receiptDetails?.invoice_number,
                    invoice_amount: receiptDetails?.invoice_amount,
                    invoice_date: receiptDetails?.invoice_date,
                    invoice_due_date: receiptDetails?.invoice_due_date
                }}
                onUpdate={handleUpdateInvoiceDetails}
            />
        </Drawer>
    );
};

export default PurchaseReceiptDetailsDrawer;

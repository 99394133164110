import React, { useEffect, useState } from 'react';
import { Button, Col, Input, Row, message } from 'antd';
import InvoicesTable from './InvoicesTable';
import InvoiceDetailsDrawer from './InvoiceDetailsDrawer';
import { fetchInvoicesApi } from './invoiceApi';

const { Search } = Input;

const InvoicesList = () => {
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [selectedInvoice, setSelectedInvoice] = useState(null);
    const [invoices, setInvoices] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
    const [searchTerm, setSearchTerm] = useState('');


     const [messageApi, contextHolder] = message.useMessage(); // Initialize Ant Design message
    const [successMessage, setSuccessMessage] = useState(null);
    const [infoMessage, setInfoMessage] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);


    // Handle displaying success and error messages using useEffect
    useEffect(() => {
        if (successMessage) {
            console.log("Displaying success message:", successMessage);
            messageApi.open({
                type: 'success',
                content: successMessage,
                duration: 2.5,
            });
            setSuccessMessage(null);
        }
        if (errorMessage) {
            console.log("Displaying error message:", errorMessage);
            messageApi.open({
                type: 'error',
                content: errorMessage,
                duration: 2.5,
            });
            setErrorMessage(null);
        }
        if (infoMessage) {
            console.log("Displaying info message:", infoMessage);
            messageApi.open({
                type: 'info',
                content: infoMessage,
                duration: 2.5,
            });
            setInfoMessage(null);
        }
    }, [successMessage, errorMessage, infoMessage, messageApi]);


    useEffect(() => {
        fetchInvoices(pagination.current, searchTerm);
    }, [pagination.current, searchTerm]);

    const fetchInvoices = async (page, search) => {
        setLoading(true);
        try {
            const data = await fetchInvoicesApi(page, search);
            setInvoices(data.results);
            setPagination(prev => ({
                ...prev,
                total: data.count,
                current: page,
            }));
            console.log(invoices);
        } catch (error) {
            setErrorMessage('Failed to load invoices: ' + error.message);
        } finally {
            setLoading(false);
        }
    };

    const handleSearch = value => {
        setSearchTerm(value);
        setPagination(prev => ({ ...prev, current: 1 }));
    };

    const openDrawer = invoice => {
        setSelectedInvoice(invoice);
        setDrawerVisible(true);
    };

    const closeDrawer = () => {
        setDrawerVisible(false);
    };

    return (
        <div style={{ padding: '24px' }}>
            {contextHolder}
            <h2>Invoices</h2>
            <Row justify="space-between" align="middle" style={{ marginBottom: 16 }}>
                <Col>
                    <Search
                        placeholder="Search invoices"
                        onSearch={handleSearch}
                        style={{ width: 200, marginRight: 8 }}
                        enterButton
                    />
                    <Button type="primary" onClick={() => alert('Create Invoice')}>
                        Create Invoice
                    </Button>
                </Col>
            </Row>
            <InvoicesTable
                invoices={invoices}
                loading={loading}
                pagination={pagination}
                onPaginationChange={setPagination}
                openDrawer={openDrawer}
            />
            {selectedInvoice && (
                <InvoiceDetailsDrawer
                    visible={drawerVisible}
                    onClose={closeDrawer}
                    invoice={selectedInvoice}
                    setInvoice={setSelectedInvoice}
                    setSuccessMessage={setSuccessMessage}
                    setErrorMessage={setErrorMessage}
                    setInfoMessage={setInfoMessage}
                />
            )}
        </div>
    );
};

export default InvoicesList;

import React, { useEffect, useState } from 'react';
import {Form, Input, Button, Row, Col, Select, Divider, Checkbox} from 'antd';

const { Option } = Select;

const BranchRegistration = ({ onCancel, onSubmit, branch, companies, countries, setErrorMessage, setInfoMessage, setSuccessMessage }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [isRegisteredBusiness, setIsRegisteredBusiness] = useState(false);
     const [states, setStates] = useState([]);

    useEffect(() => {
        if (branch) {
            form.setFieldsValue(branch);
        }
    }, [branch]);


    const handleCountryChange = (countryId) => {
        const selectedCountry = countries.find(country => country.id === countryId);
        setStates(selectedCountry?.states || []);
        form.setFieldsValue({['branch.state']: null});
    };


    const handleFinish = async (values) => {
        setLoading(true);
        let successful = false;
        try {
            const response = await onSubmit(values); // Assume onSubmit returns a response object
            if (response && response.success) { // Check if the response indicates success
                successful = true; // Set flag if submission was successful
                setSuccessMessage('Branch registered successfully!!!!');
            } else {
                setErrorMessage(response || 'Submission failed!!!');
            }
        } catch (error) {
            console.error('Registration failed:', error || 'An error occurred');
            setErrorMessage(error || 'Registration failed');
        } finally {
            if (successful) {
                form.resetFields(); // Reset form only if successful
            }
            setLoading(false);
        }
    };

    return (
        <div className="branch-registration-form">
            <h2>{branch ? 'Edit Branch' : 'Register Branch'}</h2>
            <Form
                form={form}
                layout="vertical"
                onFinish={handleFinish}
                initialValues={{
                    branch: {is_registered_business: false},
                }}
            >


                 <Row gutter={[16, 16]}>
                    <Col span={12}>
                        <Divider orientation="left">Branch Details</Divider>
                        <Form.Item
                            name="company_id"
                            label="Company Name"
                            rules={[{required: true, message: 'Please enter the company name'}]}
                        >

                            <Select
                                showSearch
                                placeholder="Select Company"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().includes(input.toLowerCase())
                                }
                            >
                                {companies.map(company => (
                                    <Option key={company.value} value={company.value}>
                                        {company.label}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name={['branch', 'branch_name']}
                            label="Branch Name"
                            rules={[{required: true, message: 'Please enter the branch name'}]}
                        >
                            <Input placeholder="Enter branch name"/>
                        </Form.Item>
                        <Form.Item
                            name={['branch', 'branch_code']}
                            label="Branch Code"
                            rules={[{required: true, message: 'Please enter the branch code'}]}
                        >
                            <Input placeholder="Enter branch code"/>
                        </Form.Item>
                        <Form.Item
                            name={['branch', 'is_warehouse']}
                            valuePropName="checked"
                        >
                            <Checkbox>Is Warehouse?</Checkbox>
                        </Form.Item>

                        <Form.Item
                            name={['branch', 'is_registered_business']}
                            valuePropName="checked"
                            label=""
                            style={{paddingTop: '32px'}}
                        >
                            <Checkbox onChange={(e) => setIsRegisteredBusiness(e.target.checked)}>
                                Registered Business?"
                            </Checkbox>
                        </Form.Item>
                        {isRegisteredBusiness ? (
                            <Form.Item
                                name={['branch', 'gstin']}
                                label="GSTIN"
                                rules={[{required: true, message: 'Enter GSTIN (15 characters)'}]}
                            >
                                <Input placeholder="Enter GSTIN"/>
                            </Form.Item>
                        ) : (
                            <Form.Item
                                name={['branch', 'pan']}
                                label="PAN"
                            >
                                <Input placeholder="Enter PAN"/>
                            </Form.Item>
                        )}
                        <Form.Item
                            name={['branch', 'zb_id']}
                            label="ZB ID"
                        >
                            <Input placeholder="Enter ZB ID"/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Divider orientation="left">Address Details</Divider>
                        <Form.Item
                            name={['branch', 'address_line_1']}
                            label="Address Line 1"
                        >
                            <Input placeholder="Enter address line 1"/>
                        </Form.Item>
                        <Form.Item
                            name={['branch', 'address_line_2']}
                            label="Address Line 2"
                        >
                            <Input placeholder="Enter address line 2"/>
                        </Form.Item>
                        <Form.Item
                            name={['branch', 'city']}
                            label="City"
                        >
                            <Input placeholder="Enter city"/>
                        </Form.Item>
                        <Form.Item
                            name={['branch', 'postal_code']}
                            label="Postal Code"
                        >
                            <Input placeholder="Enter postal code"/>
                        </Form.Item>
                        <Form.Item
                            name={['branch', 'phone_number']}
                            label="Phone Number"
                        >
                            <Input placeholder="Enter phone number"/>
                        </Form.Item>
                        <Form.Item
                            name={['branch', 'country']}
                            label="Country"
                            rules={[{required: true, message: 'Select a country'}]}
                        >
                            <Select
                                showSearch
                                placeholder="Select country"
                                optionFilterProp="children"
                                onChange={handleCountryChange}
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().includes(input.toLowerCase())
                                }
                            >
                                {countries.map(country => (
                                    <Option key={country.id} value={country.id}>
                                        {country.country_name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name={['branch', 'state']}
                            label="State"
                            rules={[{required: true, message: 'Please select a state'}]}
                        >
                            <Select
                                showSearch
                                placeholder="Select state"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().includes(input.toLowerCase())
                                }
                            >
                                {states.map(state => (
                                    <Option key={state.id} value={state.id}>
                                        {state.state_name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

                {/* Add more fields as needed */}
                <Button onClick={onCancel} style={{ marginRight: 8 }}>
                    Cancel
                </Button>
                <Button type="primary" htmlType="submit" loading={loading}>
                    {branch ? 'Update Branch' : 'Register Branch'}
                </Button>
            </Form>
        </div>
    );
};

export default BranchRegistration;

import {Button, Descriptions, Typography, Input, Card} from 'antd';
import { EditOutlined } from '@ant-design/icons';

const { Text } = Typography;

const BillingShippingInfo = ({
    billingAddress,
    shippingAddress,
    handleEditAddresses
}) => {

    // Helper function to format the address into a multi-line string with dynamic spacing
const formatAddress = (address) => {
    if (!address) return 'Not provided';

    // Initialize an array to hold the address parts
    const addressParts = [];

    // Add each part of the address if it exists
    if (address.address) addressParts.push(address.address);
    if (address.address2) addressParts.push(address.address2);
    if (address.city) addressParts.push(`${address.city},`);
    if (address.state_name || address.postalCode) addressParts.push(`${address.state_name} ${address.postalCode}`.trim());
    if (address.country_name) addressParts.push(address.country_name);

    // Join parts with a newline character
    return addressParts.join('\n');
};


    return (
        <Card style={{borderRadius: '8px', padding: 0, marginBottom: 0}}>
        <Descriptions bordered size="small" column={8} layout="vertical" style={{ marginBottom: 16 }}>
            <Descriptions.Item label="Billing Address">
                <Text style={{ whiteSpace: 'pre-line' }}>{formatAddress(billingAddress)}</Text>
                    <Button type="link" icon={<EditOutlined />} onClick={() => handleEditAddresses('billing')} style={{ marginLeft: 8 }}>
                    Edit
                </Button>
            </Descriptions.Item>
            <Descriptions.Item label="Shipping Address">
                <Text style={{ whiteSpace: 'pre-line' }}>{formatAddress(shippingAddress)}</Text>
                    <Button type="link" icon={<EditOutlined />} onClick={() => handleEditAddresses('shipping')} style={{ marginLeft: 8 }}>
                    Edit
                </Button>
            </Descriptions.Item>
        </Descriptions>
        </Card>
    );
};

export default BillingShippingInfo;

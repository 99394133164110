import React from 'react';
import { Button, Table, Typography, Space, InputNumber, Tooltip, Empty, message } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import ProductDrawer from "../products/ProductDrawer";
import {formatRupee, calculateDiscountPercentage, truncateText} from '../../utils/utils';

const { Text } = Typography;

const CreateSaleOrder = ({
    selectedProducts,
    onAddProduct,
    onRemoveProduct,
    onQuantityChange,
    onPriceChange,
    onReviewOrder,
    productDrawerVisible,
    setProductDrawerVisible,
    setInfoMessage,
}) => {
    // Handle adding a product
    const handleAddProduct = (product) => {
        if (selectedProducts.some(item => item.id === product.id)) {
            setInfoMessage(`${product.item_code} is already added`)
            return;
        }
        onAddProduct({ ...product, quantity: 1, item_price: product.ticket_price });
    };


    // Table columns
    const columns = [
        {
            title: 'Item Code',
            dataIndex: 'item_code',
            width: '30%',
            render: (text, record) => (
                <div>
                    <Text strong>{text}</Text>
                    <br />
                    <Tooltip title={record.item_name}>
                        <Text type="secondary">{truncateText(record.item_name,40)}</Text>
                    </Tooltip>
                </div>
            ),
        },
        {
            title: 'Selling Rate',
            dataIndex: 'item_price',
            width: '25%',
            render: (_, record) => {
                const discount = calculateDiscountPercentage(record.mrp, record.item_price);
                const isBelowMOP = record.item_price < record.mop;

                return (
                    <div style={{ textAlign: 'right' }}>
                        <InputNumber
                            min={0}
                            value={record.item_price}
                            onChange={(value) => onPriceChange(record.id, value)}
                            formatter={(value) => `₹${value}`}
                            parser={(value) => value.replace('₹', '')}
                            style={{ width: '100%', marginBottom: '4px' }}
                        />
                        <Text style={{ color: 'grey', marginRight: '8px' }}>MRP:</Text>
                        <Text delete style={{ color: 'grey', marginRight: '8px' }}>{formatRupee(record.mrp)}</Text>
                        {discount > 0 && (
                            <Text type={isBelowMOP ? 'danger' : 'success'}>
                                {`(${discount}% off)`}
                            </Text>
                        )}
                    </div>
                );
            }
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            width: '20%',
            align: 'center',
            render: (_, record) => (
                <Space>
                    <Button onClick={() => onQuantityChange(record.id, record.quantity - 1)} disabled={record.quantity <= 1}>-</Button>
                    <InputNumber
                        min={1}
                        value={record.quantity}
                        onChange={(value) => onQuantityChange(record.id, value)}
                        style={{ width: '60px', textAlign: 'right' }}
                    />
                    <Button onClick={() => onQuantityChange(record.id, record.quantity + 1)}>+</Button>
                </Space>
            ),
        },
        {
            title: 'Total Amount',
            align: 'right',
            width: '20%',
            render: (_, record) => formatRupee(record.item_price * record.quantity)
        },
        {
            title: '',
            width: '10%',
            align: 'center',
            render: (_, record) => (
                <Button type="link" icon={<DeleteOutlined />} onClick={() => onRemoveProduct(record.id)} />
            ),
        },
    ];

    return (
        <div style={{ padding: '10px' }}>
            <Button type="primary" onClick={() => setProductDrawerVisible(true)} style={{ marginBottom: '10px' }}>
                Add Products
            </Button>

            <Table
                columns={columns}
                dataSource={selectedProducts}
                rowKey="id"
                pagination={false}
                size="small"
                locale={{
                    emptyText: (
                        <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description="No products selected. Please add products to the transaction."
                        />
                    ),
                }}
            />

            <div style={{ marginTop: 16 }}>
                <Button type="primary" onClick={onReviewOrder}>
                    Review Sale Order
                </Button>
            </div>

            <ProductDrawer
                visible={productDrawerVisible}
                onClose={() => setProductDrawerVisible(false)}
                onSelectProduct={handleAddProduct}
                selectedProducts={selectedProducts}
            />
        </div>
    );
};

export default CreateSaleOrder;

import React, {useState} from 'react';
import {Button, Space, Tooltip} from 'antd';
import {
    CarryOutOutlined,
    CheckOutlined,
    CloseOutlined,
    EditOutlined,
    FileDoneOutlined,
    FileProtectOutlined,
} from '@ant-design/icons';
import {
    cancelPurchaseReceiptApi,
    closePurchaseReceiptApi,
    confirmPurchaseReceiptApi,
    generateInvoiceFromPurchaseReceiptApi,
    readyToReceivePurchaseReceiptApi,
    receivePurchaseReceiptApi,
} from './procurementApi';
import ConfirmActionModal from "../common/ConfirmActionModal";
import {confirmInvoiceApi} from "../sales/saleOrdersApi";

const ReceiptActionsForDetailsDrawer = ({
                                            receiptDetails,
                                            receiptId,
                                            setReceiptDetails,
                                            refreshReceipts,
                                            setErrorMessage,
                                            setSuccessMessage,
                                            handleEditReceipt,
                                            setIsModalVisible,
                                            handleUpdateInvoiceDetails,
                                        }) => {
    const [confirming, setConfirming] = useState(false);
    const [currentAction, setCurrentAction] = useState(null);
    const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
    const [modalConfig, setModalConfig] = useState({
        title: '',
        confirmationText: '',
        action: null,
        inputRequired: false,
        placeholder: '',
    });
    const [remarks, setRemarks] = useState('');

    const handleConfirmAction = async () => {
        if (modalConfig.inputRequired && !remarks.trim()) {
            setErrorMessage('Remarks are required for this action.');
            return;
        }

        setIsConfirmModalVisible(false);
        setConfirming(true);

        try {
            await modalConfig.actionFunction(receiptId, remarks);
            setSuccessMessage(modalConfig.successMessage);
            refreshReceipts();
        } catch (error) {
            console.error(`Error performing action:`, error);
            setErrorMessage(error.error || 'An unexpected error occurred while performing the action.');
        } finally {
            setConfirming(false);
            setRemarks('');
        }
    };

    const handleRecordInvoiceDetails = () => {
        setIsModalVisible(true); // Ensure you pass setIsModalVisible prop from the parent if not already passed
    };

    const actionsConfig = [
        {
            key: 'confirm',
            label: 'Confirm',
            icon: <FileDoneOutlined/>,
            condition: receiptDetails.status === 'draft',
            type: 'primary',
            color: '#52c41a', // Green
            modalConfig: {
                title: 'Confirm Purchase Receipt',
                confirmationText: 'Are you sure you want to confirm this purchase receipt?',
                actionFunction: confirmPurchaseReceiptApi,
                successMessage: 'Purchase receipt confirmed successfully',
            },
        },
        {
            key: 'readyToReceive',
            label: 'Ready to Receive',
            icon: <CarryOutOutlined/>,
            condition: receiptDetails.status === 'confirmed',
            type: 'default',
            color: '#1890ff', // Blue
            modalConfig: {
                title: 'Mark Ready to Receive',
                confirmationText: 'Are you sure you want to mark this purchase receipt as ready to receive?',
                actionFunction: readyToReceivePurchaseReceiptApi,
                successMessage: 'Purchase receipt marked as ready to receive',
            },
        },
        {
            key: 'receive',
            label: 'Receive',
            icon: <CheckOutlined/>,
            condition: receiptDetails.status === 'ready_to_receive',
            type: 'default',
            color: '#13c2c2', // Cyan
            modalConfig: {
                title: 'Receive Purchase Receipt',
                confirmationText: 'Are you sure you want to mark this purchase receipt as received?',
                actionFunction: receivePurchaseReceiptApi,
                successMessage: 'Purchase receipt received successfully',
            },
        },
        {
            key: 'generateInvoice',
            label: 'Generate Invoice',
            icon: <FileDoneOutlined/>,
            condition: receiptDetails.status === 'received' && !receiptDetails.invoice,
            type: 'default',
            color: '#fa541c', // Orange
            modalConfig: {
                title: 'Generate Invoice',
                confirmationText: 'Are you sure you want to generate an invoice for this purchase receipt?',
                actionFunction: generateInvoiceFromPurchaseReceiptApi,
                successMessage: 'Invoice generated successfully for this purchase receipt.',
            },
        },
        {
            key: 'recordInvoice',
            label: 'Record Invoice Details',
            icon: <EditOutlined/>,
            condition: receiptDetails.status === 'draft' && !receiptDetails.invoice, // Adjust the condition as necessary
            type: 'default',
            color: '#2f54eb', // Blue color, pick any as per your UI
            onClick: () => handleRecordInvoiceDetails(receiptDetails), // Adjust this to trigger the right function
        },
        {
            key: 'confirmInvoice',
            label: 'Confirm Invoice',
            icon: <CheckOutlined/>,
            condition: receiptDetails.invoice && receiptDetails.invoice_status === 'draft', // Visible only if an invoice exists and is in 'draft'
            type: 'default',
            color: '#52c41a', // Green, indicating a confirm action
            modalConfig: {
                title: 'Confirm Invoice',
                confirmationText: 'Are you sure you want to confirm this invoice?',
                actionFunction: () => confirmInvoiceApi(receiptDetails.invoice), // Assuming invoice id is accessible like this
                successMessage: 'Invoice confirmed successfully.',
            },
        },
        {
            key: 'edit',
            label: 'Edit',
            icon: <EditOutlined/>,
            condition: receiptDetails.status === 'draft',
            type: 'default',
            color: '#2f54eb', // Indigo
            onClick: () => handleEditReceipt(receiptDetails),
        },
        {
            key: 'close',
            label: 'Close',
            icon: <FileProtectOutlined/>,
            condition: receiptDetails.status === 'received',
            type: 'default',
            color: '#faad14', // Gold
            modalConfig: {
                title: 'Close Purchase Receipt',
                confirmationText: 'Are you sure you want to close this purchase receipt?',
                inputRequired: false,
                placeholder: 'Enter closing remarks (optional)',
                actionFunction: closePurchaseReceiptApi,
                successMessage: 'Purchase receipt closed successfully',
            },
        },
        {
            key: 'cancel',
            label: 'Cancel',
            icon: <CloseOutlined/>,
            condition: receiptDetails.status === 'draft' || receiptDetails.status === 'confirmed',
            type: 'danger',
            color: '#f5222d', // Red
            modalConfig: {
                title: 'Cancel Purchase Receipt',
                confirmationText: 'Are you sure you want to cancel this purchase receipt?',
                inputRequired: true,
                placeholder: 'Enter cancellation remarks',
                actionFunction: cancelPurchaseReceiptApi,
                successMessage: 'Purchase receipt cancelled successfully',
            },
        },

    ];


    return (
        <>
            <Space wrap>
                {actionsConfig.map(({key, label, icon, condition, modalConfig, onClick, type, color}) =>
                        condition && (
                            <Tooltip key={key} title={modalConfig?.confirmationText || ''}>
                                <Button
                                    type={type}
                                    style={{backgroundColor: color, borderColor: color, color: '#fff'}}
                                    icon={icon}
                                    loading={confirming && currentAction === key}
                                    onClick={() => {
                                        if (modalConfig) {
                                            setModalConfig({
                                                ...modalConfig,
                                                action: key,
                                                actionFunction: modalConfig.actionFunction,
                                                successMessage: modalConfig.successMessage
                                            });
                                            setIsConfirmModalVisible(true);
                                        } else {
                                            onClick();
                                        }
                                    }}
                                >
                                    {label}
                                </Button>
                            </Tooltip>
                        )
                )}
            </Space>

            {/* Confirmation Modal */}
            <ConfirmActionModal
                visible={isConfirmModalVisible}
                onConfirm={handleConfirmAction}
                onCancel={() => setIsConfirmModalVisible(false)}
                title={modalConfig.title}
                confirmationText={modalConfig.confirmationText}
                inputRequired={modalConfig.inputRequired}
                inputValue={remarks}
                setInputValue={setRemarks}
                placeholder={modalConfig.placeholder}
                loading={confirming}
            />
        </>
    );
};

export default ReceiptActionsForDetailsDrawer;

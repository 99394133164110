import React, {useEffect, useState} from 'react';
import {Button, Col, DatePicker, Form, Input, InputNumber, Row, Select,} from 'antd';
import moment from 'moment';
import {createCashTransaction, fetchClosingBalanceBranch, updateCashTransaction} from './cashTransactionsAPI';
import {fetchCashAccountsApi} from '../common/commonApis';
import {formatRupee} from "../../utils/utils";
// Import your fetchClosingBalance API call here:
// import { fetchClosingBalance } from '../common/financialsAPI';

const {Option} = Select;
const {TextArea} = Input;

const CashTransactionForm = ({
                                 transaction,
                                 setSuccessMessage,
                                 setErrorMessage,
                                 onCancel,
                                 refreshTransactions,
                                 companies,
                                 setTempSelectedCompany,
                                 companyId,
                                 branches,
                                 branchId,
                                 userRole,
                             }) => {
    const [form] = Form.useForm();
    const [file, setFile] = useState(null);
    const [submitting, setSubmitting] = useState(false);

    // Local transaction type state
    const [transactionType, setTransactionType] = useState(
        transaction ? transaction.transactionType : 'branch_transfer'
    );

    // Bank accounts relevant to the currently selected company
    const [bankAccounts, setBankAccounts] = useState([]);

    // Filtered branches for the currently selected company
    const [filteredBranches, setFilteredBranches] = useState([]);

    // The user-selected source branch (ID) from the form
    const [selectedSourceBranch, setSelectedSourceBranch] = useState(
        transaction?.sourceBranch || branchId
    );

    // Cash-in-hand, draft, and available amounts for the selected branch
    const [cashDetails, setCashDetails] = useState({
        cashInHand: 0,
        draftAmount: 0,
        availableBalance: 0,
    });

    // 1) Ensure amount <= availableBalance
    const validateAmountNotExceedBalance = (availableBalance) => (rule, value) => {
        if (value !== undefined && value > availableBalance) {
            return Promise.reject(
                new Error(`Amount cannot exceed available balance of ${availableBalance}.`)
            );
        }
        return Promise.resolve();
    };

// 2) Disallow future dates
    const validateNotFutureDate = (rule, value) => {
        if (!value) {
            return Promise.reject(new Error('Transaction date is required'));
        }
        if (value.isAfter(moment(), 'day')) {
            return Promise.reject(
                new Error('Transaction date cannot be in the future.')
            );
        }
        return Promise.resolve();
    };

// 3) Ensure sourceBranch != targetBranch
//    (Will check within targetBranch's Form.Item)
    const validateDifferentBranch = (getFieldValue) => (rule, value) => {
        const sourceBranchValue = getFieldValue('sourceBranch');
        if (value && sourceBranchValue && value === sourceBranchValue) {
            return Promise.reject(new Error('Source and target branch cannot be the same.'));
        }
        return Promise.resolve();
    };


    /**
     * Initialize the form fields when the component mounts or
     * when transaction/company changes (excluding transactionType to avoid re-init on type change).
     */
    useEffect(() => {
        form.setFieldsValue({
            company: transaction?.company_id || companyId,
            sourceBranch: transaction?.source_branch || branchId,
            transactionDate: transaction ? moment(transaction.transaction_date) : moment(),
            amount: transaction?.amount || undefined,
            description: transaction?.description || '',
            transactionType: transaction?.transaction_type || 'branch_transfer',
            // For edit mode, pre-populate if transaction has these fields
            ...(transaction?.bank_account ? {bankAccount: transaction.bank_account} : {}),
            ...(transaction?.target_branch ? {targetBranch: transaction.target_branch} : {}),
        });
    }, [transaction, companyId, form]);

    useEffect(() => {
        setTempSelectedCompany(companyId);
        if (selectedSourceBranch) {
            fetchClosingBalanceBranch(selectedSourceBranch).then(data => {
                setCashDetails({
                    cashInHand: data.cash_in_hand,
                    draftAmount: data.draft_amount,
                    availableBalance: data.available_cash_in_hand_balance,
                });
            }).catch(error => {
                console.error('Failed to fetch closing balances', error);
                setErrorMessage('Failed to fetch financial details');
            });
        }
        // Fetch bank accounts if companyId is preset
        if (companyId) {
            fetchBankAccounts(companyId);
        }
    }, [])
    /**
     * Filter branches based on the current companyId.
     */
    useEffect(() => {
        const newFilteredBranches = branches.filter((b) => b.companyId === companyId);
        setFilteredBranches(newFilteredBranches);
    }, [companyId, branches]);

    /**
     * If we have a default `companyId`, fetch the relevant bank accounts right away.
     */
    useEffect(() => {
        if (companyId) {
            fetchBankAccounts(companyId);
        }
    }, [companyId]);

    /**
     * Handle changes to the company selection.
     * - Reset relevant fields
     * - Fetch new bank accounts for the chosen company
     * - Filter branches for that company
     */
    const handleCompanyChange = (newCompanyId) => {
        setTempSelectedCompany(newCompanyId);
        form.setFieldsValue({
            sourceBranch: undefined,
            targetBranch: undefined,
            bankAccount: undefined,
        });

        // Filter branches
        const newFilteredBranches = branches.filter((b) => b.companyId === newCompanyId);
        setFilteredBranches(newFilteredBranches);

        // Re-fetch bank accounts for the new company
        fetchBankAccounts(newCompanyId);

        // Reset local state for source branch
        setSelectedSourceBranch(undefined);
    };

    // -----------------------------
    // File Input change
    // -----------------------------
    const handleFileChange = (e) => {
        const files = e.target.files;
        if (files && files.length > 0) {
            setFile(files[0]);
        }
    };

    /**
     * Handle changes to the sourceBranch selection.
     * - Update local state
     * - Reset target branch/bank account
     * - Fetch the closing balance for that branch
     */
    const handleSourceBranchChange = async (branchId) => {
        setSelectedSourceBranch(branchId);
        form.setFieldsValue({
            targetBranch: undefined,
            bankAccount: undefined,
        });

        if (!branchId) {
            // If user cleared the field, just reset details
            setCashDetails({cashInHand: 0, draftAmount: 0, availableBalance: 0});
            return;
        }

        try {
            // Example usage if you have an API method named fetchClosingBalance
            const data = await fetchClosingBalanceBranch(branchId);
            setCashDetails({
                cashInHand: data.cash_in_hand,
                draftAmount: data.draft_amount,
                availableBalance: data.available_balance,
            });
        } catch (error) {
            setErrorMessage('Failed to fetch financial details');
            console.error(error);
        }
    };

    /**
     * Fetch bank accounts for the currently chosen company.
     */
    const fetchBankAccounts = async (selectedCompanyId) => {
        try {
            const accounts = await fetchCashAccountsApi(selectedCompanyId);

            const filteredAccounts = accounts.filter(account =>
                    account.account_sub_type_name.toLowerCase().includes('bank account')
                );
            setBankAccounts(filteredAccounts);
        } catch (error) {
            setErrorMessage('Failed to fetch bank accounts');
        }
    };

    /**
     * Form submission handler.
     */
    const onFinish = async (values) => {
        setSubmitting(false);

        let attachment = null;
        if (file) {
            attachment = file;
        }

        // transactionType is in local state
        const formattedValues = {
            ...values,
            transactionType,
            // Convert date to 'YYYY-MM-DD'
            transactionDate: values.transactionDate.format('YYYY-MM-DD'),
            attachment: attachment,
        };

        console.log("formattedValues: ", formattedValues);
        try {
            let response;
            response = await createCashTransaction(formattedValues);

            setSuccessMessage('Cash transaction processed successfully');
            form.resetFields();
            setCashDetails({
                    cashInHand: 0,
                    draftAmount: 0,
                    availableBalance: 0,
                });
            onCancel();
            refreshTransactions();
        } catch (error) {
            console.error('Error processing cash transaction:', error);
            setErrorMessage('Failed to process cash transaction');
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <Form form={form} layout="vertical" onFinish={onFinish} autoComplete="off">
            {/* ====== ROW 1 ====== */}
            <Row gutter={16}>
                {/* Column 1: Company, Source Branch */}
                <Col span={12}>
                    <Form.Item name="company" label="Company"
                               rules={[
                                   {required: true, message: 'Company is required'}
                               ]}
                    >
                        <Select
                            onChange={handleCompanyChange}
                            disabled={!['super_admin', 'admin'].includes(userRole)}
                            placeholder="Select company"
                        >
                            {companies.map((company) => (
                                <Option key={company.id} value={company.id}>
                                    {company.company_name}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>

                    <Form.Item name="sourceBranch" label="Source Branch"
                               rules={[
                                   {required: true, message: 'Source branch is required'}
                               ]}
                    >
                        <Select
                            placeholder="Select source branch"
                            onChange={handleSourceBranchChange}
                            allowClear
                        >
                            {branches.map((b) => (
                                <Option key={b.id} value={b.id}>
                                    {b.branch_name}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>

                {/* Column 2: Show cash in hand, draft amount, available balance */}
                <Col span={12}>
                    <div style={{marginTop: 31}}>
                        <p>
                            <strong>Cash in Hand: </strong>
                            {formatRupee(cashDetails.cashInHand)}
                        </p>
                        <p>
                            <strong>Draft Amount: </strong>
                            {formatRupee(cashDetails.draftAmount)}
                        </p>
                        <p>
                            <strong>Available Balance: </strong>
                            {formatRupee(cashDetails.availableBalance)}
                        </p>
                    </div>
                </Col>
            </Row>

            {/* ====== ROW 2 ====== */}
            <Row gutter={16}>
                {/* Column 1: Amount, Transaction Date */}
                <Col span={12}>
                    <Form.Item name="amount" label="Amount"
                               rules={[
                                   {required: true, message: 'Please enter an amount'},
                                   {
                                       validator: validateAmountNotExceedBalance(cashDetails.availableBalance),
                                   },
                               ]}
                    >
                        <InputNumber style={{width: '100%'}} min={0} prefix="Rs." suffix="INR"/>
                    </Form.Item>

                    <Form.Item name="transactionDate" label="Transaction Date"
                               rules={[
                                   {required: true, message: 'Please select date'},
                                   {
                                       validator: validateNotFutureDate
                                   },
                               ]}
                    >
                        <DatePicker style={{width: '100%'}} format="DD-MM-YYYY"/>
                    </Form.Item>
                    <Form.Item name="description" label="Description"
                               rules={[
                                   {required: true, message: 'Description is required'}
                               ]}
                    >
                        <TextArea rows={3} placeholder="Additional details"/>
                    </Form.Item>
                </Col>

                {/* Column 2: Transaction Type, Target Branch (if branch_transfer), Bank Account (if bank_deposit), Description */}
                <Col span={12}>
                    <Form.Item label="Transaction Type">
                        <Select value={transactionType} onChange={setTransactionType}>
                            <Option value="branch_transfer">Branch Transfer</Option>
                            <Option value="bank_deposit">Bank Deposit</Option>
                        </Select>
                    </Form.Item>

                    {transactionType === 'branch_transfer' && (
                        <Form.Item name="targetBranch" label="Target Branch"
                                   rules={[
                                       {required: true, message: 'Target branch is required for branch transfer'},
                                       {
                                           validator: validateDifferentBranch(form.getFieldValue),
                                       },
                                   ]}
                        >
                            <Select placeholder="Select target branch" allowClear>
                                {branches.map((b) => (
                                    <Option key={b.id} value={b.id}>
                                        {b.branch_name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    )}

                    {transactionType === 'bank_deposit' && (
                        <Form.Item name="bankAccount" label="Bank Account"
                                   rules={[
                                       {required: true, message: 'Bank account is required for bank deposit'}
                                   ]}
                        >
                            <Select placeholder="Select a bank account" allowClear>
                                {bankAccounts.map((acct) => (
                                    <Option key={acct.id} value={acct.id}>
                                        {acct.account_name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    )}
                    {/* Attachment (file) - UNCONTROLLED by Form */}
                    <Form.Item
                        name="attachment"
                            label="Attachment"
                            valuePropName="file"
                               required
                        // 'required' on the label is purely cosmetic; the actual check is in your validator
                               rules={[
                                   {
                                       validator: (_, value) => {
                                           if (!file) {
                                               return Promise.reject(new Error('Attachment is required'));
                                           }
                                           return Promise.resolve();
                                       },
                                   },
                               ]}
                    >
                        <Input type="file" onChange={handleFileChange}/>
                    </Form.Item>


                </Col>
            </Row>

            {/* Final Submit/Cancel Buttons (Bottom-Aligned) */}
            <Row justify="end">
                <Col>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" disabled={submitting}>
                            {transaction ? 'Update' : 'Create'}
                        </Button>
                        <Button onClick={onCancel} style={{marginLeft: 8}}>
                            Cancel
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};
export default CashTransactionForm;

import React, {useState} from 'react';
import {Button, Space, message, Modal, Radio, Select, Popconfirm} from 'antd';
import {
    FileDoneOutlined,
    CheckOutlined,
    CloseOutlined,
    CarryOutOutlined,
    DollarOutlined,
    FileProtectOutlined,
    EditOutlined,
    BankOutlined,
} from '@ant-design/icons';
import {handleOrderAction} from './saleOrderActions';
import ConfirmActionModal from "../common/ConfirmActionModal";
import {closeSaleOrderApi, dispatchSaleOrder, fetchSaleOrderDetailsApi} from "./saleOrdersApi";

const { Option } = Select;

const OrderActionsForDetailsDrawer = ({
                                          orderDetails,
                                          orderId,
                                          invoiceId,
                                          setOrderDetails,
    generatingInvoice,
    setGeneratingInvoice,
                                          handleEditOrder,
                                          refreshOrders,
                                          setErrorMessage,
                                          setSuccessMessage,
    setPaymentFormVisible,

                                      }) => {

    const [confirming, setConfirming] = useState(false);
    const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);

    const [dispatchModalVisible, setDispatchModalVisible] = useState(false);
    const [selectedModeOfDelivery, setSelectedModeOfDelivery] = useState('');

    const [modalConfig, setModalConfig] = useState({
        title: '',
        confirmationText: '',
        action: null,
        inputRequired: false,
        placeholder: '',
    });
    const [remarks, setRemarks] = useState('');


    const handleConfirmAction = async () => {
        setIsConfirmModalVisible(false);
        if (modalConfig.action) {
            setConfirming(true);
            await handleOrderAction({
                orderId,
                invoiceId,
                action: modalConfig.action,
                setOrderDetails,
                setSuccessMessage,
                setErrorMessage,
                setConfirming,
                refreshOrders,
                remarks,
            });
            if (modalConfig.action === 'generateInvoice' || modalConfig.action === 'generateInvoicePdf') {
            setGeneratingInvoice(false); // Stop loading after invoice generation is complete
        }
            setRemarks('');
        }
    };

    // Calculate total received including draft amount
    const totalReceivedIncludingDraft = (orderDetails.received_amount || 0) + (orderDetails.draft_received_amount || 0);



    // Button visibility conditions based on order status
    const showRecordPaymentButton = !['draft', 'closed', 'cancelled'].includes(orderDetails.status) &&
    totalReceivedIncludingDraft < orderDetails.order_value;
    const showConfirmButton = orderDetails.status === 'draft' && orderDetails.customer != null;
    const showApproveRejectButtons =
        orderDetails.status === 'awaiting_approval' && !orderDetails.is_approved;
    const showExecuteButton = orderDetails.status === 'ready_to_execute';
    const showGenerateInvoiceButton = orderDetails.status === 'ready_to_invoice' && (!orderDetails.balance || orderDetails.balance <= 0);
    const showConfirmInvoiceButton = orderDetails.status === 'invoiced';
    const showGeneratePDFButton = orderDetails.invoice_pdf_url === null && orderDetails.invoice_status !== 'draft';
    const showDispatchButton = orderDetails.status === 'ready_to_dispatch';
    const showEditButton =
        orderDetails.status === 'draft' || orderDetails.status === 'rejected';
    const showCloseButton = orderDetails.status === 'dispatched' && orderDetails.balance <= 0;



    // Function to show the confirmation modal
    const showModal = (action, title, confirmationText, inputRequired = false, placeholder = '') => {
        if (action === 'generateInvoice' || action === 'generateInvoicePdf') {
        setGeneratingInvoice(true); // Start loading when initiating invoice generation
    }
        setModalConfig({action, title, confirmationText, inputRequired, placeholder});
        setIsConfirmModalVisible(true);
    };


    const handleDispatch = async () => {
        if (!selectedModeOfDelivery) {
            setErrorMessage('Please select a mode of delivery.');
            return;
        }
        try {
            setConfirming(true);
            console.log("selectedModeOfDelivery: ", selectedModeOfDelivery);
            // Assuming handleOrderAction is adjusted to handle 'dispatch' with mode of delivery
            const response = await dispatchSaleOrder({
                orderId,
                modeOfDelivery: selectedModeOfDelivery,
            });

            setSuccessMessage('Order dispatched successfully');
            refreshOrders();
            const updatedOrder = await fetchSaleOrderDetailsApi(orderId);
        setOrderDetails(updatedOrder);
        } catch (error) {
            setErrorMessage('Failed to dispatch order: ' + error.message);
        } finally {
            setDispatchModalVisible(false);
            setConfirming(false);
        }
    };


    // Close order function
    const handleCloseOrder = async () => {
        try {
            setConfirming(true);
            const response = await closeSaleOrderApi(orderId);
            setSuccessMessage('Order closed successfully');
            refreshOrders();
            const updatedOrder = await fetchSaleOrderDetailsApi(orderId);
            setOrderDetails(updatedOrder);
        } catch (error) {
            setErrorMessage('Failed to close order: ' + error.message);
        } finally {
            setIsConfirmModalVisible(false);
            setConfirming(false);
        }
    };


    return (
        <>
            <Space wrap>
                {/* Record Payment */}
                {showRecordPaymentButton && (
                    <Button
                        type="primary"
                        icon={<BankOutlined />}
                        style={{ backgroundColor: '#52c41a', borderColor: '#52c41a' }}
                        onClick={() => setPaymentFormVisible(true)}  // Open the form
                    >
                        Record Payment
                    </Button>
                )}
                {/* Confirm */}
                {showConfirmButton && (
                    <Button
                        type="primary"
                        icon={<FileDoneOutlined/>}
                        style={{backgroundColor: '#1890ff', borderColor: '#1890ff'}}
                        loading={confirming}
                        onClick={() =>
                            showModal('confirm', 'Confirm Sale Order', 'Are you sure you want to confirm this sale order?')
                        }
                    >
                        Confirm
                    </Button>
                )}

                {/* Approve */}
                {showApproveRejectButtons && (
                    <Button
                        type="default"
                        icon={<CheckOutlined/>}
                        style={{color: '#52c41a', borderColor: '#52c41a'}}
                        onClick={() =>
                            showModal('approve', 'Approve Sale Order', 'Are you sure you want to approve this sale order?')
                        }
                    >
                        Approve
                    </Button>
                )}

                {/* Reject */}
                {showApproveRejectButtons && (
                    <Button
                        type="danger"
                        icon={<CloseOutlined/>}
                        style={{color: '#f5222d', borderColor: '#f5222d'}}
                        onClick={() =>
                            showModal(
                                'reject',
                                'Reject Sale Order',
                                'Are you sure you want to reject this sale order?',
                                true,
                                'Enter rejection reason'
                            )
                        }
                    >
                        Reject
                    </Button>
                )}

                {/* Execute */}
                {showExecuteButton && (
                    <Button
                        type="default"
                        icon={<CarryOutOutlined/>}
                        style={{color: '#2f54eb', borderColor: '#2f54eb'}}
                        onClick={() =>
                            showModal(
                                'execute',
                                'Execute Sale Order',
                                'Are you sure you want to Execute this sale order?')
                        }
                    >
                        Execute
                    </Button>
                )}

                {/* Generate Invoice */}
                {showGenerateInvoiceButton && (
                    <Button
                        type="default"
                        icon={<DollarOutlined/>}
                        style={{color: '#13c2c2', borderColor: '#13c2c2'}}
                        loading={generatingInvoice}
                        onClick={() =>
                            showModal(
                                'generateInvoice',
                                'Generate Invoice',
                                'Are you sure you want to generate an invoice for this sale order?'
                            )
                        }
                    >
                        Generate Invoice
                    </Button>
                )}
                {/* Generate Invoice PDF */}
                {showGeneratePDFButton && (
                    <Button
                        type="default"
                        icon={<DollarOutlined/>}
                        style={{color: '#13c2c2', borderColor: '#13c2c2'}}
                        loading={generatingInvoice}
                        onClick={() =>
                            showModal(
                                'generateInvoicePdf',
                                'Generate Invoice PDF',
                                'Are you sure you want to generate PDF for this invoice?'
                            )
                        }
                    >
                        Generate Invoice PDF
                    </Button>
                )}
                {/* Generate Invoice */}
                {showConfirmInvoiceButton && (
                    <Button
                        type="default"
                        icon={<DollarOutlined/>}
                        style={{color: '#13c2c2', borderColor: '#13c2c2'}}
                        onClick={() =>
                            showModal(
                                'confirmInvoice',
                                'Confirm Invoice',
                                'Are you sure you want to confirm the invoice for this sale order?'
                            )
                        }
                    >
                        Confirm Invoice
                    </Button>
                )}

                {/* Dispatch */}
                {showDispatchButton && (
                    <Button
                        type="default"
                        icon={<FileProtectOutlined/>}
                        style={{color: '#1890ff', borderColor: '#1890ff'}}
                        onClick={() => setDispatchModalVisible(true)}
                    >
                        Dispatch
                    </Button>
                )}

                {/* Edit */}
                {showEditButton && (
                    <Button
                        type="default"
                        onClick={() => handleEditOrder(orderDetails)}
                        icon={<EditOutlined/>}
                        style={{marginRight: 10}}
                    >
                        Edit
                    </Button>
                )}
                {showCloseButton && (


                    <Popconfirm
    title="Are you sure you want to close this sale order?"
    onConfirm={() => handleCloseOrder()}
    okText="Yes"
    cancelText="No"
>
    <Button
        type="primary"
        icon={<FileDoneOutlined />}
        style={{
            backgroundColor: '#417826',  // Green background
            borderColor: '#417826',      // Green border
            color: 'white'               // White text
        }}
    >
        Close Order
    </Button>
</Popconfirm>

                )}
            </Space>

            <Modal
            title="Dispatch Order"
            open={dispatchModalVisible}
            onOk={handleDispatch}
            onCancel={() => setDispatchModalVisible(false)}
            confirmLoading={confirming}
        >
            <Radio.Group
                onChange={(e) => setSelectedModeOfDelivery(e.target.value)}
                value={selectedModeOfDelivery}
                style={{ width: '100%' }}
            >
                <Radio value="delivery">Delivery</Radio>
                <Radio value="pick_up">Pick Up</Radio>
                <Radio value="hand_delivery">Hand Delivery</Radio>
            </Radio.Group>
        </Modal>

            {/* Confirmation Modal */}
            <ConfirmActionModal
                visible={isConfirmModalVisible}
                onConfirm={handleConfirmAction}
                onCancel={() => setIsConfirmModalVisible(false)}
                title={modalConfig.title}
                confirmationText={modalConfig.confirmationText}
                inputRequired={modalConfig.inputRequired}
                inputValue={remarks}
                setInputValue={setRemarks}
                placeholder={modalConfig.placeholder}
                loading={confirming}

            />
        </>
    );
};

export default OrderActionsForDetailsDrawer;

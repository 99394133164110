import axios from 'axios';
import config from '../../config';

/**
 * Fetches cash accounts from the server based on the companyId.
 * @param {number|string} companyId - The ID of the company to filter accounts.
 * @returns {Promise<Array>} A promise that resolves to an array of bank accounts.
 */
export const fetchCashAccountsApi = async (companyId) => {
    const token = localStorage.getItem('token');
    try {
        const response = await axios.get(`${config.inxOmsApiUrl}/core_config/api/cash-accounts`, {
            headers: { Authorization: `Token ${token}` },
            params: {
                partner_company: companyId // Passing additional parameter for filtering
            }
        });
        console.log("fetchCashAccountsApi response", response);
        // Assuming the API returns an array of accounts in the response data
        return response.data;
    } catch (error) {
        console.error('Error fetching cash accounts:', error);
        throw new Error('Failed to fetch cash accounts');
    }
};




export const fetchExpenseAccountsApi = async (companyId) => {
    const token = localStorage.getItem('token');
    try {
        const response = await axios.get(`${config.inxOmsApiUrl}/core_config/api/expense-accounts`, {
            headers: { Authorization: `Token ${token}` },
            params: {
                partner_company_id: companyId // Passing additional parameter for filtering
            }
        });
        console.log("fetchExpenseAccountsApi response", response.data);
        // Assuming the API returns an array of accounts in the response data
        return response.data;
    } catch (error) {
        console.error('Error fetching expense accounts:', error);
        throw new Error('Failed to fetch expense accounts');
    }
};


export const fetchExpenseSubTypesApi = async () => {
    const token = localStorage.getItem('token');
    try {
        const response = await axios.get(`${config.inxOmsApiUrl}/core_config/api/expense-sub-types`, {
            headers: { Authorization: `Token ${token}` },

        });
        console.log("fetchExpenseSubTypesApi response", response.data);
        // Assuming the API returns an array of accounts in the response data
        return response;
    } catch (error) {
        console.error('Error fetching expense sub types:', error);
        throw new Error('Failed to fetch expense sub types');
    }
};
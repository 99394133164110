import React, {useEffect, useState} from 'react';
import {AutoComplete, Button, Col, DatePicker, Descriptions, Form, Input, Modal, Row, Select, Typography,} from 'antd';

import {createCustomerPaymentApi,} from './customerPaymentsApi';
import {searchCustomerApi} from '../customers/customerApi';
import {formatRupee} from '../../utils/utils';
import {fetchCashAccountsApi} from '../common/commonApis';

const {Option} = Select;
const {Text} = Typography;

const NewPaymentForm = ({
                            visible,
                            onCancel,
                            onSuccess,
                            sourceOfInitiation, // 'customer' | 'sale_order' | 'invoice'
                            // Optional parameters

                            orderId,      // e.g., referencing which Sale Order this payment is for
                            customerId,   // e.g., if the customer is known
                            invoiceId,    // e.g., referencing a specific invoice
                            companyId,    // e.g., if the company is known
                            branchId,     // e.g., if the branch is known
                            setErrorMessage,
                            setInfoMessage,
                            setSuccessMessage,
                            orderDetails,
                            refreshOrders
                        }) => {
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    const [bankAccounts, setBankAccounts] = useState([]);
    // For autocomplete
    const [customerSearchLoading, setCustomerSearchLoading] = useState(false);
    const [customerOptions, setCustomerOptions] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    // Local state for the file
    const [file, setFile] = useState(null);

    // Safely handle potentially undefined orderDetails
    const displayAmount = orderDetails ? (orderDetails.invoice_amount || orderDetails.order_value) : 0;
    const displayCompanyName = orderDetails ? orderDetails.company_name : '';
    const displayBranchName = orderDetails ? orderDetails.branch_name : '';
    const displayCustomerName = orderDetails ? orderDetails.customer_name : '';
    const displayOrderNumber = orderDetails ? orderDetails.order_number : '';
    const displayBalanceAmount = orderDetails ? orderDetails.balance : 0;
    const displayReceivedAmount = orderDetails ? orderDetails.received_amount : 0;
    const displayDraftReceivedAmount = orderDetails ? orderDetails.draft_received_amount : 0;

    // If sourceOfInitiation = 'sale_order', show read-only block
    const showInfoInsteadOfInput = sourceOfInitiation === 'sale_order';

    // -----------------------------
    // Fetch Bank Accounts (if needed)
    // -----------------------------
    useEffect(() => {
        const fetchBankAccounts = async () => {
            if (!companyId) return;
            setLoading(true);
            try {
                // We assume orderDetails.company_id is valid
                const accounts = await fetchCashAccountsApi(orderDetails.company_id);
                const filteredAccounts = accounts.filter(account =>
                    account.account_sub_type_name.toLowerCase().includes('bank account')
                );
                setBankAccounts(filteredAccounts);
            } catch (error) {
                setErrorMessage('Failed to fetch bank accounts');
            } finally {
                setLoading(false);
            }
        };

        fetchBankAccounts();
    }, [companyId, setErrorMessage, orderDetails]);

    // -----------------------------
    // handleSubmit => create Payment
    // -----------------------------
    const handleSubmit = async (values) => {
        setLoading(true);
        try {
            // Conditionally set details based on sourceOfInitiation
            const isSaleOrder = sourceOfInitiation === 'sale_order';
            const finalCustomerId = isSaleOrder
                ? orderDetails.customer
                : (selectedCustomer?.id || customerId);
            const finalBranchId = isSaleOrder
                ? orderDetails.branch_id
                : values.branch;
            const finalCompanyId = isSaleOrder
                ? orderDetails.company_id
                : values.company;
            const finalOrderId = isSaleOrder ? orderId : undefined;

            // Construct settlements array if sale_order
            const settlements = isSaleOrder
                ? [{
                    sale_order_id: finalOrderId,
                    amount: values.amount,
                }]
                : [];

            // Attach the file from local state
            let attachment = file || null;

            // Build the payload
            const payload = {
                customer_id: finalCustomerId,
                branch_id: finalBranchId,
                company_id: finalCompanyId,
                payment_type: values.payment_type || 'invoice_payment',
                payment_method: values.payment_method,
                amount: values.amount?.toString(),
                transaction_number: values.transaction_number || '',
                note: values.note || '',
                account_id: values.account || null,
                attachment,
                promised_date: values.promised_date || null,
                settlements,
                order_id: finalOrderId, // Only if relevant
            };

            // Debug: Summarize for user
            const summary = Object.entries(payload)
                .map(([k, v]) => `${k}: ${v}`)
                .join(', ');
            setInfoMessage(`Payload = ${summary}`);

            console.log('payload', payload);
            // Submit to API
            await createCustomerPaymentApi(payload);

            setSuccessMessage('Payment recorded successfully!');
            form.resetFields();
            setFile(null); // Clear out the local file state
            onSuccess();
            if (refreshOrders) refreshOrders();
        } catch (error) {
            setErrorMessage(`Error: ${error.message}`);
        } finally {
            setLoading(false);
        }
    };

    // -----------------------------
    // File Input change
    // -----------------------------
    const handleFileChange = (e) => {
        const files = e.target.files;
        if (files && files.length > 0) {
            setFile(files[0]);
        }
    };

    // -----------------------------
    // Autocomplete for Customer
    // -----------------------------
    const handleCustomerSearch = async (value) => {
        if (value.length < 3) {
            setCustomerOptions([]);
            return;
        }
        setCustomerSearchLoading(true);
        try {
            const results = await searchCustomerApi(value);
            const opts = results.map((cust) => ({
                label: `${cust.name} (${cust.mobile_number})`,
                value: `${cust.name} (${cust.mobile_number})`,
                custObj: cust,
            }));
            setCustomerOptions(opts);
        } catch (err) {
            setErrorMessage('Failed to search for customers');
        } finally {
            setCustomerSearchLoading(false);
        }
    };

    const handleCustomerSelect = (value, option) => {
        // option.custObj is the full object
        setSelectedCustomer(option.custObj);
    };

    // -----------------------------
    // Render
    // -----------------------------
    return (
        <Modal
            title="Record New Payment"
            open={visible}
            onCancel={onCancel}
            footer={null}
            destroyOnClose
            width={800}
            style={{top: 20}}
        >
            <Form form={form} layout="vertical" onFinish={handleSubmit}>
                <Row gutter={16}>
                    {/* Column 1 */}
                    <Col span={12}>
                        {/* Payment Date */}
                        <Form.Item
                            name="payment_date"
                            label="Payment Date"
                            rules={[
                                {required: true, message: 'Please select the payment date'},
                                () => ({
                                    validator(_, value) {
                                        if (value) {
                                            // Create a new date object for comparison
                                            const currentDate = new Date();
                                            currentDate.setHours(0, 0, 0, 0); // Reset time to start of the day

                                            const selectedDate = value.toDate(); // Convert Moment object to JavaScript Date if using Moment with DatePicker
                                            selectedDate.setHours(0, 0, 0, 0); // Reset time to start of the day

                                            if (selectedDate > currentDate) {
                                                return Promise.reject(new Error('Payment date cannot be in the future'));
                                            }
                                        }
                                        return Promise.resolve();
                                    },
                                }),
                            ]}
                        >
                            <DatePicker format="YYYY-MM-DD"/>
                        </Form.Item>


                        {/* Amount */}
                        <Form.Item
                            name="amount"
                            label="Amount"
                            rules={[
                                {required: true, message: 'Please input the payment amount'},
                                () => ({
                                    validator(_, value) {
                                        if (!value) {
                                            return Promise.reject(new Error('Please input the payment amount'));
                                        }
                                        const maxAmount = displayBalanceAmount - displayDraftReceivedAmount;
                                        if (Number(value) > maxAmount) {
                                            return Promise.reject(new Error(`Amount should not be greater than the remaining balance: ${formatRupee(maxAmount)}`));
                                        }
                                        return Promise.resolve();
                                    },
                                }),
                            ]}
                        >
                            <Input prefix="Rs." suffix="INR" type="number"/>
                        </Form.Item>


                        {/* Payment Method */}
                        <Form.Item
                            name="payment_method"
                            label="Mode of Payment"
                            rules={[{required: true, message: 'Please select a payment method'}]}
                        >
                            <Select placeholder="Select a payment method">
                                <Option value="cash">Cash</Option>
                                <Option value="credit_card">Credit Card</Option>
                                <Option value="debit_card">Debit Card</Option>
                                <Option value="upi">UPI</Option>
                                <Option value="bank_transfer">Bank Transfer</Option>
                                <Option value="credit">Credit</Option>
                                {/*<Option value="other">Other</Option>*/}
                            </Select>
                        </Form.Item>

                        {/* Conditionally Show Account */}
                        <Form.Item noStyle shouldUpdate={(prev, cur) => prev.payment_method !== cur.payment_method}>
                            {({getFieldValue}) => {
                                const pm = getFieldValue('payment_method');
                                if (pm && !['cash', 'credit', 'other'].includes(pm)) {
                                    return (
                                        <Form.Item
                                            name="account"
                                            label="Account"
                                            rules={[{required: true, message: 'Please select an account'}]}
                                        >
                                            <Select placeholder="Select an account">
                                                {bankAccounts.map((acct) => (
                                                    <Option key={acct.id} value={acct.id}>
                                                        {acct.account_name}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    );
                                }
                                return null;
                            }}
                        </Form.Item>

                        {/* Transaction Number */}
                        <Form.Item
                            name="transaction_number"
                            label="Transaction Number"
                            rules={[{required: true, message: 'Please enter the transaction number'}]}
                        >
                            <Input placeholder="e.g., 12345"/>
                        </Form.Item>

                        {/* Promised Date (credit only) */}
                        <Form.Item noStyle shouldUpdate={(prev, cur) => prev.payment_method !== cur.payment_method}>
                            {({getFieldValue}) => {
                                const pm = getFieldValue('payment_method');
                                if (pm === 'credit') {
                                    return (
                                        <Form.Item
    name="promised_date"
    label="Promised Date"
    dependencies={['payment_date']} // Ensure that the validator re-runs when payment_date changes
    rules={[
        {
            required: true,
            message: 'Please select the promised date for credit payments',
        },
        ({ getFieldValue }) => ({
            validator(_, value) {
                if (!value) {
                    return Promise.reject(new Error('Please select a promised date'));
                }
                const paymentDate = getFieldValue('payment_date');
                if (!paymentDate) {
                    return Promise.resolve(); // Allow submission if payment date is not set (adjust based on your requirements)
                }
                // Convert both dates to JavaScript Date objects for comparison
                const promisedDate = value.toDate();
                promisedDate.setHours(0, 0, 0, 0); // Reset time to start of the day for accurate comparison

                const paymentDateObject = paymentDate.toDate();
                paymentDateObject.setHours(0, 0, 0, 0); // Reset time to start of the day for accurate comparison

                if (promisedDate <= paymentDateObject) {
                    return Promise.reject(new Error('Promised date must be after the payment date'));
                }
                return Promise.resolve();
            },
        }),
    ]}
>
    <DatePicker format="YYYY-MM-DD"/>
</Form.Item>

                                    );
                                }
                                return null;
                            }}
                        </Form.Item>
                    </Col>

                    {/* Column 2 */}
                    <Col span={12}>
                        {showInfoInsteadOfInput ? (
                            // If "sale_order" => read-only block
                            <>
                                <Descriptions bordered size="small" column={1} style={{marginBottom: 16}}>
                                    <Descriptions.Item label="Company">{displayCompanyName}</Descriptions.Item>
                                    <Descriptions.Item label="Branch">{displayBranchName}</Descriptions.Item>
                                    <Descriptions.Item label="Customer">{displayCustomerName}</Descriptions.Item>
                                    <Descriptions.Item label="Order #">{displayOrderNumber}</Descriptions.Item>
                                    <Descriptions.Item label="Invoice/Order Amount">
                                        {formatRupee(displayAmount)}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Amount Received">
                                        {formatRupee(displayReceivedAmount)}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Balance">
                                        {formatRupee(displayBalanceAmount)}
                                        {displayDraftReceivedAmount > 0 && (
                                            <div style={{color: 'red'}}>
                                                Draft: {formatRupee(displayDraftReceivedAmount)}
                                            </div>
                                        )}
                                    </Descriptions.Item>
                                </Descriptions>
                            </>
                        ) : (
                            // Normal input fields for company, branch, customer
                            <>
                                {/* Company */}
                                <Form.Item
                                    name="company"
                                    label="Company"
                                    rules={[{required: true, message: 'Please select a company'}]}
                                >
                                    <Select placeholder="Select a company">
                                        <Option value="1">Company One</Option>
                                        <Option value="2">Company Two</Option>
                                    </Select>
                                </Form.Item>

                                {/* Branch */}
                                <Form.Item
                                    name="branch"
                                    label="Branch"
                                    rules={[{required: true, message: 'Please select a branch'}]}
                                >
                                    <Select placeholder="Select a branch">
                                        <Option value="10">Branch A</Option>
                                        <Option value="20">Branch B</Option>
                                    </Select>
                                </Form.Item>

                                {/* Customer */}
                                <Form.Item
                                    name="customer"
                                    label="Customer Name"
                                    rules={[{required: true, message: 'Please enter the customer name'}]}
                                >
                                    <AutoComplete
                                        placeholder="Type customer name or mobile (3+ chars)"
                                        onSearch={handleCustomerSearch}
                                        onSelect={handleCustomerSelect}
                                        options={customerOptions}
                                        loading={customerSearchLoading}
                                        allowClear
                                    />
                                </Form.Item>
                            </>
                        )}

                        {/* Attachment (file) - UNCONTROLLED by Form */}
                        <Form.Item label="Attachment">
                            <Input type="file" onChange={handleFileChange}/>
                        </Form.Item>

                        {/* Note */}
                        <Form.Item name="note" label="Note">
                            <Input.TextArea rows={2} placeholder="Additional notes"/>
                        </Form.Item>
                    </Col>
                </Row>

                {/* Submit Button */}
                <Form.Item>
                    <Button type="primary" htmlType="submit" loading={loading} disabled={loading}>
                        Submit Payment
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default NewPaymentForm;

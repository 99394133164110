import {Button, Descriptions, Drawer, Popconfirm, Space, Tag} from 'antd';
import {cancelCashTransaction, confirmCashTransaction} from "./cashTransactionsAPI";
import {formatRupee} from "../../utils/utils";
import {formatDate} from "../../utils/dateUtils";

const TransactionDetailsDrawer = ({
                                      visible,
                                      onClose,
                                      transaction,
                                      refreshTransactions,
                                      setErrorMessage,
                                      setSuccessMessage
                                  }) => {

    // Helper function to confirm a transaction
    const handleConfirm = async () => {
        try {
            await confirmCashTransaction(transaction.id);
            setSuccessMessage('Transaction confirmed');
            refreshTransactions();
            onClose();
        } catch (error) {
            setErrorMessage('Failed to confirm transaction');
        }
    };

    // Helper function to reject a transaction
    const handleCancel = async () => {
        try {
            await cancelCashTransaction(transaction.id);
            setSuccessMessage('Transaction canceled');
            refreshTransactions();
            onClose();
        } catch (error) {
            setErrorMessage('Failed to cancel transaction');
        }
    };

    // Determine if action buttons should be displayed
    const showActionButtons = transaction && transaction.status === 'draft';


    return (
        <Drawer
            title="Transaction Details"
            width={720}
            onClose={onClose}
            open={visible}
            extra={
                <Space>
                    {showActionButtons && (
                        <>
                            <Popconfirm
                                title="Are you sure you want to confirm this transaction?"
                                onConfirm={handleConfirm}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button type="primary">Confirm</Button>
                            </Popconfirm>
                            <Popconfirm
                                title="Are you sure you want to cancel this transaction?"
                                onConfirm={handleCancel}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button danger>Cancel</Button>
                            </Popconfirm>
                        </>
                    )}
                    <Button onClick={onClose}>Close</Button>
                </Space>
            }
        >
            {transaction ? (
                <Descriptions layout="vertical" bordered size="small" column={2}>
                    <Descriptions.Item label="Transaction ID">{transaction.id}</Descriptions.Item>
                    <Descriptions.Item label="Status">
                        <Tag
                            color={transaction.status === 'confirmed' ? 'green' : transaction.status === 'rejected' ? 'red' : 'geekblue'}>
                            {transaction.display_status}
                        </Tag>
                    </Descriptions.Item>
                    <Descriptions.Item label="Source Branch">{transaction.source_branch_name}</Descriptions.Item>
                    <Descriptions.Item label="Amount">{formatRupee(transaction.amount)}</Descriptions.Item>
                    <Descriptions.Item
                        label="Transaction Type">{transaction.display_transaction_type}</Descriptions.Item>
                    <Descriptions.Item
                        label="Bank/Target Branch">{transaction.transaction_type === 'bank_deposit' ? transaction.bank_account_name : transaction.target_branch_name}</Descriptions.Item>

                    <Descriptions.Item
                        label="Transaction Date">{formatDate(transaction.transaction_date)}</Descriptions.Item>
                    <Descriptions.Item label="Description">{transaction.description}</Descriptions.Item>
                    {/* If there are additional fields, continue adding them here. */}
                    {/* Example of additional fields: */}

                    <Descriptions.Item label="Company Code">{transaction.company_code}</Descriptions.Item>

                </Descriptions>

            ) : (
                <p>No transaction details available.</p>
            )}
        </Drawer>
    );
};

export default TransactionDetailsDrawer;
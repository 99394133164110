import React from 'react';
import {Empty, Table, Tag, Tooltip} from 'antd';
import {formatRupee} from "../../utils/utils";

const InventoryInboundTable = ({ data, loading, pagination, onTableChange }) => {
    const columns = [

        { title: 'Brand Name', dataIndex: 'brand_name', key: 'brand_name' },
        { title: 'Item Code', dataIndex: 'item_code', key: 'item_code' },
        { title: 'Quantity', dataIndex: 'remaining_quantity', key: 'remaining_quantity', align: 'right', },
        { title: 'Stock Value', dataIndex: 'stock_value', key: 'stock_value', align: 'right',
            render: (text) => `${formatRupee(text)}`
        },
        { title: 'Age Group', dataIndex: 'age_group', key: 'age_group', align: 'center',
            render: tag => <Tag color="blue">{tag}</Tag>
        },
        { title: 'Company', dataIndex: 'recipient_company_code', key: 'company_code', align: 'center',},
        { title: 'Supplier Code', dataIndex: 'supplier_company_code', key: 'supplier_company_code', align: 'center',},
        { title: 'Supplier Name', dataIndex: 'supplier_company_name', key: 'supplier_company_name', align: 'center',}
    ];

    // Custom pagination summary
    const showPaginationTotal = (total, range) => {
        return `${range[0]}-${range[1]} of ${total} records`;
    };

    return (
        <Table
            columns={columns}
            dataSource={data}
            rowKey="id"
            loading={loading}
            pagination={{
                ...pagination,
                showTotal: showPaginationTotal,
                size: "middle",
                // showSizeChanger: true,
                // pageSizeOptions: ['10', '20', '50', '100'],
            }}
            onChange={onTableChange}
            scroll={{ x: 'max-content' }}
            bordered
            size="small"
            locale={{
                emptyText: (
                    <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description="No records available."
                    />
                ),
            }}
        />
    );
};

export default InventoryInboundTable;

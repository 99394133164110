import axios from 'axios';
import config from '../../config';
import {getAuthToken} from "../../utils/authUtils";


const token = getAuthToken();

export const fetchCustomerPaymentsApi = async (page = 1, search = '') => {
    try {
        const response = await axios.get(`${config.inxOmsApiUrl}/customer_payments/api/customer_payments/`, {
            headers: { Authorization: `Token ${token}` },
            params: { page, search },
        });
        return response.data;
    } catch (error) {
        throw new Error('Failed to load customer payments');
    }
};

export const createCustomerPaymentApi = async (paymentData) => {
    try {
        const response = await axios.post(`${config.inxOmsApiUrl}/customer_payments/api/payment/create/`, paymentData, {
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Token ${token}` }
        });
        console.log("createCustomerPaymentApi response", response);
        if (response.status >= 200 && response.status < 300) {
            console.log("createCustomerPaymentApi response", response);
            return response.data;
        } else {
            throw new Error(`Failed to create payment: ${response.status} ${response.statusText}`);
        }
    } catch (error) {
        throw new Error(error.response?.data?.detail || 'Error creating payment');
    }
};

export const updateCustomerPaymentApi = async (paymentId, paymentData) => {
    try {
        const response = await axios.patch(`${config.inxOmsApiUrl}/customer_payments/api/payment/update/${paymentId}/`, paymentData, {
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Token ${token}` }
        });
        // console.log("updateCustomerPaymentApi response", response);
        return response.data;
    } catch (error) {
        console.log("updateCustomerPaymentApi response", error.response.data);
        throw new Error(error.response?.data.error || 'Failed to update payment');
    }
};

/**
 * Fetches payments associated with a specific sale order from the server.
 * @param {number|string} saleOrderId - The ID of the sale order.
 * @param {number} page - Pagination page number.
 * @param {string} search - Search term to filter payments.
 * @returns {Promise<Object>} A promise that resolves to an object containing payment data.
 */
export const fetchSaleOrderPaymentsApi = async (saleOrderId, page = 1, search = '') => {
    try {
        const response = await axios.get(`${config.inxOmsApiUrl}/customer_payments/api/payments/sale_order/`, {
            headers: { Authorization: `Token ${token}` },
            params: { sale_order_id: saleOrderId, page, search },
        });
        return response.data;
    } catch (error) {
        console.error('Failed to load sale order payments:', error);
        throw new Error('Failed to load sale order payments');
    }
};

export const confirmPaymentApi = async (paymentId) => {
    try {
        const response = await axios.patch(`${config.inxOmsApiUrl}/customer_payments/api/payments/${paymentId}/confirm/`, {}, {
            headers: { Authorization: `Token ${token}` },
        });
        console.log("confirmPaymentApi response", response);
        return response.data; // Handle response data as needed in your application
    } catch (error) {
        console.error('Failed to confirm payment:', error);
        throw new Error('Failed to confirm payment');
    }
};
import React from 'react';
import {Empty, Table, Tag} from 'antd';
import {formatDate} from "../../utils/dateUtils";
import {formatRupee} from "../../utils/utils";
import {PAYMENT_STATUS_COLORS} from "../common/paymentStatusColors";



const columns = [
    { title: '#', dataIndex: 'id', key: 'id' },
    { title: 'Date', dataIndex: 'payment_date', key: 'payment_date', render: date => formatDate(date) },
    { title: 'Customer', dataIndex: 'customer_name', key: 'customer_name' },
    { title: 'Payment Mode', dataIndex: 'payment_method_display', key: 'payment_method_display' },
    { title: 'Amount', dataIndex: 'amount', key: 'amount', render: amount => formatRupee(amount) },
    {
        title: 'Status',
        dataIndex: 'payment_status_display',
        key: 'payment_status_display',
        render: (status, record) => (
            <Tag color={PAYMENT_STATUS_COLORS[record.payment_status]}>
                {status}
            </Tag>
        ),
    },
    { title: 'Promised Date', dataIndex: 'promised_date', key: 'promised_date', render: date => formatDate(date) },
    { title: 'Transaction #', dataIndex: 'transaction_number', key: 'transaction_number' },
    { title: 'Payment Type', dataIndex: 'payment_type_display', key: 'payment_type_display' },
    {title: 'Company', dataIndex: 'company_code', key: 'company_code'},
    { title: 'Account', dataIndex: 'account_name', key: 'account_name'},
];
// Custom pagination summary
    const showPaginationTotal = (total, range) => {
        return `${range[0]}-${range[1]} of ${total} records`;
    };

const CustomerPaymentsTable = ({ data, loading, pagination, onTableChange, onOpenDrawer }) => {
    return (
        <Table
            columns={columns}
            dataSource={data}
            rowKey="id"
            loading={loading}
            onChange={onTableChange}
            pagination={{
                current: pagination.current,
                pageSize: pagination.pageSize,
                total: pagination.total,
                showTotal: showPaginationTotal,
            }}
            onRow={(record) => ({
                onClick: () => onOpenDrawer(record)
            })}
            size="middle"
            scroll={{x: 'max-content'}}
            locale={{
                emptyText: (
                    <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description="No records available."
                    />
                ),
            }}
        />
    );
};

export default CustomerPaymentsTable;

import React, {useEffect, useState} from 'react';
import {AutoComplete, Button, Col, Form, InputNumber, Row, Select, Spin, Table} from 'antd';
import ProductDrawer from "../products/ProductDrawer";
import TextArea from "antd/es/input/TextArea";
import {DeleteOutlined} from "@ant-design/icons";
import {createStockTransferApi} from "./stockTransfersApi";

const {Option} = Select;


const StockTransferForm = ({companies, refreshData, setSuccessMessage, setErrorMessage, setInfoMessage, onCancel}) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [selectedCompanyName, setSelectedCompanyName] = useState('');

    const [branches, setBranches] = useState([]);
    const [productDrawerVisible, setProductDrawerVisible] = useState(false);
    const [items, setItems] = useState([]);

    useEffect(() => {
        // Update branches when company changes
        if (selectedCompany) {
            const company = companies.find(c => c.id === selectedCompany);
            setBranches(company ? company.branches : []);
        }
    }, [selectedCompany, companies]);


    const handleCompanySelect = (value, option) => {
        setSelectedCompany(parseInt(value, 10));
        setSelectedCompanyName(option.label);
        form.setFieldsValue({company: option.label, sourceBranch: null, destinationBranch: null});
    };


    const handleAddProduct = (selectedProduct) => {
        // Check if the product is already added
        const index = items.findIndex(item => item.id === selectedProduct.id);
        if (index > -1) {
            // Update quantity if already added
            const newItems = [...items];
            newItems[index].quantity += 1; // Increment or set specific quantity
            setItems(newItems);
        } else {
            // Add new product with quantity
            setItems([...items, {...selectedProduct, quantity: 1}]);
        }
    };

    const handleRemoveProduct = (productId) => {
        setItems(items.filter(item => item.id !== productId));
    };

    const onFinish = async (values) => {
        setLoading(true);
        const stockTransferData = {
                company: selectedCompany,
                source_branch: branches.find(branch => branch.id === values.sourceBranch)?.id,
                destination_branch: branches.find(branch => branch.id === values.destinationBranch)?.id,
                items: items.map(({id, quantity}) => ({id, quantity})),
                request_remarks: values.remarks
            };


        try {
            const response = await createStockTransferApi(stockTransferData);
            if (response.status === 201) {
                setSuccessMessage('Stock transfer created successfully');
                form.resetFields();
                refreshData();
                setSelectedCompany(null);
                setBranches([]);
                setItems([]);
                onCancel();
            } else {
                setErrorMessage('Failed to create stock transfer');
                console.error('Error:', response.data);
            }
        } catch (error) {
            setErrorMessage(error.message || 'Failed to create stock transfer');
            console.error('API Error:', error);
        } finally {
            setLoading(false);
        }
    };


    const columns = [
        {title: 'ID', dataIndex: 'id', key: 'id', hidden: true},
        {title: 'Item Code', dataIndex: 'item_code', key: 'code'},
        {title: 'Item Name', dataIndex: 'item_name', key: 'name'},
        {
            title: 'Quantity', dataIndex: 'quantity', key: 'quantity',
            render: (text, record) => (
                <InputNumber
                    min={1}
                    value={text}
                    onChange={value => handleQuantityChange(value, record.id)}
                />
            )
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Button type="text" onClick={() => handleRemoveProduct(record.id)} icon={<DeleteOutlined/>}/>
            )
        }
    ];

    const handleQuantityChange = (quantity, productId) => {
        const newItems = items.map(item => {
            if (item.id === productId) {
                return {...item, quantity};
            }
            return item;
        });
        setItems(newItems);
    };

    return (
        <Spin spinning={loading}>
            <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
                autoComplete="off"
            >
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            name="company"
                            label="Company"
                            rules={[{required: true, message: 'Please select a company'}]}
                        >
                            <AutoComplete
                                value={selectedCompanyName}
                                options={companies.map(company => ({
                                    value: String(company.id),
                                    label: company.company_name
                                }))}
                                onSelect={handleCompanySelect}
                                placeholder="Select a company"
                                filterOption={(inputValue, option) =>
                                    option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                }
                            />


                        </Form.Item>
                        <Form.Item
                            name="destinationBranch"
                            label="Destination Branch"
                            rules={[{required: true, message: 'Please select the destination branch'}]}
                        >
                            <Select placeholder="Select destination branch">
                                {branches.map(branch => (
                                    <Option key={branch.id} value={branch.id}>{branch.branch_name}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="sourceBranch"
                            label="Source Branch"
                            rules={[{required: false}]}
                        >
                            <Select placeholder="Select source branch">
                                {branches.map(branch => (
                                    <Option key={branch.id} value={branch.id}>{branch.branch_name}</Option>
                                ))}
                            </Select>
                        </Form.Item>

                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="remarks"
                            label="Remarks"
                        >
                            <TextArea rows={4} placeholder="Enter any remarks here"/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item>
                            <Button type="primary" onClick={() => setProductDrawerVisible(true)}>
                                Add Items
                            </Button>
                        </Form.Item>
                        <Table
                            dataSource={items}
                            columns={columns}
                            rowKey="id"
                            pagination={false}
                        />
                    </Col>
                    <Col span={24}>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" disabled={loading}>
                                Submit
                            </Button>
                            <Button style={{marginLeft: '8px'}} onClick={onCancel}>
                                Cancel
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <ProductDrawer
                visible={productDrawerVisible}
                onClose={() => setProductDrawerVisible(false)}
                onSelectProduct={handleAddProduct}
                selectedProducts={items}
                context="purchase"
            />
        </Spin>
    );


};

export default StockTransferForm;

import axios from 'axios';
import config from "../../config";
import {getAuthToken} from "../../utils/authUtils";
const BASE_URL = '/api/expenses';  // Adjust based on your actual API endpoint

export const getExpenses = async (params) => {
    const token = getAuthToken();
    try {
        const response = await axios.get(`${config.inxOmsApiUrl}/expenses/api/expenses`,
            {
                headers: { Authorization: `Token ${token}` },
                params }
        );
        return response.data;
    } catch (error) {
        console.error('Failed to fetch expenses:', error);
        throw error;
    }
};

export const createExpense = async (data) => {
    const token = getAuthToken();
    try {
        const response = await axios.post(`${config.inxOmsApiUrl}/expenses/api/expenses/register/`,
            data,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Token ${token}` },
            });
        return response.data;
    } catch (error) {
        console.error('Failed to create expense:', error);
        throw error;
    }
};



export const updateExpense = async (id, data) => {
    const token = getAuthToken();
    try {
        const response = await axios.put(`${BASE_URL}/${id}`, data);
        return response.data;
    } catch (error) {
        console.error('Failed to update expense:', error);
        throw error;
    }
};

export const deleteExpense = async (id) => {
    const token = getAuthToken();
    try {
        await axios.delete(`${BASE_URL}/${id}`);
    } catch (error) {
        console.error('Failed to delete expense:', error);
        throw error;
    }
};

import React, { useState, useEffect } from 'react';
import { Card, Statistic, Row, Col, message, Spin } from 'antd';
import {
    WalletOutlined,
    DollarOutlined,
    CreditCardOutlined
} from '@ant-design/icons';
import axios from 'axios';
import config from "../config";
import { formatRupee } from "../utils/utils";

const BranchCashDashboard = ({ branchId: propBranchId }) => {
  const [financialData, setFinancialData] = useState({
    cash_in_hand: 0,
    draft_amount: 0,
    available_cash_in_hand_balance: 0,
    petty_cash_in_hand: 0,
    draft_petty_cash: 0,
    credit_in_hand: 0
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const user = JSON.parse(localStorage.getItem('user'));
  const branchId = propBranchId || user?.branch_id;

  useEffect(() => {
    if (!branchId) {
      message.error("Branch ID is required.");
      return;
    }

    const fetchFinancialData = async () => {
      setLoading(true);
      const token = localStorage.getItem('token');
      try {
        const response = await axios.get(`${config.inxOmsApiUrl}/financials/api/financials/cash_in_hand_draft_balance/?branch_id=${branchId}`, {
          headers: { Authorization: `Token ${token}` },
        });
        setFinancialData(response.data);
      } catch (error) {
        console.error('Failed to fetch financial data:', error);
        setError('Failed to load data. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchFinancialData();
  }, [branchId]);

  if (loading) return <Spin size="large" style={{ display: 'block', textAlign: 'center', marginTop: 50 }} />;
  if (error) return <p style={{ color: 'red', textAlign: 'center' }}>{error}</p>;

  return (
    <div style={{ margin: '20px' }}>
      <Card
        title={`${financialData.branch_code} - ${financialData.branch_name}`}
        bordered={false}
        style={{ marginBottom: '20px' }}
      >
        <Row gutter={[16, 16]} className="financial-statistics">
          <Col span={8}>
            <Statistic
              title={<span><DollarOutlined style={{ marginRight: 8, color: '#6ea154' }} /> Cash in Hand</span>}
              value={formatRupee(financialData.cash_in_hand)}
              suffix="INR"
              valueStyle={{ color: '#6ea154' }} // green
            />
            <p style={{ color: '#595959' }}>Draft: {formatRupee(financialData.draft_amount)} INR</p>
          </Col>
          <Col span={8}>
            <Statistic
              title={<span><WalletOutlined style={{ marginRight: 8, color: '#1890ff' }} /> Petty Cash</span>}
              value={formatRupee(financialData.petty_cash_in_hand)}
              suffix="INR"
              valueStyle={{ color: '#1890ff' }} // blue
            />
            <p style={{ color: '#595959' }}>Draft: {formatRupee(financialData.draft_petty_cash)} INR</p>
          </Col>
          <Col span={8}>
            <Statistic
              title={<span><CreditCardOutlined style={{ marginRight: 8, color: '#f5222d' }} /> Current Outstanding</span>}
              value={formatRupee(financialData.credit_in_hand)}
              suffix="INR"
              valueStyle={{ color: '#f5222d' }} // red
            />
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default BranchCashDashboard;

import React, { useEffect, useState } from 'react';
import {Button, Col, Empty, message, Modal, Row, Select, Table} from 'antd';
import PettyCashTransactionForm from './PettyCashTransactionForm';
import TransactionDetailsDrawer from './PettyCashTransactionDetailsDrawer';

import { formatRupee } from '../../utils/utils';
import { formatDate } from '../../utils/dateUtils';
import {fetchPettyCashTransactions} from "./cashTransactionsAPI";
import {fetchCompanies} from "../../store/slices/companyBranchSlice";
import {useDispatch, useSelector} from "react-redux";

const { Option } = Select;

const PettyCashTransactionsList = () => {
     const dispatch = useDispatch();
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [drawerVisible, setDrawerVisible] = useState(false);

  const [tempSelectedCompany, setTempSelectedCompany] = useState('');

  const {companies, loading: companiesLoading} = useSelector((state) => state.companyBranch);
    const [branches, setBranches] = useState([]);

  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const [selectedStatus, setSelectedStatus] = useState('');
  const [successMessage, setSuccessMessage] = useState(null);
    const [infoMessage, setInfoMessage] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [messageApi, contextHolder] = message.useMessage();

  const user = JSON.parse(localStorage.getItem('user'));
    const userRole = user?.user_role;

    const companyId = user?.company_id || '';
    const companyName = user?.company || '';
    const companyCode = user?.company_code
    const branchId = user?.branch_id || '';
    const branchName = user?.branch || '';
    const branchCode = user?.branch_code || '';

    // Fetch companies on component mount
    useEffect(() => {
        if (!companies || companies.length === 0) {
            dispatch(fetchCompanies());
        }
    }, [dispatch, companies]);

    // Update branches when a company is selected
    useEffect(() => {
        const company = companies.find((comp) => comp.id === tempSelectedCompany);
        setBranches(company ? company.branches : []);
    }, [tempSelectedCompany, companies]);

    // Handle displaying success and error messages using useEffect
    useEffect(() => {
        if (successMessage) {
            messageApi.open({
                type: 'success',
                content: successMessage,
                duration: 2.5,
            });
            setSuccessMessage(null);
        }
        if (errorMessage) {
            messageApi.open({
                type: 'error',
                content: errorMessage,
                duration: 2.5,
            });
            setErrorMessage(null);
        }
        if (infoMessage) {
            messageApi.open({
                type: 'info',
                content: infoMessage,
                duration: 2.5,
            });
            setInfoMessage(null);
        }
    }, [successMessage, errorMessage, infoMessage, messageApi]);

    useEffect(() => {
        loadTransactions();
    }, []);



  useEffect(() => {
    loadTransactions();
  }, [pagination.current, pagination.pageSize, selectedStatus]);

  const loadTransactions = async () => {
    setLoading(true);
    const params = {
      page: pagination.current,
      pageSize: pagination.pageSize,
      status: selectedStatus,
    };
    try {
      const data = await fetchPettyCashTransactions(params);
      setTransactions(data.results);
      setPagination({ ...pagination, total: data.count });
    } catch (error) {
      message.error('Failed to fetch petty cash transactions');
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    { title: '#', dataIndex: 'id', key: 'id' },
    { title: 'Request Date', dataIndex: 'request_date', key: 'request_date', render: (text) => formatDate(text) },
    { title: 'Company Code', dataIndex: 'company_code', key: 'company_code' },
    { title: 'Branch Name', dataIndex: 'branch_name', key: 'branch_name' },
    { title: 'Requested Amount', dataIndex: 'amount_requested', key: 'amount_requested', align: 'right', render: (value) => formatRupee(value) },
    { title: 'Status', dataIndex: 'display_status', key: 'status_display', align: 'center' },
    { title: 'Amount Issued', dataIndex: 'amount_issued', key: 'amount_issued', align: 'right', render: (value) => formatRupee(value) },
    { title: 'Transaction Number', dataIndex: 'transaction_number', key: 'transaction_number' },
      { title: 'Transaction Date', dataIndex: 'transaction_date', key: 'transaction_date', render: (text) => text ? formatDate(text) : 'N/A' },
    { title: 'Bank Account', dataIndex: 'bank_account_name', key: 'bank_account_name' }, // Assuming bank_account_name is part of the response, otherwise adjust accordingly
    { title: 'Requested By', dataIndex: 'requested_by_name', key: 'requested_by_name' },
    { title: 'Approval Date', dataIndex: 'approval_date', key: 'approval_date', render: (text) => text ? formatDate(text) : 'N/A' },
    { title: 'Approved By', dataIndex: 'approved_by_name', key: 'approved_by_name' }, // Assuming you can get approved_by_name from your API
];


  const handleCancel = () => {
        setIsModalVisible(false);
    };

  // Custom pagination summary
    const showPaginationTotal = (total, range) => {
        return `${range[0]}-${range[1]} of ${total} orders`;
    };

  return (
    <div>
        {contextHolder}

      <Row justify="space-between" style={{ marginBottom: 16 }}>
        <Col>
          <Select
            defaultValue=""
            style={{ width: 150 }}
            onChange={setSelectedStatus}
          >
            <Option value="">All Statuses</Option>
            <Option value="pending">Pending</Option>
            <Option value="approved">Approved</Option>
            <Option value="rejected">Rejected</Option>
            <Option value="canceled">Canceled</Option>
            <Option value="completed">Completed</Option>
          </Select>
        </Col>
        <Col>
          <Button type="primary" onClick={() => setIsModalVisible(true)}>
            Add Petty Cash Request
          </Button>
        </Col>
      </Row>

      <Table
        dataSource={transactions}
        columns={columns}
        rowKey="id"
        loading={loading}
        pagination={{
          current: pagination.current,
          pageSize: pagination.pageSize,
          total: pagination.total,
        }}
        size="small"
                scroll={{x: 'max-content'}}
        onRow={(record) => ({
          onClick: () => {
            setSelectedTransaction(record);
            setDrawerVisible(true);
          },
        })}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No transactions available."
            />
          ),
        }}
      />

      <Modal
        title="Add Petty Cash Request"
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
      >
        <PettyCashTransactionForm
          refreshTransactions={loadTransactions}
           onCancel={handleCancel}
          companies={companies}
                    companyId={companyId}
          setTempSelectedCompany={setTempSelectedCompany}
                    branches={branches}
                    branchId={branchId}
                    userRole={userRole}
          setSuccessMessage={setSuccessMessage}
          setErrorMessage={setErrorMessage}
        />
      </Modal>

      <TransactionDetailsDrawer
        visible={drawerVisible}
        onClose={() => setDrawerVisible(false)}
        transaction={selectedTransaction}
        refreshTransactions={loadTransactions}
        setSuccessMessage={setSuccessMessage}
        setErrorMessage={setErrorMessage}
      />
    </div>
  );
};

export default PettyCashTransactionsList;

import React, { useEffect, useState } from 'react';
import { Drawer, Button, Descriptions, Typography, Badge, Spin } from 'antd';
import { generateInvoicePdfApi, fetchInvoiceDetailsApi } from "./invoiceApi";
import { formatDate } from '../../utils/dateUtils';
import { formatRupee } from "../../utils/utils";

const { Title } = Typography;

const InvoiceDetailsDrawer = ({
    visible, onClose, invoice, setInvoice, setErrorMessage, setSuccessMessage
}) => {
    const [localInvoice, setLocalInvoice] = useState(invoice);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLocalInvoice(invoice);
    }, [invoice]);

    const handleGeneratePDF = async () => {
        setLoading(true); // Start loading
        try {
            await generateInvoicePdfApi(localInvoice.id);
            const updatedInvoiceDetails = await fetchInvoiceDetailsApi(localInvoice.id);
            setInvoice(updatedInvoiceDetails);
            setLocalInvoice(updatedInvoiceDetails);
            setSuccessMessage('PDF generated successfully.');
        } catch (error) {
            setErrorMessage('Failed to generate or refresh invoice details: ' + error.message);
        } finally {
            setLoading(false); // End loading
        }
    };

    return (
        <Drawer
            title={
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                    <Title level={4} style={{ margin: 0 }}>Invoice Details</Title>
                    <div>
                        {!localInvoice.invoice_hash && !localInvoice.invoice_pdf && !loading && (
                            <Button onClick={handleGeneratePDF} type="primary" style={{ marginRight: 8 }}>
                                Generate PDF
                            </Button>
                        )}
                        <Button onClick={onClose}>
                            Close
                        </Button>
                    </div>
                </div>
            }
            width={720}
            onClose={onClose}
            open={visible}
        >
            {loading ? (
                <Spin tip="Generating PDF..." size="large" style={{ display: 'block', margin: '20px auto' }} />
            ) : localInvoice ? (
                <Descriptions bordered size="small" column={1} style={{ marginTop: 20 }}>
                    <Descriptions.Item label="Invoice Number">{localInvoice.invoice_number}</Descriptions.Item>
                    <Descriptions.Item label="Customer">{localInvoice.customer_name}</Descriptions.Item>
                    <Descriptions.Item label="Date">{formatDate(localInvoice.invoice_date)}</Descriptions.Item>
                    <Descriptions.Item label="Total">{formatRupee(localInvoice.total)}</Descriptions.Item>
                    <Descriptions.Item label="Status">
                        <Badge status="processing" text={localInvoice.status} />
                    </Descriptions.Item>
                    <Descriptions.Item label="Invoice Hash">{localInvoice.invoice_hash}</Descriptions.Item>
                    {localInvoice.invoice_pdf && (
                        <Descriptions.Item label="Invoice PDF">
                            <a href={localInvoice.invoice_pdf} target="_blank" rel="noopener noreferrer">View PDF</a>
                        </Descriptions.Item>
                    )}
                </Descriptions>
            ) : (
                <Title level={5} type="warning">No invoice data available.</Title>
            )}
        </Drawer>
    );
};

export default InvoiceDetailsDrawer;

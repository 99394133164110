import React, {useEffect, useState} from 'react';
import {Button, Checkbox, Col, Divider, Form, Input, Row, Select} from 'antd';
import {useDispatch, useSelector} from "react-redux";
import {fetchCountriesWithStates} from "../../store/slices/locationSlice";
import './CompanyRegistration.css'; // Ensure you have appropriate CSS for styling

const {Option} = Select;

const CompanyRegistration = ({onCancel, onSubmit, company, setSuccessMessage, setErrorMessage, setInfoMessage}) => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const {countries} = useSelector(state => state.configuration);
    const [states, setStates] = useState([]);
    const [isRegisteredBusiness, setIsRegisteredBusiness] = useState(false);

    useEffect(() => {
        if (countries.length === 0) dispatch(fetchCountriesWithStates());
        if (company) {
            form.setFieldsValue(company);
            setIsRegisteredBusiness(company.branch?.is_registered_business || false);
            if (company.branch?.country) {
                const selectedCountry = countries.find(c => c.id === company.branch.country);
                setStates(selectedCountry?.states || []);
            }
        }
    }, [company, countries, dispatch]);


    const handleCountryChange = (countryId) => {
        const selectedCountry = countries.find(country => country.id === countryId);
        setStates(selectedCountry?.states || []);
        form.setFieldsValue({['branch.state']: null});
    };

    const handleFinish = async (values) => {
        setLoading(true);
        let successful = false;
        try {
            const response = await onSubmit(values); // Assume onSubmit returns a response object
            if (response && response.success) { // Check if the response indicates success
                successful = true; // Set flag if submission was successful
                setSuccessMessage('Registration successful');
            } else {
                setErrorMessage(response || 'Submission failed');
            }
        } catch (error) {
            console.error('Registration failed:', error || 'An error occurred');
            setErrorMessage(error || 'Registration failed');
        } finally {
            if (successful) {
                form.resetFields(); // Reset form only if successful
            }
            setLoading(false);
        }
    };


    return (
        <div className="company-registration-form">
            <h2>{company ? 'Edit Company' : 'Register Company'}</h2>
            <Form
                form={form}
                layout="vertical"
                onFinish={handleFinish}
                initialValues={{
                    is_internal: true,
                    branch: {is_registered_business: false},
                }}
            >
                <Divider orientation="left">Company Details</Divider>
                <Row gutter={[16, 16]}>
                    <Col span={12}>
                        <Form.Item
                            name="company_name"
                            label="Company Name"
                            rules={[{required: true, message: 'Please enter the company name'}]}
                        >
                            <Input placeholder="Enter company name"/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name="company_code"
                            label="Company Code"
                            rules={[{required: true, message: 'Please enter the company code'}]}
                        >
                            <Input placeholder="Enter company code"/>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item name="is_internal" valuePropName="checked" style={{paddingTop: '32px'}}>
                            <Checkbox>Is Internal?</Checkbox>
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={[16, 16]}>
                    <Col span={12}>
                        <Divider orientation="left">Branch Details</Divider>
                        <Form.Item
                            name={['branch', 'branch_name']}
                            label="Branch Name"
                            rules={[{required: true, message: 'Please enter the branch name'}]}
                        >
                            <Input placeholder="Enter branch name"/>
                        </Form.Item>
                        <Form.Item
                            name={['branch', 'branch_code']}
                            label="Branch Code"
                            rules={[{required: true, message: 'Please enter the branch code'}]}
                        >
                            <Input placeholder="Enter branch code"/>
                        </Form.Item>
                        <Form.Item
                            name={['branch', 'is_warehouse']}
                            valuePropName="checked"
                        >
                            <Checkbox>Is Warehouse?</Checkbox>
                        </Form.Item>

                        <Form.Item
                            name={['branch', 'is_registered_business']}
                            valuePropName="checked"
                            label=""
                            style={{paddingTop: '32px'}}
                        >
                            <Checkbox onChange={(e) => setIsRegisteredBusiness(e.target.checked)}>
                                Registered Business?"
                            </Checkbox>
                        </Form.Item>
                        {isRegisteredBusiness ? (
                            <Form.Item
                                name={['branch', 'gstin']}
                                label="GSTIN"
                                rules={[{required: true, message: 'Enter GSTIN (15 characters)'}]}
                            >
                                <Input placeholder="Enter GSTIN"/>
                            </Form.Item>
                        ) : (
                            <Form.Item
                                name={['branch', 'pan']}
                                label="PAN"
                            >
                                <Input placeholder="Enter PAN"/>
                            </Form.Item>
                        )}
                        <Form.Item
                            name={['branch', 'zb_id']}
                            label="ZB ID"
                        >
                            <Input placeholder="Enter ZB ID"/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Divider orientation="left">Address Details</Divider>
                        <Form.Item
                            name={['branch', 'address_line_1']}
                            label="Address Line 1"
                        >
                            <Input placeholder="Enter address line 1"/>
                        </Form.Item>
                        <Form.Item
                            name={['branch', 'address_line_2']}
                            label="Address Line 2"
                        >
                            <Input placeholder="Enter address line 2"/>
                        </Form.Item>
                        <Form.Item
                            name={['branch', 'city']}
                            label="City"
                        >
                            <Input placeholder="Enter city"/>
                        </Form.Item>
                        <Form.Item
                            name={['branch', 'postal_code']}
                            label="Postal Code"
                        >
                            <Input placeholder="Enter postal code"/>
                        </Form.Item>
                        <Form.Item
                            name={['branch', 'phone_number']}
                            label="Phone Number"
                        >
                            <Input placeholder="Enter phone number"/>
                        </Form.Item>
                        <Form.Item
                            name={['branch', 'country']}
                            label="Country"
                            rules={[{required: true, message: 'Select a country'}]}
                        >
                            <Select
                                showSearch
                                placeholder="Select country"
                                optionFilterProp="children"
                                onChange={handleCountryChange}
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().includes(input.toLowerCase())
                                }
                            >
                                {countries.map(country => (
                                    <Option key={country.id} value={country.id}>
                                        {country.country_name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name={['branch', 'state']}
                            label="State"
                            rules={[{required: true, message: 'Please select a state'}]}
                        >
                            <Select
                                showSearch
                                placeholder="Select state"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().includes(input.toLowerCase())
                                }
                            >
                                {states.map(state => (
                                    <Option key={state.id} value={state.id}>
                                        {state.state_name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

                <Row justify="end">
                    <Button onClick={onCancel} style={{marginRight: '8px'}}>Cancel</Button>
                    <Button type="primary" htmlType="submit" loading={loading}>
                        {company ? 'Update Company' : 'Register Company'}
                    </Button>
                </Row>
            </Form>
        </div>
    );
};

export default CompanyRegistration;

const permissionsMap = {
    super_admin: [
        'sales-dashboard',
        'sales',
        'invoices',
        'sales_orders',
        'register_customer',
        'customer-payments',
        'procurement',
        'purchase_orders',
        'product_catalogue',
        'states_list',
        'countries_list',
        'inventory',
        'serial_numbers',
        'partners',
        'partner-branches',
        'inventory-stock-list',
        'stock-transfers',
        'stock-value',
        'payment-cycle-items',
        'refresh_local_storage',
        'petty-cash',
        'cash-transfers',
        'branch-cash-dashboard',
        'company-cash-dashboard',
        'stock-list',
        'expense-list',
        'expense-dashboard',
    ],
    admin: [
        'sales-dashboard',
        'sales',
        'invoices',
        'sales_orders',
        'register_customer',
        'customer-payments',
        'procurement',
        'purchase_orders',
        'product_catalogue',
        'states_list',
        'countries_list',
        'inventory',
        'partners',
        'serial_numbers',
        'partner-branches',
        'inventory-stock-list',
        'stock-transfers',
        'stock-value',
        'payment-cycle-items',
        'refresh_local_storage',
        'petty-cash',
        'cash-transfers',
        'branch-cash-dashboard',
        'company-cash-dashboard',
        'stock-list',
        'expense-list',
        'expense-dashboard',
    ],
    company_manager: [
        'sales-dashboard',
        'sales',
        'invoices',
        'sales_orders',
        'register_customer',
        'customer-payments',
        'procurement',
        'purchase_orders',
        'product_catalogue',
        'states_list',
        'countries_list',
        'inventory',
        'partners',
        'serial_numbers',
        'partner-branches',
        'inventory-stock-list',
        'stock-transfers',
        'stock-value',
        'payment-cycle-items',
        'refresh_local_storage',
        'petty-cash',
        'cash-transfers',
        'branch-cash-dashboard',
        'company-cash-dashboard',
        'stock-list',
        'expense-list',
        'expense-dashboard',
    ],
    manager: ['dashboard', 'product_catalogue', 'option5', 'option6'],
    staff: ['dashboard', 'product_catalogue', 'purchase_requests_draft', ],
    guest: ['dashboard'],
};

export const hasAccess = (userRole, permission) => {
    // console.log(`Checking access for role: ${userRole} to permission: ${permission}`);
    if (!userRole) {
        return true;
    }
        const rolePermissions = permissionsMap[userRole] || [];
    const hasPermission = rolePermissions.includes(permission);
    // console.log(`Result: ${hasPermission}`);
    return hasPermission;
};

export default hasAccess;

import React, {useState, useEffect} from 'react';
import {ProLayout, PageContainer} from '@ant-design/pro-components';
import {Avatar, Button, Divider, Popconfirm, Tooltip, message} from 'antd';
import {LogoutOutlined, UserOutlined} from '@ant-design/icons';
import {Outlet, useNavigate} from 'react-router-dom';
import menuDataRender from "./menuData";
import {useDispatch} from "react-redux";
import {logout} from "../features/auth/authSlice";

const renderDate = () => {
    const today = new Date();
    const dayName = today.toLocaleDateString('en-US', { weekday: 'long' });
    const dateStr = today.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
    return `${dayName}, ${dateStr}`;
};


const LayoutWrapper = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userData = JSON.parse(localStorage.getItem('user') || '{}');
    const [collapsed, setCollapsed] = useState(() => {
        const storedValue = localStorage.getItem('collapsed');
        const initialCollapsed = storedValue !== null ? JSON.parse(storedValue) : true;
        // console.log('[INIT] Collapsed value on load:', initialCollapsed);
        return initialCollapsed;
    });


    // Ant Design message hook for async feedback display
    const [messageApi, contextHolder] = message.useMessage();
    const [logoutStatus, setLogoutStatus] = useState(null); // State to track logout status

    const handleLogout = async () => {
        setLogoutStatus('loading'); // Set logout status to trigger message in useEffect

        try {
            await dispatch(logout()).unwrap();
            setLogoutStatus('success');
            navigate('/'); // Redirect to login page
        } catch (error) {
            setLogoutStatus('error');
        }
    };

    useEffect(() => {
        if (logoutStatus === 'loading') {
            messageApi.open({
                type: 'loading',
                content: 'Logging out...',
                duration: 0, // Persistent until success or failure
            });
        } else if (logoutStatus === 'success') {
            messageApi.destroy(); // Clear the loading message
            messageApi.open({
                type: 'success',
                content: 'Logout successful!',
                duration: 2.5,
            });
        } else if (logoutStatus === 'error') {
            messageApi.destroy(); // Clear the loading message
            messageApi.open({
                type: 'error',
                content: 'Logout failed! Please try again',
                duration: 2.5,
            });
        }

        // Reset logoutStatus after displaying the message
        return () => {
            if (logoutStatus) setLogoutStatus(null);
        };
    }, [logoutStatus, messageApi]);

    const handleCollapse = (collapsedState) => {
        // console.log('[COLLAPSE] Setting collapsed to:', collapsedState);
        setCollapsed(collapsedState);
    };

    // Debugging useEffect to log the collapsed state on change
    useEffect(() => {
        // console.log('[EFFECT] Collapsed state updated:', collapsed);
        localStorage.setItem('collapsed', JSON.stringify(collapsed));
    }, [collapsed]);

    const layoutProps = {
        title: 'INX CORE',
        logo: require('../logo.png'),
        menuDataRender: () => menuDataRender(userData.user_role || 'admin'),
        onMenuHeaderClick: () => navigate('/dashboard'),
        defaultCollapsed: collapsed,
        onCollapse: handleCollapse,
        menuFooterRender: () => (
            <>
                <Divider style={{margin: 0}}/>
                <div style={{display: 'flex', alignItems: 'center', padding: '16px 0'}}>
                    <Avatar
                        src={userData.profile_picture ? userData.profile_picture : undefined}
                        icon={!userData.profile_picture && <UserOutlined/>}
                        size="large"
                        alt="avatar"
                        style={{marginRight: collapsed ? 0 : 8}}
                    >
                        {/* Show user initials if no profile picture */}
                        {!userData.profile_picture && userData.full_name && userData.full_name
                            .split(' ')
                            .map(name => name[0])
                            .join('')}
                    </Avatar>
                    {!collapsed && (
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            width: '80%',
                            justifyContent: 'space-between'
                        }}>
                            <div style={{color: '#595959', fontSize: 16}}>
                                {userData.full_name || 'User'}
                            </div>
                            <Tooltip title="Logout">
                                <Popconfirm
                                    title="Are you sure you want to logout?"
                                    onConfirm={handleLogout}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <Button
                                        type="primary"
                                        icon={<LogoutOutlined/>}
                                        style={{backgroundColor: '#f5222d', borderColor: '#f5222d'}}
                                    />
                                </Popconfirm>
                            </Tooltip>
                        </div>
                    )}
                </div>
            </>
        ),
        menuItemRender: (item, dom) => (
            <div onClick={() => navigate(item.path || '/dashboard')}>{dom}</div>
        ),
    };

    return (
        <ProLayout {...layoutProps}>
            {contextHolder} {/* Place context holder for Ant Design messages */}
            <PageContainer>
                <Outlet/> {/* Render nested routes */}
            </PageContainer>
        </ProLayout>
    );
};

export default LayoutWrapper;

import { getAuthToken, getRoleBasedFilters } from "../../utils/authUtils";
import axios from "axios";
import config from "../../config";

export const fetchCycleItemsApi = async (cycleId, page = 1, pageSize = 10) => {
    const token = getAuthToken();
    const roleFilters = getRoleBasedFilters();
    const params = {
        page: page,
        page_size: pageSize,
        ...roleFilters
    };

    // Only add cycleId to params if it's not empty
    if (cycleId) {
        params.cycle = cycleId;
    }

    try {
        const response = await axios.get(`${config.inxOmsApiUrl}/partner_payments/api/cycle_items/`, {
            headers: { Authorization: `Token ${token}` },
            params: params,
        });
        console.log("Fetched cycle items:", response.data);
        return response.data;
    } catch (error) {
        console.error("Failed to load cycle items:", error);
        throw new Error('Failed to load cycle items');
    }
};

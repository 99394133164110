// src/components/BranchesTable.js
import React from 'react';
import {Table, Button, Space, Empty} from 'antd';
import { EditOutlined, EyeOutlined, DeleteOutlined } from '@ant-design/icons';

const BranchesTable = ({ data, loading, pagination, onTableChange, onEditBranch, onViewBranch, onDeleteBranch }) => {
    const columns = [
        {
            title: 'Branch Name',
            dataIndex: 'branch_name',
            key: 'branch_name',
            sorter: (a, b) => a.branch_name.localeCompare(b.branch_name),
        },
        {
            title: 'Branch Code',
            dataIndex: 'branch_code',
            key: 'branch_code',
        },
        {
            title: 'City',
            dataIndex: 'city',
            key: 'city',
        },
        {
            title: 'State',
            dataIndex: 'state_name',
            key: 'state_name',
        },
        {
            title: 'GSTIN',
            dataIndex: 'gstin',
            key: 'gstin',
            render: gstin => gstin || 'NA',
        },
        {
            title: 'Is Warehouse',
            dataIndex: 'is_warehouse',
            key: 'is_warehouse',
            render: isWarehouse => (isWarehouse ? 'Yes' : 'No'),
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (_, record) => (
                <Space size="middle">
                    {/*<Button size="small" icon={<EditOutlined />} onClick={() => onEditBranch(record)}></Button>*/}
                    <Button size="small" icon={<EyeOutlined />} onClick={() => onViewBranch(record)}></Button>
                </Space>
            ),
        },
    ];

    // Custom pagination summary
    const showPaginationTotal = (total, range) => {
        return `${range[0]}-${range[1]} of ${total} branches`;
    };

    return (
        <Table
            columns={columns}
            dataSource={data}
            rowKey="id"
            loading={loading}
            pagination={{
                ...pagination,
                showTotal: showPaginationTotal,
                // showSizeChanger: true,
                // pageSizeOptions: ['10', '20', '50', '100'],
            }}
            locale={{
                emptyText: (
                    <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description="No orders available."
                    />
                ),
            }}
            scroll={{x: 'max-content'}}
            onChange={onTableChange}
            bordered
            size="small"
        />
    );
};

export default BranchesTable;

import React, {useEffect, useState} from 'react';
import {Button, Input, message} from 'antd';
import {createCompanyApi, fetchCompaniesApi, updateCompanyApi} from './companyApi';
import CompaniesTable from './CompaniesTable';
import CompanyRegistration from './CompanyRegistration'; // Import the CompanyRegistration component

const {Search} = Input;

const CompanyList = () => {
    const [companies, setCompanies] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState({current: 1, pageSize: 10});
    const [searchTerm, setSearchTerm] = useState('');
    const [isRegistrationPage, setIsRegistrationPage] = useState(false); // Toggle between list and registration page
    const [selectedCompany, setSelectedCompany] = useState(null);
    const user = JSON.parse(localStorage.getItem('user')); // Retrieve user data
    const userRole = user?.user_role; // Extract user role
    const [successMessage, setSuccessMessage] = useState(null);
    const [infoMessage, setInfoMessage] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    const [messageApi, contextHolder] = message.useMessage(); // Initialize Ant Design message

    const fetchCompanies = async (page = 1, search = '') => {
        setLoading(true);
        try {
            const data = await fetchCompaniesApi(page, search);
            console.log("Loaded Companies Data:", data); // Ensure data structure is logged
            setCompanies(data);  // Assuming 'data' is the array of companies
            setPagination((prev) => ({
                ...prev,
                total: data.length, // Set total to data length if no pagination
                current: page,
            }));
        } catch (error) {
            console.error("Failed to load companies:", error.message); // Improved error logging
            setErrorMessage(error.message || 'Failed to load companies');
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        fetchCompanies(pagination.current, searchTerm);
    }, [pagination.current, searchTerm]);

    // Handle displaying success and error messages using useEffect
    useEffect(() => {
        if (successMessage) {
            messageApi.open({
                type: 'success',
                content: successMessage,
                duration: 2.5,
            });
            setSuccessMessage(null);
        }
        if (errorMessage) {
            messageApi.open({
                type: 'error',
                content: errorMessage,
                duration: 2.5,
            });
            setErrorMessage(null);
        }
        if (infoMessage) {
            messageApi.open({
                type: 'info',
                content: infoMessage,
                duration: 2.5,
            });
            setInfoMessage(null);
        }
    }, [successMessage, errorMessage, infoMessage, messageApi]);

    const handleSearch = (value) => {
        setSearchTerm(value);
        setPagination((prev) => ({...prev, current: 1}));
    };

    const handleTableChange = (pagination) => {
        setPagination((prev) => ({
            ...prev,
            current: pagination.current,
            pageSize: pagination.pageSize,
        }));
    };

    const handleAddCompany = () => {
        if (userRole === 'super_admin') {
            console.log('Add Country Modal Triggered');
            setSelectedCompany(null);
        setIsRegistrationPage(true);
            // Add your modal logic here
        } else {
            setErrorMessage('You do not have permission to add a partner company');
        }

    };

    const handleEditCompany = (company) => {
        if (userRole === 'super_admin') {
            console.log('Add Country Modal Triggered');
            setSelectedCompany(company);
        setIsRegistrationPage(true);
            // Add your modal logic here
        } else {
            setErrorMessage('You do not have permission to edit partner company details');
        }

    };

    const handleRegistrationSubmit = async (companyData) => {
        console.log('Company Data:', companyData);
        setLoading(true);
        try {
            const response = selectedCompany ? await updateCompanyApi(selectedCompany.id, companyData) : await createCompanyApi(companyData);
            if (response.success) {
                setSuccessMessage(selectedCompany ? 'Company updated successfully' : 'Company and branch registered successfully');
                setIsRegistrationPage(false); // Navigate back to list
                fetchCompanies(pagination.current, searchTerm);
            } else {
                setErrorMessage(response.message || 'Failed to process company data');
                console.error('Error:', response.message);
            }
        } catch (error) {
            setErrorMessage(error.message || 'Failed to process company data');
            console.error('API Error:', error);
        } finally {
            setLoading(false);
        }
    };


    if (isRegistrationPage) {
        return (
            <CompanyRegistration
                onCancel={() => setIsRegistrationPage(false)}
                onSubmit={handleRegistrationSubmit}
                company={selectedCompany}
                setSuccessMessage={setSuccessMessage}
                setErrorMessage={setErrorMessage}
                setInfoMessage={setInfoMessage}
            />
        );
    }

    return (
        <div style={{padding: '24px'}}>
            {contextHolder}
            <h2>Companies List</h2>
            <div style={{marginBottom: '16px', display: 'flex', justifyContent: 'space-between'}}>
                <Search
                    placeholder="Search by company name"
                    onSearch={handleSearch}
                    style={{width: '300px'}}
                    enterButton
                />
                <Button
                    type="primary"
                    onClick={handleAddCompany}
                >
                    Add Company
                </Button>
            </div>
            <CompaniesTable
                data={companies}
                loading={loading}
                pagination={{
                    current: pagination.current,
                    pageSize: pagination.pageSize,
                    total: pagination.total,
                }}
                onTableChange={handleTableChange}
                onEditCompany={handleEditCompany}
            />
        </div>
    );
};

export default CompanyList;

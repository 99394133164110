import React, {useEffect, useState} from 'react';
import {Button, Col, Form, InputNumber, Row, Select} from 'antd';
import moment from 'moment';

import {formatRupee} from '../../utils/utils';
import {createPettyCashTransaction, fetchClosingBalanceBranch} from "./cashTransactionsAPI";

const {Option} = Select;

const PettyCashTransactionForm = ({
                                      onCancel,
                                      refreshTransactions,
                                      setSuccessMessage,
                                      setErrorMessage,
                                      companies,
                                      setTempSelectedCompany,
                                      companyId,
                                      branches,
                                      branchId,
                                      userRole,
                                  }) => {
    const [form] = Form.useForm();
    const [pettyCashDetails, setPettyCashDetails] = useState({
        pettyCashInHand: 0,
        draftPettyCash: 0,
    });
    const [submitting, setSubmitting] = useState(false);


    // Set initial company and branch
    useEffect(() => {
        setTempSelectedCompany(companyId);

        form.setFieldsValue({
            company: companyId,
            branch: branchId,
        });

        if (branchId) {
            handleBranchChange(branchId);

        } else {
            form.resetFields(['branch']);
            setPettyCashDetails({pettyCashInHand: 0, draftPettyCash: 0});
        }
    }, [companyId, branchId]);

    // 1) Ensure amount <= availableBalance
    const validateAmountNotExceedBalance = () => (rule, value) => {
        if (value !== undefined && value <= 0) {
            return Promise.reject(
                new Error(`Amount cannot be 0 or negative.`)
            );
        }
        return Promise.resolve();
    };

    // Handle company change
    const handleCompanyChange = (companyId) => {
        setTempSelectedCompany(companyId);
        form.resetFields(['branch', 'amount_requested']);
        setPettyCashDetails({pettyCashInHand: 0, draftPettyCash: 0});
    };

    // Fetch petty cash balance when the branch changes
    const handleBranchChange = async (branchId) => {
        form.setFieldsValue({
            branch: branchId,
        });

        if (!branchId) {
            setPettyCashDetails({pettyCashInHand: 0, draftPettyCash: 0});
            return;
        }

        try {
            const data = await fetchClosingBalanceBranch(branchId);
            setPettyCashDetails({
                pettyCashInHand: data.petty_cash_in_hand,
                draftPettyCash: data.draft_petty_cash,
            });
        } catch (error) {
            setErrorMessage('Failed to fetch petty cash details');
        }
    };

    const onFinish = async (values) => {
        setSubmitting(true);
        const formattedValues = {
            ...values,
            request_date: moment().format('YYYY-MM-DD'), // Use today's date
        };

        try {
            console.log("formattedValues: ", formattedValues);
            await createPettyCashTransaction(formattedValues);
            setSuccessMessage('Petty cash request submitted successfully');
            form.resetFields();
            setPettyCashDetails({
                pettyCashInHand: 0,
                draftPettyCash: 0,
            });
            onCancel();
            refreshTransactions();
        } catch (error) {
            console.error('Failed to submit petty cash request:', error);
            if (error.response && error.response.data) {
                console.error('Detailed error:', error.response.data);
                setErrorMessage(`Failed to submit petty cash request: ${error.response.data.message}`);
            } else {
                setErrorMessage('Failed to submit petty cash request due to network or server error.');
            }
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            initialValues={{
                company: companyId,
                branch: branchId,
            }}
            autoComplete="off"
        >
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                        name="company"
                        label="Company"
                        rules={[{required: true, message: 'Please select a company'}]}
                    >
                        <Select
                            onChange={handleCompanyChange}
                            disabled={!['super_admin', 'admin'].includes(userRole)}
                            placeholder="Select company"
                        >
                            {companies.map((company) => (
                                <Option key={company.id} value={company.id}>
                                    {company.company_name}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        name="branch"
                        label="Branch"
                        rules={[{required: true, message: 'Please select a branch'}]}
                    >
                        <Select
                            placeholder="Select branch"
                            onChange={handleBranchChange}
                            allowClear
                        >
                            {branches.map((branch) => (
                                <Option key={branch.id} value={branch.id}>
                                    {branch.branch_name}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <div style={{marginTop: 31}}>
                        <p>
                            <strong>Petty Cash in Hand: </strong>
                            {formatRupee(pettyCashDetails.pettyCashInHand)}
                        </p>
                        <p>
                            <strong>Draft Petty Cash: </strong>
                            {formatRupee(pettyCashDetails.draftPettyCash)}
                        </p>
                    </div>
                </Col>
            </Row>

            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                        name="amount_requested"
                        label="Requested Amount"
                        rules={[
                            {required: true, message: 'Please enter the requested amount'},
                            {
                                validator: validateAmountNotExceedBalance(),
                            },
                        ]}
                    >
                        <InputNumber style={{width: '100%'}} min={0} prefix="Rs." suffix="INR"/>
                    </Form.Item>
                </Col>
            </Row>

            <Row justify="end">
                <Col>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" disabled={submitting}>
                            Submit
                        </Button>
                        <Button onClick={onCancel} style={{marginLeft: 8}}>
                            Cancel
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};

export default PettyCashTransactionForm;

import React from 'react';
import { Table, Button, Tag, Space } from 'antd';
import { EditOutlined, EyeOutlined } from '@ant-design/icons';

const CompaniesTable = ({ data, loading, pagination, onTableChange, onEditCompany }) => {
    const columns = [
        {
            title: 'Company Name',
            dataIndex: 'company_name',
            key: 'company_name',
            sorter: (a, b) => a.company_name.localeCompare(b.company_name),
        },
        {
            title: 'Company Code',
            dataIndex: 'company_code',
            key: 'company_code',
            align: 'center',
        },
        {
            title: 'Is Internal',
            dataIndex: 'is_internal',
            key: 'is_internal',
            align: 'center',
            render: isInternal => (isInternal ? 'Yes' : 'No'),
        },
        {
            title: 'Branch Count',
            dataIndex: 'branches',
            key: 'branch_count',
            align: 'center',
            render: branches => branches.length,
        },
        {
            title: 'Actions',
            key: 'actions',
            align: 'center',
            render: (_, record) => (
                <Space size="middle">
                    {/*<Button type="link" icon={<EditOutlined />} onClick={() => console.log('Edit:', record.id)} />*/}
                    <Button type="link" icon={<EyeOutlined />} onClick={() => alert(`Viewing ${record.company_name}`)} />
                </Space>
            ),
        },
    ];

    const expandedRowRender = record => {
        const branchColumns = [
            { title: 'Branch Name', dataIndex: 'branch_name', key: 'branch_name' },
            { title: 'Branch Code', dataIndex: 'branch_code', key: 'branch_code', align: 'center', },
            { title: 'City', dataIndex: 'city', key: 'city', align: 'center', },
            { title: 'State Code', dataIndex: 'state_code', key: 'state_code', align: 'center', },
            { title: 'Phone Number', dataIndex: 'phone_number', key: 'phone_number' },
            { title: 'GSTIN', dataIndex: 'gstin', key: 'gstin' },
            { title: 'Is Warehouse', dataIndex: 'is_warehouse', align: 'center', key: 'is_warehouse', render: isWarehouse => (isWarehouse ? 'Yes' : 'No') }
        ];

        return (
            <Table
                columns={branchColumns}
                dataSource={record.branches}
                pagination={false}
                rowKey="id"
                size="small"
            />
        );
    };

    return (
        <Table
            className="components-table-demo-nested"
            columns={columns}
            expandable={{ expandedRowRender }}
            dataSource={data}
            rowKey="id"
            loading={loading}
            pagination={pagination}
            onChange={onTableChange}
            bordered
            size="small"
        />
    );
};

export default CompaniesTable;

import React, { useState } from 'react';
import { Modal, Input, List, Button, message } from 'antd';

const RecordSerialNumbersModal = ({
    visible,
    onClose,
    onSubmit,
    stockTransferId,
    setSuccessMessage,
    setErrorMessage,
}) => {
    const [serialNumbers, setSerialNumbers] = useState([]);
    const [currentSerial, setCurrentSerial] = useState('');

    const handleAddSerial = () => {
        if (currentSerial.trim()) {
            setSerialNumbers((prev) => [...prev, currentSerial.trim()]);
            setCurrentSerial('');
        } else {
            setErrorMessage('Serial number cannot be empty');
        }
    };

    const handleRemoveSerial = (serial) => {
        setSerialNumbers((prev) => prev.filter((s) => s !== serial));
    };

    const handleSubmit = async () => {
        if (serialNumbers.length === 0) {
            setErrorMessage('Please add at least one serial number');
            return;
        }
        try {
            await onSubmit(stockTransferId, serialNumbers);
            // setSuccessMessage('Serial numbers recorded successfully');
            setSerialNumbers([]);
            onClose();
        } catch (error) {
            setErrorMessage(error.message || 'Failed to record serial numbers');
        }
    };

    return (
        <Modal
            title="Record Serial Numbers"
            open={visible}
            onCancel={onClose}
            onOk={handleSubmit}
            okText="Submit"
        >
            <div style={{ display: 'flex', marginBottom: 16 }}>
                <Input
                    value={currentSerial}
                    onChange={(e) => setCurrentSerial(e.target.value)}
                    placeholder="Enter serial number"
                    style={{ marginRight: 8 }}
                />
                <Button onClick={handleAddSerial} type="primary">
                    Add
                </Button>
            </div>
            <List
                bordered
                dataSource={serialNumbers}
                renderItem={(serial) => (
                    <List.Item
                        actions={[
                            <Button
                                type="link"
                                danger
                                onClick={() => handleRemoveSerial(serial)}
                            >
                                Remove
                            </Button>,
                        ]}
                    >
                        {serial}
                    </List.Item>
                )}
                locale={{ emptyText: 'No serial numbers added yet' }}
            />
        </Modal>
    );
};

export default RecordSerialNumbersModal;

import React, { useEffect, useState } from 'react';
import { Card, Collapse, Col, Row, Statistic, Spin, Typography, message, Table } from 'antd';
import {
    CreditCardOutlined, DollarOutlined,
    WalletOutlined
} from '@ant-design/icons';
import axios from 'axios';
import { formatRupee } from "../utils/utils";
import config from "../config";

const { Panel } = Collapse;
const { Title } = Typography;

const CompanyCashDashboard = () => {
    const [financialData, setFinancialData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        setLoading(true);
        const token = localStorage.getItem('token');
        axios.get(`${config.inxOmsApiUrl}/financials/api/financials/company-financials/`, {
            headers: { Authorization: `Token ${token}` }
        }).then(response => {
            const dataWithKeys = response.data.map(company => ({
                ...company,
                branches: company.branches.map(branch => ({
                    ...branch,
                    key: branch.branch_id // Ensure each branch has a unique 'key' prop
                }))
            }));
            setFinancialData(dataWithKeys);
            setLoading(false);
        }).catch(error => {
            console.error('Failed to fetch financial data:', error);
            setError('Failed to load data. Please try again.');
            setLoading(false);
        });
    }, []);

    if (loading) {
        return <Spin size="large" style={{ display: 'block', textAlign: 'center', marginTop: 50 }} />;
    }

    if (error) {
        return <p style={{ color: 'red', textAlign: 'center' }}>{error}</p>;
    }

    const columns = [
        {
            title: 'Branch',
            key: 'branch_info',
            render: (text, record) => (
                <div>
                    <strong>{record.branch_code}</strong>
                    <div style={{ color: '#595959' }}>{record.branch_name || 'N/A'}</div>
                </div>
            )
        },
        {
            title: 'Cash in Hand',
            key: 'cash_in_hand',
            render: (text, record) => (
                <div>
                    <div style={{ color: '#6ea154', fontWeight:"bold" }}>{formatRupee(record.cash_in_hand)} INR</div>
                    <div style={{ color: '#595959' }}>Draft: {formatRupee(record.draft_amount)} INR</div>
                </div>
            )
        },
        {
            title: 'Petty Cash',
            key: 'petty_cash',
            render: (text, record) => (
                <div>
                    <div style={{ color: '#1890ff', fontWeight:"bold" }}>{formatRupee(record.petty_cash_in_hand)} INR</div>
                    <div style={{ color: '#595959' }}>Draft: {formatRupee(record.draft_petty_cash)} INR</div>
                </div>
            )
        },
        {
            title: 'Current Outstanding',
            key: 'current_outstanding',
            render: (text, record) => (
                <div>
                    <div style={{ color: '#f5222d', fontWeight:"bold" }}>{formatRupee(record.credit_in_hand)} INR</div>
                </div>
            )
        }
    ];


    return (
        <div style={{ margin: '0px' }}>
            {financialData.map((company, index) => (
                <Card
                    key={company.company_id}
                    title={`${company.company_code} - ${company.company_name}`}
                    bordered={false}
                    style={{ marginBottom: '20px' }}
                >
                    <Row gutter={[16, 16]} className="financial-statistics">
                        <Col span={8}>
                            <Statistic
                                title={<span><DollarOutlined style={{ marginRight: 8, color: '#6ea154' }} /> Cash in Hand</span>}
                                value={formatRupee(company.total_cash_in_hand)}
                                suffix="INR"
                                valueStyle={{ color: '#6ea154' }} // green
                            />
                            <p style={{ color: '#595959' }}>Draft: {formatRupee(company.total_draft_amount)} INR</p>
                        </Col>
                        <Col span={8}>
                            <Statistic
                                title={<span><WalletOutlined style={{ marginRight: 8, color: '#1890ff' }} /> Petty Cash</span>}
                                value={formatRupee(company.total_petty_cash)}
                                suffix="INR"
                                valueStyle={{ color: '#1890ff' }} // blue
                            />
                            <p style={{ color: '#595959' }}>Draft: {formatRupee(company.total_draft_petty_cash)} INR</p>
                        </Col>
                        <Col span={8}>
                            <Statistic
                                title={<span><CreditCardOutlined style={{ marginRight: 8, color: '#f5222d' }} /> Current Outstanding</span>}
                                value={formatRupee(company.total_credit)}
                                suffix="INR"
                                valueStyle={{ color: '#f5222d' }} // red
                            />
                        </Col>
                    </Row>
                    <Table
                        dataSource={company.branches}
                        columns={columns}
                        pagination={false}
                        size="small"
                    />
                </Card>
            ))}
        </div>
    );
};

export default CompanyCashDashboard;

import React, { useEffect, useState } from 'react';
import {Button, Col, Input, message, Row} from 'antd';
import CustomerPaymentsTable from './CustomerPaymentsTable';
import { fetchCustomerPaymentsApi } from './customerPaymentsApi';
import CustomerPaymentDetailsDrawer from './CustomerPaymentDetailsDrawer';
import NewPaymentForm from "./NewPaymentForm";
import PaymentFormModal from "./PaymentFormModal";

const { Search } = Input;

const CustomerPaymentsList = () => {
    const [payments, setPayments] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedPayment, setSelectedPayment] = useState(null);
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [paymentToEdit, setPaymentToEdit] = useState(null);

    const [messageApi, contextHolder] = message.useMessage(); // Initialize Ant Design message
    const [successMessage, setSuccessMessage] = useState(null);
    const [infoMessage, setInfoMessage] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        total: 0,
    });

    // Handle displaying success and error messages using useEffect
    useEffect(() => {
        if (successMessage) {
            console.log("Displaying success message:", successMessage);
            messageApi.open({
                type: 'success',
                content: successMessage,
                duration: 2.5,
            });
            setSuccessMessage(null);
        }
        if (errorMessage) {
            console.log("Displaying error message:", errorMessage);
            messageApi.open({
                type: 'error',
                content: errorMessage,
                duration: 2.5,
            });
            setErrorMessage(null);
        }
        if (infoMessage) {
            console.log("Displaying info message:", infoMessage);
            messageApi.open({
                type: 'info',
                content: infoMessage,
                duration: 2.5,
            });
            setInfoMessage(null);
        }
    }, [successMessage, errorMessage, infoMessage, messageApi]);



    useEffect(() => {
        fetchPayments(pagination.current, pagination.pageSize);
    }, [pagination.current, pagination.pageSize]);


    const fetchPayments = async (page, pageSize) => {
    setLoading(true);
    try {
        const response = await fetchCustomerPaymentsApi({ page, pageSize });
            setPayments(response.results);
            setPagination((prev) => ({
                ...prev,
                total: response.count, // Update total count from backend response
            }));
    } catch (error) {
        setErrorMessage(`Error loading payments: ${error.message}`);
    } finally {
        setLoading(false);
    }
};


    const handleEditPayment = (payment) => {
    // Called from the drawer
    setPaymentToEdit(payment);
    setShowEditModal(true);
    // Optionally close the drawer, if that makes sense in your flow
    setDrawerVisible(false);
  };

  const handleCloseEditModal = () => {
    setShowEditModal(false);
    setPaymentToEdit(null);
  };

  const handleEditSuccess = () => {
    // After editing, refresh data or show success message
    setShowEditModal(false);
    setPaymentToEdit(null);
    // fetchPayments();
  };


    const handleOpenDrawer = (payment) => {
        setSelectedPayment(payment);
        setDrawerVisible(true);
    };

    const handleCloseDrawer = () => {
        setDrawerVisible(false);
    };

    const handleFormSuccess = () => {
        setShowForm(false);
        fetchPayments(pagination.current, pagination.pageSize);  // Re-fetch payments to update the list
    };

    const handleTableChange = (pagination) => {
        setPagination({
            ...pagination,
            current: pagination.current,
            pageSize: pagination.pageSize,
        });
    };

    return (
        <div style={{ padding: '24px' }}>
            {contextHolder}
            <Row justify="space-between" align="middle" style={{ marginBottom: 16 }}>
                <Col>
                    <Search
                        placeholder="Search payments..."
                        onSearch={(value) => console.log(value)}
                        style={{ width: 200, marginRight: 8 }}
                    />
                </Col>
                <Col>
                    <Button type="primary" disabled onClick={() => setShowForm(true)}>
                        Add New Payment
                    </Button>
                </Col>
            </Row>
            <CustomerPaymentsTable
                data={payments}
                loading={loading}
                onOpenDrawer={handleOpenDrawer}
                pagination={pagination}
                onTableChange={handleTableChange}
                setSuccessMessage={setSuccessMessage}
                setErrorMessage={setErrorMessage}
                setInfoMessage={setInfoMessage}
            />
            {selectedPayment && (
                <CustomerPaymentDetailsDrawer
                    visible={drawerVisible}
                    payment={selectedPayment}
                    onEditPayment={handleEditPayment}
                    onClose={handleCloseDrawer}
                    setSuccessMessage={setSuccessMessage}
                setErrorMessage={setErrorMessage}
                setInfoMessage={setInfoMessage}
                />
            )}
            {showForm && (
                <NewPaymentForm
                    visible={showForm}
                    onCancel={() => setShowForm(false)}
                    onSuccess={handleFormSuccess}
                    setSuccessMessage={setSuccessMessage}
                setErrorMessage={setErrorMessage}
                setInfoMessage={setInfoMessage}
                />
            )}
            {showEditModal && (
        <PaymentFormModal
          visible={showEditModal}
          onCancel={handleCloseEditModal}
          onSuccess={handleEditSuccess}
          fetchPayments={fetchPayments}
          setSuccessMessage={setSuccessMessage}
                setErrorMessage={setErrorMessage}
                setInfoMessage={setInfoMessage}
          existingPayment={paymentToEdit} // load form in "edit mode"
          // other props...
        />
      )}
        </div>
    );
};

export default CustomerPaymentsList;

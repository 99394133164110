import React from 'react';
import { DatePicker, Form, Input, message, Modal } from 'antd';
import moment from 'moment';

const UpdateInvoiceModal = ({ isVisible, onClose, invoiceDetails, onUpdate }) => {
    const [form] = Form.useForm();

    const handleUpdate = async () => {
        try {
            const values = await form.validateFields();
            values.invoice_date = values.invoice_date.format('YYYY-MM-DD');
            values.invoice_due_date = values.invoice_due_date.format('YYYY-MM-DD');
            onUpdate(values); // Assume onUpdate is a prop function that handles the API call
            onClose(); // Close the modal on success
        } catch (error) {
            message.error('Please correct the errors in the form.');
        }
    };

    // Custom validator to ensure due date is not before invoice date
    const validateDueDate = async (_, value) => {
        const invoiceDateValue = form.getFieldValue('invoice_date');
        if (value && invoiceDateValue && value.isBefore(invoiceDateValue)) {
            return Promise.reject(new Error('Due date cannot be before the invoice date.'));
        }
        return Promise.resolve();
    };

    return (
        <Modal
            title="Update Invoice Details"
            open={isVisible}
            onOk={handleUpdate}
            onCancel={onClose}
            okText="Update"
            cancelText="Cancel"
        >
            <Form
                form={form}
                layout="vertical"
                initialValues={{
                    invoice_number: invoiceDetails.invoice_number,
                    invoice_amount: invoiceDetails.invoice_amount,
                    invoice_date: invoiceDetails.invoice_date ? moment(invoiceDetails.invoice_date) : null,
                    invoice_due_date: invoiceDetails.invoice_due_date ? moment(invoiceDetails.invoice_due_date) : null,
                }}
            >
                <Form.Item
                    name="invoice_number"
                    label="Invoice Number"
                    rules={[{ required: true, message: 'Invoice number is required' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="invoice_amount"
                    label="Invoice Amount"
                    rules={[{ required: true, message: 'Invoice amount is required' }]}
                >
                    <Input prefix="₹" type="number" />
                </Form.Item>
                <Form.Item
                    name="invoice_date"
                    label="Invoice Date"
                    rules={[{ required: true, message: 'Invoice date is required' }]}
                >
                    <DatePicker />
                </Form.Item>
                <Form.Item
                    name="invoice_due_date"
                    label="Due Date"
                    rules={[
                        { required: true, message: 'Due date is required' },
                        { validator: validateDueDate }
                    ]}
                >
                    <DatePicker />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default UpdateInvoiceModal;

import axios from 'axios';
import config from '../../config';
import {getAuthToken} from "../../utils/authUtils";

const token = getAuthToken();

export const fetchBranchesApi = async () => {
    try {
        const response = await axios.get(`${config.inxOmsApiUrl}/core_config/api/branches/`, {
            headers: { Authorization: `Token ${token}` }
        });
        return response.data;  // Assuming the API returns an array of branches
    } catch (error) {
        console.error("API Error:", error.response ? error.response.data : 'Failed to load branches');
        throw new Error('Failed to load branches');
    }
};

export const createBranchApi = async (branchData) => {
    try {
        const response = await axios.post(`${config.inxOmsApiUrl}/core_config/api/register_branch/`, branchData, {
            headers: { Authorization: `Token ${token}`, 'Content-Type': 'application/json' }
        });
        if (response.status === 201) {
            return { success: true, data: response.data };  // Return success status and data if HTTP 201
        } else {
            return { success: false, message: "Unexpected status code received" };
        }
    } catch (error) {
        console.error("API Error:", error.response ? error.response.data : error);
        return { success: false, message: error.response?.data.error || 'Failed to create branch' };
    }
};

export const updateBranchApi = async (branchId, branchData) => {
    // const token = localStorage.getItem('token');
    // try {
    //     const response = await axios.patch(`${config.inxOmsApiUrl}/core_config/api/branches/${branchId}/`, branchData, {
    //         headers: { Authorization: `Token ${token}`, 'Content-Type': 'application/json' }
    //     });
    //     if (response.status === 200) {
    //         return { success: true, data: response.data };  // Assume success if HTTP status 200
    //     } else {
    //         return { success: false, message: "Unexpected status code received" };
    //     }
    // } catch (error) {
    //     console.error("API Error:", error.response ? error.response.data : error);
    //     return { success: false, message: error.response?.data.error || 'Failed to update branch' };
    // }
    console.log("Feature is not available yet!");
};

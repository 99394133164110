import React from 'react';
import {Card, Col, Table, Tooltip} from 'antd';
import {LineChartOutlined} from '@ant-design/icons';
import {formatRupee, truncateText} from '../../utils/utils';

const BranchPerformanceTable = ({branchPerformance = []}) => {
    const columns = [
        {
            title: 'Branch',
            dataIndex: 'branch_name',
            key: 'branch_name',
            ellipsis: true,
            render: (text) => (
                <Tooltip title={text || '-'}>
                    <span style={{whiteSpace: 'nowrap'}}>{truncateText(text || '-', 20)}</span>
                </Tooltip>
            ),
        },
        {
            title: 'Company',
            dataIndex: 'company_code',
            key: 'company_code',
            ellipsis: true, // Prevent content wrapping
        },
        {
            title: 'Revenue',
            dataIndex: 'current_month_revenue',
            key: 'current_month_revenue',
            align: 'right',
            render: (value, record) => {
                const growth = record.current_month_revenue - record.last_month_revenue;
                const growthPercentage =
                    record.last_month_revenue > 0
                        ? ((growth / record.last_month_revenue) * 100).toFixed(2)
                        : null;

                const isGrowth = growth > 0;

                return (
                    <Tooltip
                        title={`Last Month: ${formatRupee(record.last_month_revenue || 0)}`}
                        placement="topRight"
                    >
                        <div style={{display: 'block'}}>
    <span
        style={{
            color: isGrowth ? 'green' : growth < 0 ? 'red' : 'inherit',
            fontWeight: 'bold',
            display: 'block'  // This makes the span take up the full width available
        }}
    >
        {formatRupee(value)}
    </span>
                            {growthPercentage !== null && (
                                <span
                                    style={{
                                        display: 'block',  // This ensures the percentage appears on the next line
                                        color: isGrowth ? 'green' : growth < 0 ? 'red' : 'inherit',
                                        fontWeight: 'bold'
                                    }}
                                >
            ({isGrowth ? '+' : ''}
                                    {growthPercentage}%)
        </span>
                            )}
                        </div>

                    </Tooltip>
                );
            },
        },
        {
            title: 'Target Revenue',
            dataIndex: 'current_month_revenue_target',
            key: 'current_month_revenue_target',
            align: 'right',
            render: (value) => formatRupee(value || 0),
        },
        {
            title: 'Target Achieved (%)',
            key: 'target_achieved',
            align: 'right',
            render: (value, record) => {
                const currentPercentage = parseFloat(record.percent_current_target_achieved);
                const growth = record.percent_current_target_achieved - record.percent_last_target_achieved;
                const growthPercentage =
                    record.percent_last_target_achieved > 0
                        ? ((growth / record.percent_last_target_achieved) * 100).toFixed(2)
                        : null;

                const isGrowth = growth > 0;

                return (
                    <Tooltip
                        title={`Last Month: ${record.percent_last_target_achieved || 0}%`}
                        placement="topRight"
                    >
                        <div style={{display: 'block'}}>
    <span
        style={{
            color: isGrowth ? 'green' : growth < 0 ? 'red' : 'inherit',
            fontWeight: 'bold',
            display: 'block'  // This makes the span take up the full width available
        }}
    >
        {currentPercentage !== null && !isNaN(currentPercentage)
            ? `${currentPercentage}%`
            : 'N/A'}
    </span>
                            {growthPercentage !== null && (
                                <span
                                    style={{
                                        display: 'block',  // This ensures the percentage appears on the next line
                                        color: isGrowth ? 'green' : growth < 0 ? 'red' : 'inherit',
                                        fontWeight: 'bold'
                                    }}
                                >
            ({isGrowth ? '+' : ''}
                                    {growthPercentage}%)
        </span>
                            )}
                        </div>

                    </Tooltip>
                );
            },
        },
    ];

    return (
        <Col xs={24} md={24}>
            <Card
                title={
                    <>
                        <LineChartOutlined/> Branch Performance
                    </>
                }
                style={{height: '100%', padding: '0'}}
            >
                <Table
                    dataSource={branchPerformance.map((branch, index) => ({
                        ...branch,
                        key: index,
                    }))}
                    columns={columns}
                    pagination={false}
                    size="small"
                    locale={{emptyText: 'No branch performance data available'}} // Fallback for empty data
                    scroll={{x: '100%'}} // Enable horizontal scroll
                />
            </Card>
        </Col>
    );
};

export default BranchPerformanceTable;

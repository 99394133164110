import React, {useEffect, useState} from 'react';
import {Checkbox, Col, Empty, Form, Input, Modal, Row, Select} from 'antd';
import {useDispatch, useSelector} from 'react-redux';
import {fetchCountriesWithStates,} from "../../store/slices/locationSlice";

const {Option} = Select;


const AddressForm = ({visible, onSubmit, onCancel, initialData}) => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const [sameAsBilling, setSameAsBilling] = useState(false);

    const {countries} = useSelector(state => state.configuration);
    const [billingStates, setBillingStates] = useState([]);
    const [shippingStates, setShippingStates] = useState([]);

    useEffect(() => {
        console.log("form: ", form);
        // Ensure countries are fetched and form is ready
        if (countries.length === 0) {
            dispatch(fetchCountriesWithStates());
        } else {
            const setupDefaultValues = () => {
                const defaultCountryId = initialData?.billingCountry || countries.find(country => country.country_name === 'INDIA')?.id;
                if (defaultCountryId && form) {  // Check if form is defined
                    const defaultCountry = countries.find(country => country.id === defaultCountryId);
                    if (defaultCountry) {
                        const {country_code, country_name} = defaultCountry;
                        form.setFieldsValue({
                            billingCountry: defaultCountryId,
                            shippingCountry: defaultCountryId,
                            billingCountryCode: country_code,
                            shippingCountryCode: country_code,
                            billingCountryName: country_name,
                            shippingCountryName: country_name,
                        });

                        handleCountryChange(defaultCountryId, 'billing');
                        handleCountryChange(defaultCountryId, 'shipping');
                    }
                }
            };

            setupDefaultValues();
        }
    }, [initialData, form, countries.length, dispatch]);


    const handleSameAsBillingChange = checked => {
        setSameAsBilling(checked);
        if (checked) {
            const billingData = form.getFieldsValue();
            form.setFieldsValue({
                shippingAddress: billingData.billingAddress,
                shippingAddress2: billingData.billingAddress2,
                shippingCity: billingData.billingCity,
                shippingCountry: billingData.billingCountry,
                shippingCountryName: billingData.billingCountryName,
                shippingCountryCode: billingData.billingCountryCode,
                shippingState: billingData.billingState,
                shippingPostalCode: billingData.billingPostalCode,
                shippingStateName: billingData.billingStateName

            });
            setShippingStates(billingStates);
        } else {
            form.resetFields([
                'shippingAddress', 'shippingAddress2', 'shippingCity', 'shippingCountry', 'shippingState',
                'shippingPostalCode', 'shippingCountryCode', 'shippingCountryName', 'shippingStateName'
            ]);
            handleCountryChange(form.getFieldValue('shippingCountry'), 'shipping');
        }
    };

    const handleCountryChange = (countryId, field) => {
        const selectedCountry = countries.find(country => country.id === countryId);
        const states = selectedCountry?.states || [];
        const countryCode = selectedCountry?.country_code || '';
        const countryName = selectedCountry?.country_name || '';

        form.setFieldsValue({
            [`${field}Country`]: countryId,
            [`${field}CountryCode`]: countryCode,
            [`${field}CountryName`]: countryName,
            [`${field}State`]: null,  // Clear state selection when country changes
            [`${field}StateName`]: ''  // Clear state name when country changes
        });


        field === 'billing' ? setBillingStates(states) : setShippingStates(states);
    };

    const handleStateChange = (stateId, field) => {
        const selectedState = (field === 'billing' ? billingStates : shippingStates).find(state => state.id === stateId);
        const stateName = selectedState?.state_name || '';
        form.setFieldsValue({
            [`${field}StateName`]: stateName
        });
    };

    const onFinish = values => {
        // Include the country codes directly in the submission values
        onSubmit({
            ...values,
            billingCountryCode: form.getFieldValue('billingCountryCode'),
            shippingCountryCode: form.getFieldValue('shippingCountryCode'),
            billingCountryName: form.getFieldValue('billingCountryName'),
            shippingCountryName: form.getFieldValue('shippingCountryName'),
            billingStateName: form.getFieldValue('billingStateName'),
            shippingStateName: form.getFieldValue('shippingStateName'),
            same_as_billing_address: sameAsBilling,
        });
    };

    return (
        <Modal title="Edit Addresses" open={visible} onOk={() => form.submit()} onCancel={onCancel}>
            <Form form={form} layout="vertical" onFinish={onFinish} initialValues={initialData}>

                <Row gutter={16}>
                    <Col span={24}>
                        <Checkbox value={sameAsBilling} checked={sameAsBilling}
                                  onChange={e => handleSameAsBillingChange(e.target.checked)}>
                            Same as Billing Address
                        </Checkbox>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>

                        <Form.Item name="billingAddress" label="Billing Address" rules={[{required: true}]}>
                            <Input/>
                        </Form.Item>
                        <Form.Item name="billingAddress2" label="Billing Address Line 2">
                            <Input/>
                        </Form.Item>
                        <Form.Item name="billingCity" label="City" rules={[{required: true}]}>
                            <Input/>
                        </Form.Item>
                        <Form.Item name="billingCountry" label="Billing Country" rules={[{required: true}]}>

                            <Select
                                showSearch
                                placeholder="Select Country"
                                optionFilterProp="children"
                                onChange={value => handleCountryChange(value, 'billing')}
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().includes(input.toLowerCase())
                                }
                                notFoundContent={
                                    <Empty
                                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                                        description="No countries found."
                                    />
                                }
                            >
                                {countries.map((country) => (
                                    <Option key={country.id} value={country.id}>{country.country_name}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item name="billingCountryCode" hidden>
                            <Input hidden/>
                        </Form.Item>
                        <Form.Item name="billingCountryName" hidden>
                            <Input hidden/>
                        </Form.Item>
                        <Form.Item name="billingStateName" hidden>
                            <Input hidden/>
                        </Form.Item>
                        <Form.Item name="billingState" label="State" rules={[{required: true}]}>


                            <Select
                                showSearch
                                placeholder="Select State"
                                optionFilterProp="children"
                                onChange={value => handleStateChange(value, 'billing')}
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().includes(input.toLowerCase())
                                }
                                notFoundContent={
                                    <Empty
                                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                                        description="Select Country."
                                    />
                                }
                            >
                                {billingStates.map((state) => (
                                    <Option key={state.id} value={state.id}>{state.state_name}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item name="billingPostalCode" label="Postal Code" rules={[{required: true}]}>
                            <Input/>
                        </Form.Item>

                    </Col>
                    <Col span={12}>

                        <Form.Item name="shippingAddress" label="Shipping Address" rules={[{required: !sameAsBilling}]}>
                            <Input disabled={sameAsBilling}/>
                        </Form.Item>
                        <Form.Item name="shippingAddress2" label="Shipping Address Line 2">
                            <Input disabled={sameAsBilling}/>
                        </Form.Item>
                        <Form.Item name="shippingCity" label="City" rules={[{required: !sameAsBilling}]}>
                            <Input disabled={sameAsBilling}/>
                        </Form.Item>
                        <Form.Item name="shippingCountryCode" hidden>
                            <Input hidden/>
                        </Form.Item>
                        <Form.Item name="shippingCountry" label="Shipping Country" rules={[{required: !sameAsBilling}]}>
                            <Select
                                showSearch
                                disabled={sameAsBilling}
                                placeholder="Select Country"
                                optionFilterProp="children"
                                onChange={value => handleCountryChange(value, 'shipping')}
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().includes(input.toLowerCase())
                                }
                                notFoundContent={
                                    <Empty
                                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                                        description="No countries found."
                                    />
                                }
                            >
                                {countries.map((country) => (
                                    <Option key={country.id} value={country.id}>{country.country_name}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item name="shippingState" label="State" rules={[{required: !sameAsBilling}]}>

                            <Select
                                showSearch
                                disabled={sameAsBilling}
                                placeholder="Select State"
                                optionFilterProp="children"
                                onChange={value => handleStateChange(value, 'shipping')}
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().includes(input.toLowerCase())
                                }
                                notFoundContent={
                                    <Empty
                                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                                        description="Select Country."
                                    />
                                }
                            >
                                {shippingStates.map((state) => (
                                    <Option key={state.id} value={state.id}>{state.state_name}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item name="shippingPostalCode" label="Postal Code" rules={[{required: !sameAsBilling}]}>
                            <Input disabled={sameAsBilling}/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default AddressForm;

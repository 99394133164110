import React, {useEffect, useState} from 'react';
import {AutoComplete, Button, Col, Input, message, Row} from 'antd';
import StockTransfersTable from './StockTransfersTable';
import {
    cancelStockTransferApi,
    closeStockTransferApi,
    confirmStockTransferApi,
    dispatchStockTransferApi,
    executeStockTransferApi,
    fetchStockTransfersApi,
    receiveStockTransferApi
} from "./stockTransfersApi";
import {fetchCompaniesApi} from "../partners/companyApi";
import StockTransferForm from "./StockTransferForm";
import StockTransferDetailsDrawer from "./StockTransferDetailsDrawer";
import SourceBranchUpdateModal from "./SourceBranchUpdateModal";


const {Search} = Input;

const StockTransfersList = () => {
    const [showForm, setShowForm] = useState(false);
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [selectedStockTransfer, setSelectedStockTransfer] = useState(null);

    const [modalVisible, setModalVisible] = useState(false);
    const [currentTransferId, setCurrentTransferId] = useState(null);
    const [selectCompanyId, setSelectCompanyId] = useState(null);


    const [transfers, setTransfers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState({current: 1, pageSize: 10});
    const [searchTerm, setSearchTerm] = useState('');

    const [companies, setCompanies] = useState([]);

    const [successMessage, setSuccessMessage] = useState(null);
    const [infoMessage, setInfoMessage] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [messageApi, contextHolder] = message.useMessage();

    // Handle displaying success and error messages using useEffect
    useEffect(() => {
        if (successMessage) {
            messageApi.open({
                type: 'success',
                content: successMessage,
                duration: 2.5,
            });
            setSuccessMessage(null);
        }
        if (errorMessage) {
            messageApi.open({
                type: 'error',
                content: errorMessage,
                duration: 2.5,
            });
            setErrorMessage(null);
        }
        if (infoMessage) {
            messageApi.open({
                type: 'info',
                content: infoMessage,
                duration: 2.5,
            });
            setInfoMessage(null);
        }
    }, [successMessage, errorMessage, infoMessage, messageApi]);

    useEffect(() => {
    if (selectedStockTransfer) {
        const updatedTransfer = transfers.find(
            transfer => transfer.id === selectedStockTransfer.id
        );
        if (updatedTransfer) {
            setSelectedStockTransfer(updatedTransfer);
        }
    }
}, [transfers, selectedStockTransfer]);



    const fetchCompanies = async (page = 1, search = '') => {
        setLoading(true);
        try {
            const data = await fetchCompaniesApi(page, search);
            console.log("Loaded Companies Data:", data); // Ensure data structure is logged
            setCompanies(data);  // Assuming 'data' is the array of companies
            setPagination((prev) => ({
                ...prev,
                total: data.length, // Set total to data length if no pagination
                current: page,
            }));
        } catch (error) {
            console.error("Failed to load companies:", error.message); // Improved error logging
            setErrorMessage(error.message || 'Failed to load companies');
        } finally {
            setLoading(false);
        }
    };

    const fetchTransfers = async (page = 1, search = '') => {
        setLoading(true);
        try {
            const data = await fetchStockTransfersApi(page, search);
            setTransfers(data.results);
            setPagination((prev) => ({
                ...prev,
                total: data.count,
                current: page,
            }));
        } catch (error) {
            message.error(error.message);
        } finally {
            setLoading(false);
        }
    };

    // Function to refresh the current page
    const refreshData = async () => {
        await fetchTransfers(pagination.current, searchTerm);

        // Update the selected stock transfer if the drawer is open
        if (selectedStockTransfer) {
            const updatedTransfer = transfers.find(
                (transfer) => transfer.id === selectedStockTransfer.id
            );
            if (updatedTransfer) {
                setSelectedStockTransfer(updatedTransfer);
            }
        }
    };

    useEffect(() => {
        fetchTransfers(pagination.current, searchTerm);
    }, [pagination.current, searchTerm]);

    useEffect(() => {
        fetchCompanies();
    }, [])

    const handleSearch = (value) => {
        setSearchTerm(value);
        setPagination((prev) => ({...prev, current: 1}));
    };

    const handleTableChange = (pagination) => {
        setPagination((prev) => ({
            ...prev,
            current: pagination.current,
            pageSize: pagination.pageSize,
        }));
    };

    const onCompanySelect = (value) => {
        console.log(value);

    };

    const openDrawer = (stockTransfer) => {
        console.log(stockTransfer);
        setSelectedStockTransfer(stockTransfer);
        setDrawerVisible(true);
    };

    const closeDrawer = () => {
        setDrawerVisible(false);
    };


    const handleConfirmTransfer = async (stockTransferId) => {
    setLoading(true);
    try {
        const response = await confirmStockTransferApi(stockTransferId);
        if (!response.success) {
            setErrorMessage(response.message || 'Unable to confirm stock transfer');
        }
        setSuccessMessage('Stock transfer confirmed successfully');
        refreshData();
    } catch (error) {
        setErrorMessage(error.message);
    } finally {
        setLoading(false);
    }
};

    const handleDispatchTransfer = async (stockTransferId) => {
    setLoading(true);
    try {
        const response = await dispatchStockTransferApi(stockTransferId);
        if (response.success) {
            setSuccessMessage('Stock transfer dispatched successfully');
            refreshData(); // This will refresh the list to reflect the new state
        } else {
            setErrorMessage(response.message || 'Failed to dispatch stock transfer');
        }
    } catch (error) {
        setErrorMessage(error.message);
    } finally {
        setLoading(false);
    }
};



    const handleReceiveTransfer = async (stockTransferId) => {
    setLoading(true);
    try {
        const response = await receiveStockTransferApi(stockTransferId);
        if (response.success) {
            setSuccessMessage('Stock transfer received successfully');
            refreshData(); // This will refresh the list to reflect the new state
        } else {
            setErrorMessage(response.message || 'Failed to receive stock transfer');
        }
    } catch (error) {
        setErrorMessage(error.message);
    } finally {
        setLoading(false);
    }
};


    const handleCloseTransfer = async (stockTransferId) => {

        const result = await closeStockTransferApi(stockTransferId);
        console.log("Result:", result);
        if (result.success) {
            setSuccessMessage(result.message);
            refreshData();
            // Refresh data or update state as needed
        } else {
            setErrorMessage(result.message);
        }
    }

    const handleExecuteTransfer = async (stockTransferId) => {
    setLoading(true);
    try {
        const response = await executeStockTransferApi(stockTransferId);
        if (response.success) {
            setSuccessMessage('Stock transfer executed successfully');
            refreshData(); // This function should re-fetch the necessary data to update the UI
        } else {
            setErrorMessage(response.message || 'Failed to execute stock transfer');
        }
    } catch (error) {
        setErrorMessage(error.message);
    } finally {
        setLoading(false);
    }
};

    const handleCancelTransfer = async (stockTransferId) => {
    setLoading(true);
    try {
        const response = await cancelStockTransferApi(stockTransferId);
        if (response.success) {
            setSuccessMessage('Stock transfer cancelled successfully');
            refreshData(); // This function should re-fetch the necessary data to update the UI
        } else {
            setErrorMessage(response.message || 'Failed to cancel stock transfer');
        }
    } catch (error) {
        setErrorMessage(error.message);
    } finally {
        setLoading(false);
    }
};
    const handleEditSourceBranch = (stockTransferId, companyId) => {
    setCurrentTransferId(stockTransferId);
    setSelectCompanyId(companyId);
    setModalVisible(true);
};


    return (
        <div style={{padding: '24px'}}>
            {contextHolder}
            <h2>Stock Transfer Requests</h2>
            {showForm ? (
                <StockTransferForm
                    companies={companies}
                    setSuccessMessage={setSuccessMessage}
                    setErrorMessage={setErrorMessage}
                    setInfoMessage={setInfoMessage}
                    refreshData={refreshData}
                    onCancel={() => setShowForm(false)}
                />
            ) : (
                <>
                    <Row justify="space-between" align="middle" style={{marginBottom: 16}}>
                        <Col span={14}>
                            <Search
                                placeholder="Search by item, branch..."
                                onSearch={handleSearch}
                                style={{width: 'calc(60% - 8px)', marginRight: 8}}
                                enterButton
                            />
                            <AutoComplete
                                style={{width: '40%'}}
                                options={companies}
                                onSelect={onCompanySelect}
                                placeholder="Select a company"
                                notFoundContent="No companies found"
                            />
                        </Col>
                        <Col>
                            <Button type="primary" onClick={() => setShowForm(true)} style={{marginBottom: '16px'}}>
                                Add New Stock Transfer
                            </Button>
                        </Col>
                    </Row>


                    <StockTransfersTable
                        data={transfers}
                        loading={loading}
                        pagination={pagination}
                        onTableChange={handleTableChange}
                        openDrawer={openDrawer}
                    />
                    {selectedStockTransfer && (
                        <StockTransferDetailsDrawer
                            visible={drawerVisible}
                            refreshData={refreshData}
                            stockTransfer={selectedStockTransfer}
                            onClose={closeDrawer}
                            onEditStockTransfer={handleEditSourceBranch}
                            setErrorMessage={setErrorMessage}
                            setSuccessMessage={setSuccessMessage}
                            handleConfirmTransfer={handleConfirmTransfer}
                            handleDispatchTransfer={handleDispatchTransfer}
                            handleReceiveTransfer={handleReceiveTransfer}
                            handleCloseTransfer={handleCloseTransfer}
                            handleExecuteTransfer={handleExecuteTransfer}
                            handleCencelTransfer={handleCancelTransfer}
                        />
                    )}


                    <SourceBranchUpdateModal
            visible={modalVisible}
            onClose={() => setModalVisible(false)}
            companies={companies}
            stockTransferId={currentTransferId}
            companyId={selectCompanyId}
            refreshData={fetchTransfers} // Function to refresh the list of stock transfers
                        setErrorMessage={setErrorMessage}
            setSuccessMessage={setSuccessMessage}

        />
                </>
            )}
        </div>
    );
};

export default StockTransfersList;

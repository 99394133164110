import React, {useEffect, useState} from 'react';
import {BrowserRouter as Router, Routes, Route, Navigate} from 'react-router-dom';
import {useSelector} from 'react-redux';
import Login from './features/auth/Login';
import Dashboard from './pages/Dashboard';
import LayoutWrapper from "./layouts/LayoutWrapper";
import StatesList from "./features/masters/StatesList";
import CountriesList from "./features/masters/CountriesList";
import SalesOrderList from "./features/sales/SalesOrderList";
import ProductList from "./features/products/ProductList";
import TransactionForm from "./features/products/TransactionForm";
import SalesPage from "./pages/SalesPage";
import CustomerRegistration from "./features/customers/CustomerRegistration";
import SalesAnalyticsDashboard from "./pages/SalesAnalyticsDashboard";
import Procurement from "./pages/ProcurementPage";
import InventoryPage from "./pages/InventoryPage";
import ProductCatalog from "./features/products/ProductCatalog";
import RefreshLocalStorage from "./pages/refreshLocalStorage";
import CompanyList from "./features/partners/CompanyList";
import BranchList from "./features/partners/BranchList";
import StockTransfersList from "./features/stockTransfer/StockTransfersList";
import InventoryInboundList from "./features/inventory/InventoryInboundList";
import CycleItemsList from "./features/partber_payments/CycleItemsList";
import InvoicesList from "./features/invoices/InvoicesList";
import CustomerPaymentsList from "./features/customer_payments/CustomerPaymentsList";
import SerialNumbersList from "./features/inventory/SerialNumbersList";
import CashTransactionsList from "./features/cash_transfers/CashTransactionsList";
import PettyCashTransactionsList from "./features/cash_transfers/PettyCashTransactionsList";
import BranchCashDashboard from "./pages/BranchCashDashboard";
import CompanyCashDashboard from "./pages/CompanyCashDashboard";
import InventoryStockList from "./features/inventory/InventoryStockList";
import ExpenseList from "./features/expenses/ExpenseList";
import ExpensesDashboard from "./features/expenses/ExpensesDashboard";


const App = () => {
    const token = useSelector((state) => state.auth.token);
    const [isAuthenticated, setIsAuthenticated] = useState(!!token);

    // Update `isAuthenticated` state when `token` changes
    useEffect(() => {
        setIsAuthenticated(!!token);
    }, [token]);

    // ProtectedRoute component to avoid navigation loops
    const ProtectedRoute = ({element}) => {
        if (!isAuthenticated) {
            return <Navigate to="/" replace/>;
        }
        return element;
    };


    return (
        <Router>
            <Routes>
                <Route path="/" element={isAuthenticated ? <Navigate to="/dashboard" /> : <Login/>}/>

                {/* Protected Routes - Wrapped in LayoutWrapper */}
                <Route element={<ProtectedRoute element={<LayoutWrapper/>}/>}>
                    {/*<Route path="/dashboard" element={<SalesAnalyticsDashboard/>}/>*/}
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/branch-cash-dashboard" element={<BranchCashDashboard />} />
                    <Route path="/company-cash-dashboard" element={<CompanyCashDashboard />} />
                    <Route path="/sales-dashboard" element={<SalesAnalyticsDashboard/>}/>
                    <Route path="/sales" element={<SalesPage />} />
                    <Route path="/sales-orders" element={<SalesOrderList />} />
                    <Route path="/inventory" element={<InventoryInboundList/>} />
                    <Route path="/stock-list" element={<InventoryStockList/>} />
                    <Route path="/product-catalogue" element={<ProductCatalog/>} />
                    <Route path="/states_list" element={<StatesList />}/>
                    <Route path="/countries_list" element={<CountriesList/>}/>
                    <Route path="/register-customer" element={<CustomerRegistration />} />
                    <Route path="/procurement" element={<Procurement />} />
                    <Route path="/partners" element={<CompanyList />} />
                    <Route path="/partner-branches" element={<BranchList />} />
                    <Route path="/stock-transfers" element={<StockTransfersList/>} />
                    <Route path="/stock-value" element={<InventoryInboundList />}/>
                    <Route path="/payment-cycle-items" element={<CycleItemsList />} />
                    <Route path="/invoices" element={<InvoicesList />} />
                    <Route path="/customer-payments" element={<CustomerPaymentsList />} />
                    {/* New Serial Number Routes */}
                    <Route path="/serial-numbers" element={<SerialNumbersList />} />
                    <Route path="/cash-transfers" element={<CashTransactionsList />}/>
                    <Route path="/petty-cash" element={<PettyCashTransactionsList />}/>
                    <Route path="/expense-list" element={<ExpenseList />}/>
                    <Route path="/expense-dashboard" element={<ExpensesDashboard />}/>

                    <Route path="/products" element={<ProductList />} />
                    <Route path="/refresh_local_storage" element={<RefreshLocalStorage />} />
                    <Route path="/transactions" element={<TransactionForm />} />
                    <Route path="*" element={<Navigate to="/dashboard"/>}/>
                </Route>
            </Routes>
        </Router>
    );

};

export default App;

  import axios from 'axios';
  import config from '../../config';
  import {getAuthToken} from "../../utils/authUtils";

  export const loginAPI = async (credentials) => {
    try {
      const response = await axios.post(`${config.inxOmsApiUrl}/users/api/login/`, credentials, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      console.log("login response", response);
      return response;
    } catch (error) {
      // Check for common error types and throw descriptive messages
      if (!error.response) {
        // No response from server
        if (!navigator.onLine) {
          throw new Error("No internet connection. Please check your network.");
        }
        throw new Error("Unable to connect to the server. Please try again later.");
      } else if (error.response.status === 401) {
        // Invalid credentials
        throw new Error("Incorrect username or password. Please try again.");
      } else {
        // Other server errors
        throw new Error("Login failed. Please try again.");
      }
    }
  };

  export const checkSessionAPI = async () => {
    const token = getAuthToken();
    return axios.get(`${config.inxOmsApiUrl}/users/api/session-check/`, {
      headers: { Authorization: `Token ${token}` },
    });
  };

  export const logoutAPI = async () => {
    const token = getAuthToken();
    console.log("Log Out Initiated!");
    return axios.post(`${config.inxOmsApiUrl}/users/api/logout/`, {}, {
      headers: { Authorization: `Token ${token}` },
    });
  };

  export const someDataAPI = async (token) => {
    return axios.get(`${config.inxOmsApiUrl}/users/api/some-data/`, {
      headers: { Authorization: `Token ${token}` },
    });
  };

import React, {useEffect, useState} from 'react';
import {AutoComplete, Button, Col, DatePicker, Descriptions, Form, Input, Modal, Row, Select, Typography,} from 'antd';
import moment from 'moment';

import {updateCustomerPaymentApi,} from './customerPaymentsApi';
import {searchCustomerApi} from '../customers/customerApi';
import {formatRupee} from '../../utils/utils';
import {fetchCashAccountsApi} from '../common/commonApis';

const {Option} = Select;
const {Title} = Typography;

const PaymentFormModal = ({
                              visible,
                              onCancel,
                              onSuccess,
                              fetchPayments,
                              existingPayment = null,

                              // Additional context
                              sourceOfInitiation, // 'customer' | 'sale_order' | 'invoice'
                              orderId,
                              customerId,
                              invoiceId,
                              companyId,
                              branchId,

                              setErrorMessage,
                              setInfoMessage,
                              setSuccessMessage,

                              orderDetails,
                              refreshOrders,
                          }) => {
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    const [bankAccounts, setBankAccounts] = useState([]);
    const [customerSearchLoading, setCustomerSearchLoading] = useState(false);
    const [customerOptions, setCustomerOptions] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [file, setFile] = useState(null);

    // For read-only display in "sale_order" scenario
    const displayAmount = orderDetails ? orderDetails.invoice_amount || orderDetails.order_value : 0;
    const displayCompanyName = orderDetails ? orderDetails.company_name : '';
    const displayBranchName = orderDetails ? orderDetails.branch_name : '';
    const displayCustomerName = orderDetails ? orderDetails.customer_name : '';
    const displayOrderNumber = orderDetails ? orderDetails.order_number : '';
    const displayBalanceAmount = orderDetails ? orderDetails.balance : 0;
    const displayReceivedAmount = orderDetails ? orderDetails.received_amount : 0;
    const displayDraftReceivedAmount = orderDetails ? orderDetails.draft_received_amount : 0;

    // If it's a sale_order flow, we show a read-only block for company/branch/customer
    const showInfoInsteadOfInput = sourceOfInitiation === 'sale_order';

    // -----------------------------
    // Retrieve user & role-based permissions
    // -----------------------------
    const user = JSON.parse(localStorage.getItem('user'));
    const userRole = user?.user_role || '';

    // 1) Full-edit role (can edit everything)
    const rolesAllowedForFullEdit = ['super_admin_core']; // or whatever name you have
    const userCanFullEdit = rolesAllowedForFullEdit.includes(userRole);

    // 2) Partial-edit roles
    const rolesAllowedForPartialEdit = ['super_admin', 'admin'];
    const userCanPartialEdit = rolesAllowedForPartialEdit.includes(userRole);

    // Are we editing an existing payment?
    const isEditMode = !!existingPayment;

    // Decide if user can edit at all
    let canEditPayment = false;
    let canEditEverything = false;

    if (isEditMode) {
        if (userCanFullEdit) {
            canEditEverything = true;
            canEditPayment = true;
        } else if (userCanPartialEdit) {
            canEditPayment = true;
        }
        // else => read-only
    }

    // If we have existingPayment but user cannot edit => show read-only
    const showReadOnlyForRole = isEditMode && !canEditPayment;

    // -----------------------------
    // 1) Prefill form in EDIT mode
    // -----------------------------
    useEffect(() => {
        if (existingPayment) {
            const paymentDate = existingPayment.payment_date
                ? moment(existingPayment.payment_date, 'YYYY-MM-DD')
                : null;
            const promisedDate = existingPayment.promised_date
                ? moment(existingPayment.promised_date, 'YYYY-MM-DD')
                : null;

            form.setFieldsValue({
                payment_date: paymentDate,
                amount: existingPayment.amount,
                payment_method: existingPayment.payment_method,
                transaction_number: existingPayment.transaction_number,
                promised_date: promisedDate,
                note: existingPayment.note,
            });
        }
    }, [existingPayment, form]);

    // -----------------------------
    // 2) Fetch Bank Accounts
    // -----------------------------
    useEffect(() => {
        const fetchBankAccounts = async () => {
            let targetCompanyId = companyId;
            if (existingPayment?.company) {
                targetCompanyId = existingPayment.company;
            } else if (orderDetails?.company_id) {
                targetCompanyId = orderDetails.company_id;
            }
            if (!targetCompanyId) return;

            setLoading(true);
            try {
                const accounts = await fetchCashAccountsApi(targetCompanyId);

                const filteredAccounts = accounts.filter(account =>
                    account.account_sub_type_name.toLowerCase().includes('bank account')
                );
                setBankAccounts(filteredAccounts);
            } catch (error) {
                setErrorMessage('Failed to fetch bank accounts');
            } finally {
                setLoading(false);
            }
        };

        fetchBankAccounts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [companyId, existingPayment]);

    // -----------------------------
    // 3) handleSubmit => create or update
    // -----------------------------
    const handleSubmit = async (values) => {
        setLoading(true);

        // Format dates directly before submission to avoid issues
        const formattedValues = {
            ...values,
            payment_date: values.payment_date ? values.payment_date.format('YYYY-MM-DD') : undefined,
            promised_date: values.promised_date ? values.promised_date.format('YYYY-MM-DD') : undefined,
        };

        try {
            if (isEditMode) {
                // EDIT scenario
                if (canEditEverything) {
                    await handleFullEditUpdate(formattedValues);
                } else {
                    await handlePartialEditUpdate(formattedValues);
                }
            } else {
                // CREATE scenario
                // await handleCreatePayment(formattedValues);
            }
            onSuccess();
            fetchPayments();
        } catch (error) {
            console.log(error);
            setErrorMessage(`Error: ${error.message}`);
        } finally {
            setLoading(false);
        }
    };


    const handleFileChange = (e) => {
        const files = e.target.files;
        if (files && files.length > 0) {
            setFile(files[0]);
        }
    };

    // EDIT Payment - Partial
    const handlePartialEditUpdate = async (values) => {
        // Now also append the file from local state
        let attachment = null;
        if (file) {
            attachment = file;
        }

        // Only updating date, amount, method, transaction#, note (and account if needed)
        const payload = {
            payment_date: values.payment_date,
            payment_status: existingPayment.payment_status,
            payment_method: values.payment_method,
            amount: values.amount,
            transaction_number: values.transaction_number,
            note: values.note,
            promised_date: values.promised_date || null,
            account_id: values.account,
            attachment: attachment,
            // Not editing company_id, branch_id, or customer_id
        };

        // setInfoMessage(`Updating (partial) payment ID ${existingPayment.id}: ${JSON.stringify(payload)}`);
        await updateCustomerPaymentApi(existingPayment.id, payload);
        setSuccessMessage('Payment updated successfully!');
        form.resetFields();
    };

    // EDIT Payment - Full
    const handleFullEditUpdate = async (values) => {
        // Now also append the file from local state
        let attachment = null;
        if (file) {
            attachment = file;
        }

        // If super_admin_core can edit everything (co, branch, cust, etc.)
        // We assume we have form fields for them
        const payload = {
            payment_date: values.payment_date,
            payment_status: existingPayment.payment_status,
            payment_method: values.payment_method,
            amount: values.amount,
            transaction_number: values.transaction_number,
            note: values.note,
            promised_date: values.promised_date || null,
            account_id: values.account,
            attachment: attachment,
            // FULL EDIT: we also allow changing company, branch, customer if provided
            company_id: values.company,
            branch_id: values.branch,
            customer_id: values.customer, // if your form includes these
        };

        setInfoMessage(`Updating (full) payment ID ${existingPayment.id}: ${JSON.stringify(payload)}`);
        await updateCustomerPaymentApi(existingPayment.id, payload);
        setSuccessMessage('Payment updated successfully!');
        form.resetFields();
    };

    // -----------------------------
    // 4) Customer Autocomplete (CREATE mode)
    // -----------------------------
    const handleCustomerSearch = async (value) => {
        if (value.length < 3) {
            setCustomerOptions([]);
            return;
        }
        setCustomerSearchLoading(true);
        try {
            const results = await searchCustomerApi(value);
            const opts = results.map((c) => ({
                label: `${c.name} (${c.mobile_number})`,
                value: `${c.name} (${c.mobile_number})`,
                custObj: c,
            }));
            setCustomerOptions(opts);
        } catch (err) {
            setErrorMessage('Failed to search for customers');
        } finally {
            setCustomerSearchLoading(false);
        }
    };

    const handleCustomerSelect = (value, option) => {
        setSelectedCustomer(option.custObj);
    };

    // -----------------------------
    // 5) Rendering
    // -----------------------------
    const modalTitle = isEditMode ? 'Edit Payment' : 'Record New Payment';

    // If we are editing but user cannot edit => read-only
    if (showReadOnlyForRole) {
        return (
            <Modal
                title={modalTitle}
                open={visible}
                onCancel={onCancel}
                footer={<Button onClick={onCancel}>Close</Button>}
                destroyOnClose
                width={800}
                style={{top: 20}}
            >
                <Title level={5}>Read-Only Payment Details</Title>
                <Descriptions bordered size="small" column={1} style={{marginBottom: 16}}>
                    <Descriptions.Item label="Payment ID">{existingPayment.id}</Descriptions.Item>
                    <Descriptions.Item label="Payment Date">
                        {existingPayment.payment_date || 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label="Amount">
                        {formatRupee(existingPayment.amount)}
                    </Descriptions.Item>
                    <Descriptions.Item label="Method">
                        {existingPayment.payment_method_display || existingPayment.payment_method}
                    </Descriptions.Item>
                    <Descriptions.Item label="Transaction #">
                        {existingPayment.transaction_number || 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label="Company">
                        {existingPayment.company_name || 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label="Branch">
                        {existingPayment.branch_name || 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label="Customer">
                        {existingPayment.customer_name || 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label="Note">
                        {existingPayment.note || 'N/A'}
                    </Descriptions.Item>
                </Descriptions>
            </Modal>
        );
    }

    // Otherwise, show the create/edit form
    return (
        <Modal
            title={modalTitle}
            open={visible}
            onCancel={onCancel}
            footer={null}
            destroyOnClose
            width={800}
            style={{top: 20}}
        >
            <Form form={form} layout="vertical" onFinish={handleSubmit}>
                <Row gutter={16}>
                    <Col span={12}>
                        {/* Payment Date */}
                        <Form.Item
                            name="payment_date"
                            label="Payment Date"
                            rules={[
                                {required: true, message: 'Please select the payment date'},
                                () => ({
                                    validator(_, value) {
                                        if (value) {
                                            // Create a new date object for comparison
                                            const currentDate = new Date();
                                            currentDate.setHours(0, 0, 0, 0); // Reset time to start of the day

                                            const selectedDate = value.toDate(); // Convert Moment object to JavaScript Date if using Moment with DatePicker
                                            selectedDate.setHours(0, 0, 0, 0); // Reset time to start of the day

                                            if (selectedDate > currentDate) {
                                                return Promise.reject(new Error('Payment date cannot be in the future'));
                                            }
                                        }
                                        return Promise.resolve();
                                    },
                                }),
                            ]}
                        >
                            <DatePicker format="YYYY-MM-DD"/>
                        </Form.Item>

                        {/* Amount */}
                        <Form.Item
                            name="amount"
                            label="Amount"
                            rules={[
                                {required: true, message: 'Please input the payment amount'},
                            ]}
                        >
                            <Input prefix="Rs." suffix="INR" type="number"/>
                        </Form.Item>


                        {/* Payment Method */}
                        <Form.Item
                            name="payment_method"
                            label="Mode of Payment"
                            rules={[{required: true, message: 'Please select a payment method'}]}
                        >
                            <Select placeholder="Select a payment method">
                                <Option value="cash">Cash</Option>
                                <Option value="credit_card">Credit Card</Option>
                                <Option value="debit_card">Debit Card</Option>
                                <Option value="upi">UPI</Option>
                                <Option value="bank_transfer">Bank Transfer</Option>
                                <Option value="credit">Credit</Option>
                                {/*<Option value="other">Other</Option>*/}
                            </Select>
                        </Form.Item>

                        {/* Conditionally show Account if not cash/credit/other */}
                        <Form.Item noStyle shouldUpdate>
                            {({getFieldValue}) => {
                                const pm = getFieldValue('payment_method');
                                if (pm && !['cash', 'credit', 'other'].includes(pm)) {
                                    return (
                                        <Form.Item
                                            name="account"
                                            label="Account"
                                            rules={[{required: true, message: 'Please select an account'}]}
                                        >
                                            <Select placeholder="Select an account">
                                                {bankAccounts.map((acct) => (
                                                    <Option key={acct.id} value={acct.id}>
                                                        {acct.account_name}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    );
                                }
                                return null;
                            }}
                        </Form.Item>

                        {/* Transaction Number */}
                        <Form.Item
                            name="transaction_number"
                            label="Transaction Number"
                            rules={[{required: true, message: 'Please enter the transaction number'}]}
                        >
                            <Input placeholder="e.g. TXN12345"/>
                        </Form.Item>

                        {/* Promised Date (credit only) */}
                        <Form.Item noStyle shouldUpdate>
                            {({getFieldValue}) => {
                                const pm = getFieldValue('payment_method');
                                if (pm === 'credit') {
                                    return (
                                        <Form.Item
    name="promised_date"
    label="Promised Date"
    dependencies={['payment_date']} // Ensure that the validator re-runs when payment_date changes
    rules={[
        {
            required: true,
            message: 'Please select the promised date for credit payments',
        },
        ({ getFieldValue }) => ({
            validator(_, value) {
                if (!value) {
                    return Promise.reject(new Error('Please select a promised date'));
                }
                const paymentDate = getFieldValue('payment_date');
                if (!paymentDate) {
                    return Promise.resolve(); // Allow submission if payment date is not set (adjust based on your requirements)
                }
                // Convert both dates to JavaScript Date objects for comparison
                const promisedDate = value.toDate();
                promisedDate.setHours(0, 0, 0, 0); // Reset time to start of the day for accurate comparison

                const paymentDateObject = paymentDate.toDate();
                paymentDateObject.setHours(0, 0, 0, 0); // Reset time to start of the day for accurate comparison

                if (promisedDate <= paymentDateObject) {
                    return Promise.reject(new Error('Promised date must be after the payment date'));
                }
                return Promise.resolve();
            },
        }),
    ]}
>
    <DatePicker format="YYYY-MM-DD"/>
</Form.Item>
                                    );
                                }
                                return null;
                            }}
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        {/*
              If creating => show full fields (unless it's a sale_order, then read-only block)
              If editing => either partial or full. But if we want a user with "full" perms to also edit co/branch/cust,
              we'd add them as fields in the form.
              Below is partial example for simplicity.
            */}
                        {!isEditMode ? (
                            // CREATE Mode
                            !showInfoInsteadOfInput ? (
                                <>
                                    {/* Company, Branch, Customer fields */}
                                    <Form.Item
                                        name="company"
                                        label="Company"
                                        rules={[{required: true, message: 'Please select a company'}]}
                                    >
                                        <Select placeholder="Select a company">
                                            <Option value="1">Company One</Option>
                                            <Option value="2">Company Two</Option>
                                        </Select>
                                    </Form.Item>

                                    <Form.Item
                                        name="branch"
                                        label="Branch"
                                        rules={[{required: true, message: 'Please select a branch'}]}
                                    >
                                        <Select placeholder="Select a branch">
                                            <Option value="10">Branch A</Option>
                                            <Option value="20">Branch B</Option>
                                        </Select>
                                    </Form.Item>

                                    <Form.Item
                                        name="customer"
                                        label="Customer"
                                        rules={[{required: true, message: 'Please enter the customer name'}]}
                                    >
                                        <AutoComplete
                                            placeholder="Type customer name or mobile (3+ chars)"
                                            onSearch={handleCustomerSearch}
                                            onSelect={handleCustomerSelect}
                                            options={customerOptions}
                                            loading={customerSearchLoading}
                                            allowClear
                                        />
                                    </Form.Item>
                                </>
                            ) : (
                                // Sale Order creation => read-only block
                                <Descriptions bordered size="small" column={1} style={{marginBottom: 16}}>
                                    <Descriptions.Item label="Company">{displayCompanyName}</Descriptions.Item>
                                    <Descriptions.Item label="Branch">{displayBranchName}</Descriptions.Item>
                                    <Descriptions.Item label="Customer">{displayCustomerName}</Descriptions.Item>
                                    <Descriptions.Item label="Order #">{displayOrderNumber}</Descriptions.Item>
                                    <Descriptions.Item label="Invoice/Order Amount">
                                        {formatRupee(displayAmount)}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Amount Received">
                                        {formatRupee(displayReceivedAmount)}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Balance">
                                        {formatRupee(displayBalanceAmount)}
                                        {displayDraftReceivedAmount > 0 && (
                                            <div style={{color: 'red'}}>
                                                Draft: {formatRupee(displayDraftReceivedAmount)}
                                            </div>
                                        )}
                                    </Descriptions.Item>
                                </Descriptions>
                            )
                        ) : canEditEverything ? (
                            // EDIT Mode with FULL permissions
                            <>
                                {/* If you want them to edit co/branch/cust, add them here: */}
                                <Form.Item name="company" label="Company">
                                    <Select placeholder="Select a company">
                                        <Option value="1">Company One</Option>
                                        <Option value="2">Company Two</Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item name="branch" label="Branch">
                                    <Select placeholder="Select a branch">
                                        <Option value="10">Branch A</Option>
                                        <Option value="20">Branch B</Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item name="customer" label="Customer">
                                    <AutoComplete
                                        placeholder="Type name or mobile"
                                        onSearch={handleCustomerSearch}
                                        onSelect={handleCustomerSelect}
                                        options={customerOptions}
                                        loading={customerSearchLoading}
                                        allowClear
                                    />
                                </Form.Item>
                            </>
                        ) : (
                            // EDIT Mode with PARTIAL permissions => read-only block
                            <Descriptions bordered size="small" column={1} style={{marginBottom: 16}}>
                                <Descriptions.Item label="Company">
                                    {existingPayment.company_name || 'N/A'}
                                </Descriptions.Item>
                                <Descriptions.Item label="Branch">
                                    {existingPayment.branch_name || 'N/A'}
                                </Descriptions.Item>
                                <Descriptions.Item label="Customer">
                                    {existingPayment.customer_name || 'N/A'}
                                </Descriptions.Item>
                            </Descriptions>
                        )}

                        {/* Attachment (Optional) */}
                        <Form.Item
                            name="attachment"
                            label="Attachment"
                            valuePropName="file"
                        >
                            <Input type="file" onChange={handleFileChange}/>
                        </Form.Item>

                        {/* Note */}
                        <Form.Item name="note" label="Note">
                            <Input.TextArea rows={2} placeholder="Any additional notes"/>
                        </Form.Item>
                    </Col>
                </Row>

                {/* Submit Button */}
                <Form.Item>
                    <Button type="primary" htmlType="submit" loading={loading} disabled={loading}>
                        {isEditMode ? 'Update Payment' : 'Submit Payment'}
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default PaymentFormModal;

import React, {useEffect, useState} from 'react';
import {Button, message, Typography} from 'antd';
import {ArrowLeftOutlined, PlusOutlined} from '@ant-design/icons';
import CreateSaleOrder from '../features/sales/CreateSaleOrder';
import OrderSummary from '../features/sales/OrderSummary';
import CustomerDetails from '../features/sales/CustomerDetails';
import SalesOrdersList from "../features/sales/SalesOrderList";
import SalespersonSelector from "../features/sales/SalespersonSelector";
import OrderReviewPage from "../features/sales/OrderReviewPage";
import CompanyBranchSelector from "../features/common/CompanyBranchSelector";
import {handleSubmitOrder} from "../features/sales/saleOrderActions";
import AddressForm from "../features/sales/AddressForm";
import {useDispatch, useSelector} from "react-redux";
import {fetchItemsApi} from "../features/products/productSlice";

const {Title} = Typography;

const SalesPage = () => {
    const dispatch = useDispatch();
    const [step, setStep] = useState('list'); // "list", "create", "review"
    const [editingCustomer, setEditingCustomer] = useState(false);
    const [editingSalesperson, setEditingSalesperson] = useState(false);
    const [editingCompanyBranch, setEditingCompanyBranch] = useState(false);
    const [editingOrderId, setEditingOrderId] = useState(null);

    const {allItems = [], loading, hasFetched} = useSelector((state) => state.products);


    const [messageApi, contextHolder] = message.useMessage(); // Initialize Ant Design message
    const [successMessage, setSuccessMessage] = useState(null);
    const [infoMessage, setInfoMessage] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    const [addressFormVisible, setAddressFormVisible] = useState(false);
    const [addressData, setAddressData] = useState({
        billingAddress: '',
        billingAddress2: '',
        billingCity: '',
        billingState: '',
        billingStateName: '',
        billingCountryCode: '',
        billingPostalCode: '',
        shippingAddress: '',
        shippingAddress2: '',
        shippingCity: '',
        shippingState: '',
        shippingStateName: '',
        shippingCountryCode: '',
        shippingPostalCode: ''
    });


    // Fetch company and branch information from local storage
    // Fetch user details from localStorage
    const user = JSON.parse(localStorage.getItem('user'));

    const companyId = user?.company_id || '';
    const companyName = user?.company || '';
    const companyCode = user?.company_code
    const branchId = user?.branch_id || '';
    const branchName = user?.branch || '';
    const branchCode = user?.branch_code || '';

    const [orderData, setOrderData] = useState({
        customer: null,
        selectedProducts: [],
        orderValue: 0,
        totalQuantity: 0,
        totalMRPValue: 0,
        isApproved: true,
        salesperson: null,
        company: {id: companyId, name: companyName, code: companyCode},
        branch: {id: branchId, name: branchName, code: branchCode},
    });
    const [productDrawerVisible, setProductDrawerVisible] = useState(false);

    useEffect(() => {
        if (!hasFetched) {
            dispatch(fetchItemsApi());
        }
    }, [dispatch, hasFetched]);

    // Calculate totals whenever products change
    useEffect(() => {
        const totalValue = orderData.selectedProducts.reduce((sum, item) => sum + item.item_price * item.quantity, 0);
        const totalMRP = orderData.selectedProducts.reduce((sum, item) => sum + item.mrp * item.quantity, 0);
        const totalQty = orderData.selectedProducts.reduce((sum, item) => sum + item.quantity, 0);
        const isBelowMOP = orderData.selectedProducts.some(item => item.item_price < item.mop);

        setOrderData(prev => ({
            ...prev, orderValue: totalValue, totalMRPValue: totalMRP, totalQuantity: totalQty, isApproved: !isBelowMOP,
        }));
    }, [orderData.selectedProducts]);

    // Navigation Handlers
    const handleBackToList = () => setStep('list');
    const handleReviewOrder = () => setStep('review');

    const handleEditOrderData = () => {
        // Directly set the step to 'create' without fetching data again
        setStep('create');
    };

    const handleAddNewOrder = () => {
        // Clear existing order data
        setOrderData({
            customer: null,
            selectedProducts: [],
            orderValue: 0,
            totalQuantity: 0,
            totalMRPValue: 0,
            isApproved: true,
            salesperson: null,
            company: {id: companyId, name: companyName, code: companyCode},
            branch: {id: branchId, name: branchName, code: branchCode},
        });
        setEditingOrderId(null); // Reset editing order ID
        setStep('create');
    };

    useEffect(() => {
        console.log("Selected Products:", orderData.selectedProducts);
    }, [orderData.selectedProducts]);


    const handleAddProduct = (product) => {
        const alreadyAdded = orderData.selectedProducts.some(item => item.item === product.id);

        if (alreadyAdded) {
            setInfoMessage(`${product.item_code} is already added`);
            return;
        }

        // Function to create product structure for order data
        const createProductEntry = (prod, isBundle = false) => ({
            ...prod,
            quantity: 1,
            item: prod.id,
            item_price: prod.ticket_price, // Default to ticket price
            mrp: prod.mrp || 0,
            mop: prod.mop || 0,
            tax_group: prod.tax_group || null,
            tax_percentage: prod.tax_group?.tax_percentage || 0,
            hsn_code: prod.hsn_code || null,
            ticket_price: prod.ticket_price || 0,
            key: isBundle ? `${prod.id}-${Date.now()}` : prod.id // Use a unique key for bundled items
        });

        let newProducts = [createProductEntry(product)];

        // Check for bundled items and add them if they aren't already added
    if (product.bundles && product.bundles.length > 0) {
        product.bundles.forEach(bundle => {
            if (!orderData.selectedProducts.some(item => item.item === bundle.secondary_item)) {
                const bundledProduct = allItems.find(p => p.id === bundle.secondary_item);
                if (bundledProduct) {
                    newProducts.push(createProductEntry(bundledProduct, true));
                    setInfoMessage(`${bundledProduct.item_code} bundled with ${product.item_code} added`);
                }
            } else {
                const bundledProductInfo = allItems.find(p => p.id === bundle.secondary_item);
                setInfoMessage(`Bundled product ${bundledProductInfo.item_code} is already added`);
            }
        });
    }

        // Flatten the products into the existing array properly
        setOrderData(prev => ({
            ...prev, selectedProducts: [...prev.selectedProducts, ...newProducts]
        }));
    };


    const handleRemoveProduct = (id) => {
        setOrderData(prev => ({
            ...prev, selectedProducts: prev.selectedProducts.filter(item => item.id !== id)
        }));
    };


    // Handle displaying success and error messages using useEffect
    useEffect(() => {
        if (successMessage) {
            console.log("Displaying success message:", successMessage);
            messageApi.open({
                type: 'success',
                content: successMessage,
                duration: 2.5,
            });
            setSuccessMessage(null);
        }
        if (errorMessage) {
            console.log("Displaying error message:", errorMessage);
            messageApi.open({
                type: 'error',
                content: errorMessage,
                duration: 2.5,
            });
            setErrorMessage(null);
        }
        if (infoMessage) {
            console.log("Displaying info message:", infoMessage);
            messageApi.open({
                type: 'info',
                content: infoMessage,
                duration: 2.5,
            });
            setInfoMessage(null);
        }
    }, [successMessage, errorMessage, infoMessage, messageApi]);


    const handleQuantityChange = (id, quantity) => {
        setOrderData(prev => ({
            ...prev, selectedProducts: prev.selectedProducts.map(item => item.id === id ? {...item, quantity} : item)
        }));
    };

    const handlePriceChange = (id, price) => {
        setOrderData(prev => ({
            ...prev,
            selectedProducts: prev.selectedProducts.map(item => item.id === id ? {...item, item_price: price} : item)
        }));
    };

    const handleCustomerChange = (updatedCustomer) => {
        setOrderData(prev => ({
            ...prev, customer: updatedCustomer
        }));
        setEditingCustomer(false); // Close the modal after updating
    };

    // Handlers
    const handleEditSalesperson = (selectedSalesperson) => {
        setOrderData(prev => ({
            ...prev,
            salesperson: {
                id: selectedSalesperson.id,
                full_name: selectedSalesperson.full_name,
            }
        }));
        setEditingSalesperson(false);
    };

    const handleEditCompanyBranch = (updatedCompanyBranch) => {
        setOrderData(prev => ({
            ...prev, company: updatedCompanyBranch.company, branch: updatedCompanyBranch.branch
        }));
        setEditingCompanyBranch(false);
    };

    // Handler for editing an existing order
    const handleEditOrder = async (passedOrderData = null) => {
        try {
            setErrorMessage(null); // Clear previous error messages

            // Use the passed order data if available, otherwise use the existing state
            const data = passedOrderData || orderData;

            // If no data is available, show an error
            if (!data) {
                setErrorMessage('Order data not found');
                return;
            }

            // Update state with the fetched or passed order data
            setOrderData({
                customer: {
                    id: data.customer_id,
                    name: data.customer_name,
                    mobile: data.mobile_number,
                },
                selectedProducts: data.items?.map(item => ({
                    id: item.id,
                    item: item.item,
                    item_code: item.item_code,
                    item_name: item.item_name,
                    item_price: item.item_price,
                    ticket_price: item.ticket_price,
                    quantity: item.quantity,
                    mrp: item.mrp,
                    mop: item.mop,
                    tax_group: item.tax_group,
                    tax_percentage: item.tax_percentage,
                    hsn_code: item.hsn_code,
                })) || [],
                salesperson: {
                    id: data.salesperson_id,
                    full_name: data.salesperson_name
                },
                company: {
                    id: data.company_id,
                    name: data.company_name,
                    code: data.company_code,
                },
                branch: {
                    id: data.branch_id,
                    name: data.branch_name,
                    code: data.branch_code,
                },
                same_as_billing_address: data.same_as_billing_address,
                billingAddress: {
                    address: data.billing_address_line_1,
                    address2: data.billing_address_line_2,
                    city: data.billing_city,
                    state: data.billing_state,
                    state_name: data.billingStateName,
                    postalCode: data.billing_postal_code,
                    country: data.billingCountryCode,
                    country_name: data.billingCountryName,
                },
                shippingAddress: {
                    address: data.shipping_address_line_1,
                    address2: data.shipping_address_line_2,
                    city: data.shipping_city,
                    state: data.shipping_state,
                    state_name: data.shippingStateName,
                    postalCode: data.shipping_postal_code,
                    country: data.shippingCountryCode,
                    country_name: data.shippingCountryName,
                },
                mode_of_delivery: data.mode_of_delivery || data.mode_of_delivery || 'hand_delivery',
                totalQuantity: data.total_quantity || 0,
                orderValue: data.order_value || 0,
                totalMRPValue: data.total_mrp_value || 0,
                isApproved: data.is_approved || false,
            });

            setEditingOrderId(data.id);
            setStep('create');
        } catch (error) {
            setStep('list');
            setErrorMessage('Failed to load sale order for editing');
        }
    };

    // Function to open the form
    const handleEditAddresses = () => {
        setAddressFormVisible(true);
    };

    // Function to handle form submission for addresses
    const handleAddressFormSubmit = (values) => {
        console.log('Received address data:', values);

        // Integrate address data directly into the main order data state
        setOrderData(prevData => ({
            ...prevData,
            billingAddress: {
                address: values.billingAddress,
                address2: values.billingAddress2,
                city: values.billingCity,
                state: values.billingState,
                state_name: values.billingStateName,
                postalCode: values.billingPostalCode,
                country: values.billingCountryCode,
                country_name: values.billingCountryName,
            },
            same_as_billing_address: values.same_as_billing_address,
            shippingAddress: {
                address: values.shippingAddress,
                address2: values.shippingAddress2,
                city: values.shippingCity,
                state: values.shippingState,
                state_name: values.shippingStateName,
                postalCode: values.shippingPostalCode,
                country: values.shippingCountryCode,
                country_name: values.shippingCountryName,
            }
        }));

        // Reflect this update in the UI or log it
        console.log('Updated Order Data:', orderData);

        // Close the modal after updating
        setAddressFormVisible(false);
    };


    return (<div style={{padding: 0}}>
        {contextHolder}
        {step === 'create' && (<>
            {/* Back Button */}
            <div style={{
                display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '4px'
            }}>
                <Title level={4}>Create Sales Order</Title>
                <Button
                    icon={<ArrowLeftOutlined/>}
                    onClick={handleBackToList}
                    type="default"
                >
                    Back to Orders
                </Button>
            </div>

            {/* Order Summary */}
            <OrderSummary
                customer={orderData.customer}
                company={orderData.company}
                branch={orderData.branch}
                totalQuantity={orderData.totalQuantity}
                orderValue={orderData.orderValue}
                totalMRPValue={orderData.totalMRPValue}
                isApproved={orderData.isApproved}
                salesperson={orderData.salesperson}
                onEditCustomer={() => setEditingCustomer(true)}
                onEditSalesperson={() => setEditingSalesperson(true)}
                onEditCompanyBranch={() => setEditingCompanyBranch(true)}
            />


            {/* Create Sale Order */}
            <CreateSaleOrder
                selectedProducts={orderData.selectedProducts}
                onAddProduct={handleAddProduct}
                onRemoveProduct={handleRemoveProduct}
                onQuantityChange={handleQuantityChange}
                onPriceChange={handlePriceChange}
                onReviewOrder={handleReviewOrder}
                productDrawerVisible={productDrawerVisible}
                setProductDrawerVisible={setProductDrawerVisible}
                setInfoMessage={setInfoMessage}
            />
        </>)}

        {/* Customer Details Modal */}
        <CustomerDetails
            visible={editingCustomer}
            initialData={orderData.customer}
            onCustomerChange={handleCustomerChange}
            onCancel={() => setEditingCustomer(false)}
            setInfoMessage={setInfoMessage}
            setSuccessMessage={setSuccessMessage}
            setErrorMessage={setErrorMessage}
        />

        <SalespersonSelector
            visible={editingSalesperson}
            onClose={() => setEditingSalesperson(false)}
            currentSalesperson={orderData.salesperson}
            onSelectSalesperson={handleEditSalesperson}
        />

        <CompanyBranchSelector
            visible={editingCompanyBranch}
            currentCompany={orderData.company}
            currentBranch={orderData.branch}
            onSelectCompanyBranch={handleEditCompanyBranch}
            onClose={() => setEditingCompanyBranch(false)}
        />

        <AddressForm
            visible={addressFormVisible}
            onSubmit={handleAddressFormSubmit}
            onCancel={() => setAddressFormVisible(false)}
            initialData={addressData}
        />


        {step === 'review' && (<OrderReviewPage
            orderData={orderData}
            onSubmitOrder={() => handleSubmitOrder(orderData, editingOrderId, handleBackToList, setSuccessMessage, setErrorMessage)}
            handleEditOrderData={handleEditOrderData}
            onEditCustomer={() => setEditingCustomer(true)}
            onEditSalesperson={() => setEditingSalesperson(true)}
            onEditCompanyBranch={() => setEditingCompanyBranch(true)}
            handleEditAddresses={handleEditAddresses}
            handleAddressFormSubmit={handleAddressFormSubmit}
        />)}

        {step === 'list' && (<>
            {/* Sales Orders List */}
            <div style={{
                display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '4px'
            }}>
                <Title level={4}>Sales Orders List</Title>
                <Button type="primary" icon={<PlusOutlined/>} onClick={handleAddNewOrder}>
                    Add New Sale Order
                </Button>
            </div>
            <SalesOrdersList
                onEditOrder={handleEditOrder}
                setErrorMessage={setErrorMessage}
                setSuccessMessage={setSuccessMessage}
            />
        </>)}
    </div>);
};

export default SalesPage;

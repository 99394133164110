import React, {useState} from 'react';
import {Button, Empty, Input, InputNumber, Select, Switch, Table, Tooltip, Typography} from 'antd';
import {DeleteOutlined, PlusOutlined, QrcodeOutlined} from '@ant-design/icons';
import ProductDrawer from '../products/ProductDrawer';
import {formatRupee, truncateText} from '../../utils/utils';
const { Option } = Select;

const {Text} = Typography;

const CreatePurchaseReceipt = ({
                                   items,
                                   setItems,
                                   receiptData,
                                   setReceiptData,
                                   onReviewOrder,
                                   onSubmitOrder,
                                   calculateTotals,
                                   onError,
                               }) => {
    const [productDrawerVisible, setProductDrawerVisible] = useState(false);


    const updateItems = (newItems) => {
        const {
            updatedItems,
            totalAmount,
            totalTax,
            totalQuantity
        } = calculateTotals(newItems, receiptData.isInclusiveTax);

        setItems(updatedItems); // Update items with calculated totals
        setReceiptData((prev) => ({
            ...prev,
            totalAmount,
            taxAmount: totalTax,
            totalQuantity,
        }));
    };


    const handleAddProduct = (product) => {
        if (items.some((item) => item.item === product.id)) {
            onError('Product already added');
            return;
        }

        const newItems = [
            ...items,
            {
                ...product,
                item: product.id,
                quantity: 1,
                mrp: product.mrp,
                item_price: product.dealer_price,
                tax_group_name: product.tax_group_name || 0,
                payment_term: 'pay_on_sell', // Default payment term
                payment_days: 0, // Default to 0 days for 'pay_on_sell'
            },
        ];

        updateItems(newItems);
    };

    // Handler to toggle the payment term and set number of days accordingly
    const handlePaymentTermChange = (value) => {
        setReceiptData(prev => ({
            ...prev,
            payment_term: value,
            payment_days: value === 'no_of_days' ? 1 : 0 // Reset days to 1 by default if 'no_of_days' is selected
        }));
    };

    const handlePaymentDaysChange = (e) => {
        setReceiptData(prev => ({
            ...prev,
            payment_days: parseInt(e.target.value, 10)
        }));
    };

    const handleRemoveProduct = (id) => {
        const newItems = items.filter((item) => item.id !== id);
        updateItems(newItems);
    };

    const handleQuantityChange = (id, quantity) => {
        const newItems = items.map((item) =>
            item.id === id ? {...item, quantity} : item
        );
        updateItems(newItems);
    };

    const handlePriceChange = (id, price) => {
        const newItems = items.map((item) =>
            item.id === id ? {...item, item_price: price} : item
        );
        updateItems(newItems);
    };

    const handleMRPChange = (id, mrp) => {
        const newItems = items.map((item) =>
            item.id === id ? { ...item, mrp } : item
        );
        updateItems(newItems);
    };


    const columns = [
        {
            title: 'Item Details',
            key: 'item_details',
            render: (_, record) => (
                <div style={{display: 'flex', alignItems: 'center', gap: '12px', padding: '4px 0'}}>
                    <div style={{flex: 1}}>
                        <div style={{fontWeight: 'bold', fontSize: '14px', lineHeight: '1.2'}}>
                            <Tooltip title={record.item_code || 'N/A'}>
                                {truncateText(record.item_code || 'N/A', 35)} ({record.brand_name || 'N/A'})
                            </Tooltip>
                        </div>
                        <div style={{color: 'gray', fontSize: '12px', marginTop: '4px'}}>
                            <Tooltip title={record.item_name || 'N/A'}>
                                {truncateText(record.item_name || 'N/A', 35)}
                            </Tooltip>
                        </div>
                    </div>
                    {record.has_serial_no && (
                        <Tooltip title="This is a serialized item">
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    backgroundColor: '#f0f5ff',
                                    border: '1px solid #adc6ff',
                                    width: '24px',
                                    height: '24px',
                                    marginRight: '8px',
                                }}
                            >
                                <QrcodeOutlined style={{fontSize: '16px', color: '#597ef7'}}/>
                            </div>
                        </Tooltip>
                    )}
                </div>
            ),
        },
        {
            title: 'MRP',
            dataIndex: 'mrp',
            align: 'center',
            render: (_, record) => (
                <InputNumber
                    min={0}
                    value={record.mrp}
                    formatter={(value) => `₹${value}`}
                    parser={(value) => value.replace(/₹\s?|(,*)/g, '')}
                    onChange={(value) => handleMRPChange(record.id, value)}
                    style={{ width: '150px' }}
                />
            ),
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            align: 'center',
            render: (_, record) => (
                <InputNumber
                    min={1}
                    value={record.quantity}
                    onChange={(value) => handleQuantityChange(record.id, value)}
                />
            ),
        },
        {
    title: 'Item Price',
    dataIndex: 'item_price',
            align: 'center',
    render: (_, record) => (
        <InputNumber
            min={0}
            value={record.item_price}
            formatter={(value) => `₹${value}`}
            parser={(value) => value.replace(/₹\s?|(,*)/g, '')} // Handles formatted values properly
            onChange={(value) => handlePriceChange(record.id, value)} // Use the correct unique identifier (e.g., record.id)
            style={{ width: '150px' }}
        />
    ),
},
      {
  title: 'Tax Amount',
  align: 'right',
  render: (_, record) => {
    // Existing logic to format the tax amount
    const taxAmountDisplay = formatRupee(record.taxAmount);
    // Show tax percentage if present
    const taxPercentage = record.tax_group_name || 0; // or record.tax_percentage, depending on your data

    return (
      <div style={{ textAlign: 'right' }}>
        <div>{taxAmountDisplay}</div>
        {taxPercentage > 0 && (
          <div style={{ fontSize: '12px', color: 'gray' }}>
            ({taxPercentage}%)
          </div>
        )}
      </div>
    );
  },
},
        {
  title: 'Total',
  align: 'right',
  render: (_, record) => {
    // Main total (e.g., record.totalAmount)
    const totalDisplay = formatRupee(record.totalAmount);
    // Subtotal (e.g., record.subTotal)
    const subTotalDisplay = formatRupee(record.totalAmount - record.taxAmount);

    return (
      <div style={{ textAlign: 'right' }}>
        <div>{totalDisplay}</div>
        <div style={{ fontSize: '12px', color: 'gray' }}>
          {subTotalDisplay}
        </div>
      </div>
    );
  },
},

        {
            title: '',
            render: (_, record) => (
                <Button
                    type="link"
                    danger
                    icon={<DeleteOutlined/>}
                    onClick={() => handleRemoveProduct(record.id)}
                />
            ),
        },
    ];

    return (
        <div>
            {/* Add Products Button and Tax Inclusion Toggle */}
            <div style={{
                marginTop: '16px',
                display: 'flex',
                alignItems: 'center',
                gap: '16px' // Space between button and toggle
            }}>
                {/* Add Products Button */}
                <Button
                    type="primary"
                    icon={<PlusOutlined/>}
                    onClick={() => setProductDrawerVisible(true)}
                >
                    Add Products
                </Button>

                {/* Tax Inclusion Toggle */}
                <div style={{display: 'flex', alignItems: 'center', gap: '8px'}}>
                    <Tooltip title="Toggle whether prices include or exclude tax">
                        <Switch
                            checked={receiptData.isInclusiveTax}
                            onChange={(checked) => setReceiptData((prev) => ({...prev, isInclusiveTax: checked}))}
                        />

                    </Tooltip>
                    <span style={{fontSize: '14px', color: 'gray'}}>
            {receiptData.isInclusiveTax ? 'Prices include tax' : 'Prices exclude tax'}
        </span>
                </div>
                <div style={{display: 'flex', alignItems: 'center', gap: '8px'}}>
                    <Tooltip title="Select payment term">
                        <Select defaultValue={receiptData.payment_term} style={{width: 180}}
                                onChange={handlePaymentTermChange}>
                            <Option value="pay_on_sell">Pay on Sell</Option>
                            <Option value="no_of_days">Number of Days</Option>
                        </Select>
                    </Tooltip>
                    {receiptData.payment_term === 'no_of_days' && (
                        <Input
                            type="number"
                            min={1}
                            value={receiptData.payment_days}
                            onChange={handlePaymentDaysChange}
                            style={{width: '100px'}}
                            required
                        />
                    )}
                </div>
            </div>


            <Table
                columns={columns}
                dataSource={items}
                rowKey="id"
                pagination={false}
                size="small"
                locale={{
                    emptyText: (
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No products selected"/>
                    ),
                }}
            />
            <div style={{marginTop: '16px', display: 'flex', justifyContent: 'flex-end', gap: '12px'}}>
                <Button type="default" onClick={onReviewOrder}>
                    Review Order
                </Button>
            </div>
            <ProductDrawer
                visible={productDrawerVisible}
                onClose={() => setProductDrawerVisible(false)}
                onSelectProduct={handleAddProduct}
                selectedProducts={items}
                context="purchase"
            />
        </div>
    );
};

export default CreatePurchaseReceipt;

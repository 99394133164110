// cashTransactionsAPI.js
import axios from 'axios';
import config from '../../config';
import {getAuthToken} from "../../utils/authUtils";

const token = getAuthToken();


/**
 * Fetches the closing balance, draft amount, and available balance for a specific branch.
 * @param {number|string} selectedBranchId - The ID of the branch to query.
 * @returns {Object} - An object containing { cash_in_hand, draft_amount, available_balance }, among other details.
 */
export const fetchClosingBalanceBranch = async (selectedBranchId) => {
  try {
    // Construct the request using axios
    const response = await axios.get(
      `${config.inxOmsApiUrl}/financials/api/financials/cash_in_hand_draft_balance/`,
      {
        headers: { Authorization: `Token ${token}` },
        params: { branch_id: selectedBranchId },
      }
    );

    // The API is expected to return something like:
    // {
    //   "branch_id": 123,
    //   "branch_name": "Main Branch",
    //   "company_id": 1,
    //   "company_name": "Example Co",
    //   "cash_in_hand": 10000.0,
    //   "draft_amount": 2000.0,
    //   "available_balance": 8000.0
    // }
    return response.data;
  } catch (error) {
    console.error('Failed to fetch financial details:', error);
    throw new Error('Failed to fetch financial details');
  }
};

export const fetchCashTransactions = async ({ page = 1, pageSize = 10, status = '', type = '', ...otherFilters }) => {
    try {
        const response = await axios.get(`${config.inxOmsApiUrl}/financials/api/cash_transactions/`, {
            headers: { Authorization: `Token ${token}` },
            params: { page, page_size: pageSize, status, type, ...otherFilters }
        });
        return response.data;
    } catch (error) {
        console.error('Failed to load cash transactions:', error);
        throw new Error('Failed to load cash transactions');
    }
};



export const createCashTransaction = async (transactionData) => {
    try {
        const response = await axios.post(`${config.inxOmsApiUrl}/financials/api/cash_transactions/record/`, transactionData, {
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Token ${token}` }
        });
        return response.data;
    } catch (error) {
        console.error('Error creating cash transaction:', error);
        throw new Error('Failed to create cash transaction');
    }
};



export const confirmCashTransaction = async (transactionId) => {
    try {
        const response = await axios.post(`${config.inxOmsApiUrl}/financials/api/cash_transactions/${transactionId}/confirm/`, {}, {
            headers: { Authorization: `Token ${token}` }
        });
        return response.data;
    } catch (error) {
        console.error('Error confirming cash transaction:', error);
        throw new Error('Failed to confirm cash transaction');
    }
};

export const cancelCashTransaction = async (transactionId) => {
    try {
        const response = await axios.post(`${config.inxOmsApiUrl}/financials/api/cash_transactions/${transactionId}/cancel/`, {}, {
            headers: { Authorization: `Token ${token}` }
        });
        return response.data;
    } catch (error) {
        console.error('Error cancelling cash transaction:', error);
        throw new Error('Failed to cancel cash transaction');
    }
};


export const fetchPettyCashTransactions = async ({ page = 1, pageSize = 10, status = '', type = '', ...otherFilters }) => {
    const token = getAuthToken();
    try {
        const response = await axios.get(`${config.inxOmsApiUrl}/financials/api/petty_cash_transactions/`, {
            headers: { Authorization: `Token ${token}` },
            params: { page, page_size: pageSize, status, type, ...otherFilters }
        });
        return response.data;
    } catch (error) {
        console.error('Failed to load petty cash transactions:', error);
        throw new Error('Failed to load petty cash transactions');
    }
};

export const createPettyCashTransaction = async (transactionData) => {
    try {
        const response = await axios.post(`${config.inxOmsApiUrl}/financials/api/petty_cash_transactions/record/`, transactionData, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Token ${token}` }
        });
        return response.data;
    } catch (error) {
        console.error('Error creating petty cash transaction:', error);
        throw new Error('Failed to create petty cash transaction');
    }
};


export const issuePettyCashTransaction = async (transactionId, { bankAccount, amountIssued, transactionDate, transactionNumber }) => {
    try {
        const response = await axios.post(`${config.inxOmsApiUrl}/financials/api/petty_cash_transactions/${transactionId}/record-issued/`, {
            bankAccount,
            amountIssued,
            transactionDate,
            transactionNumber
        }, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Token ${token}`
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error issuing petty cash transaction:', error);
        throw new Error('Failed to issue petty cash transaction');
    }
};

export const closePettyCashTransaction = async (transactionId) => {
    try {
        const response = await axios.post(`${config.inxOmsApiUrl}/financials/api/petty_cash_transactions/${transactionId}/close/`, {}, {
            headers: { Authorization: `Token ${token}` }
        });
        return response.data;
    } catch (error) {
        console.error('Error closing cash transaction:', error);
        throw new Error('Failed to close cash transaction');
    }
};

export const approvePettyCashTransaction = async (transactionId) => {
    try {
        const response = await axios.post(`${config.inxOmsApiUrl}/financials/api/petty_cash_transactions/${transactionId}/approve/`, {}, {
            headers: { Authorization: `Token ${token}` }
        });
        return response.data;
    } catch (error) {
        console.error('Error approving cash transaction:', error);
        throw new Error('Failed to approve cash transaction');
    }
};

export const cancelPettyCashTransaction = async (transactionId) => {
    try {
        const response = await axios.post(`${config.inxOmsApiUrl}/financials/api/petty_cash_transactions/${transactionId}/cancel/`, {}, {
            headers: { Authorization: `Token ${token}` }
        });
        return response.data;
    } catch (error) {
        console.error('Error cancelling petty cash transaction:', error);
        throw new Error('Failed to cancel petty cash transaction');
    }
};

export const rejectPettyCashTransaction = async (transactionId, rejectionReason) => {
    try {
        const response = await axios.post(`${config.inxOmsApiUrl}/financials/api/petty_cash_transactions/${transactionId}/reject/`, {
            rejectionReason: rejectionReason
        }, {
            headers: { Authorization: `Token ${token}` }
        });
        return response.data;
    } catch (error) {
        console.error('Error rejecting petty cash transaction:', error);
        throw new Error('Failed to reject petty cash transaction');
    }
};

import axios from 'axios';
import config from '../../config';
import {getAuthToken} from "../../utils/authUtils";

const token = getAuthToken();

export const fetchCompaniesShortApi = async (page = 1, search = '') => {
    try {
        const response = await axios.get(`${config.inxOmsApiUrl}/core_config/api/companies-list/`, {
            headers: { Authorization: `Token ${token}` },
            params: { page, search },
        });
        console.log("API Success Response", response.data);
        return response.data;
    } catch (error) {
        console.error("API Error Response", error.response?.data);
        throw new Error('Failed to load companies');
    }
};

export const fetchCompaniesApi = async (page = 1, search = '') => {
    try {
        const response = await axios.get(`${config.inxOmsApiUrl}/core_config/api/companies/`, {
            headers: { Authorization: `Token ${token}` },
            params: { page, search },
        });
        console.log("API Success Response", response.data);
        return response.data;
    } catch (error) {
        console.error("API Error Response", error.response?.data);
        throw new Error('Failed to load companies');
    }
};

export const createCompanyApi = async (data) => {
    try {
        const response = await axios.post(`${config.inxOmsApiUrl}/core_config/api/register_company/`, data, {
            headers: { Authorization: `Token ${token}`, 'Content-Type': 'application/json' },
        });
        console.log("API Response:", response);
        if (response.status === 201) {
            return { success: true, data: response.data }; // Assume success if HTTP status 201
        } else {
            return { success: false, message: "Unexpected status code received" };
        }
    } catch (error) {
        console.error("API Error:", error.response ? error.response.data : error);
        return { success: false, message: error.response?.data.error || 'Failed to create company' };
    }
};


export const updateCompanyApi = async (id, data) => {
    // const token = localStorage.getItem('token');
    // try {
    //     const response = await axios.patch(`${config.inxOmsApiUrl}/core_config/api/companies/${id}/`, data, {
    //         headers: { Authorization: `Token ${token}`, 'Content-Type': 'application/json' },
    //     });
    //     return response.data;
    // } catch (error) {
    //     throw new Error(error.response?.data || 'Failed to update company');
    // }
    console.log("Feature is not available yet!");
};

import React from 'react';
import SalesAnalyticsDashboard from "./SalesAnalyticsDashboard";


function Dashboard(props) {


    return (
        <>

            <SalesAnalyticsDashboard/>
        </>
    );
}

export default Dashboard;
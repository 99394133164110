import axios from 'axios';
import config from '../../config';
import {getAuthToken} from "../../utils/authUtils";  // Ensure you have a config file with your API's URL

const token = getAuthToken();

export const fetchStockTransfersApi = async (page = 1, search = '') => {
    try {
        const response = await axios.get(`${config.inxOmsApiUrl}/inventory/api/stock_transfers/`, {
            headers: { Authorization: `Token ${token}` },
            params: { page, search },
        });
        return response.data;
    } catch (error) {
        throw new Error('Failed to load stock transfers');
    }
};



export const createStockTransferApi = async (stockTransferData) => {
    try {
        const response = await axios.post(`${config.inxOmsApiUrl}/inventory/api/stock_transfers/`, stockTransferData, {
            headers: { Authorization: `Token ${token}` }
        });
        console.log("Response: ", response);
        // Check if the response has a status code in the 2xx range
        if (response.status >= 200 && response.status < 300) {
            return response;
        } else {
            // Handle non-successful HTTP statuses
            throw new Error(`Failed to create stock transfer: ${response.status} ${response.statusText}`);
        }
    } catch (error) {
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            throw new Error(`Server responded with an error: ${error.response.status} ${error.response.data.detail || error.response.statusText}`);
        } else if (error.request) {
            // The request was made but no response was received
            throw new Error('No response from server');
        } else {
            // Something happened in setting up the request that triggered an Error
            throw new Error('Error creating stock transfer: ' + error.message);
        }
    }
};

export const confirmStockTransferApi = async (stockTransferId) => {
  try {
    const response = await axios.post(
      `${config.inxOmsApiUrl}/inventory/api/stock_transfers/${stockTransferId}/confirm/`,
      {},
      {
        headers: { Authorization: `Token ${token}` }
      }
    );
    return {
      success: true,
      data: response.data,
      message: 'Stock transfer confirmed successfully.'
    };
  } catch (error) {
    console.error('Error confirming stock transfer:', error);
    return {
      success: false,
      message: error.response?.data?.detail || 'Failed to confirm stock transfer.'
    };
  }
};

export const recordSerialNumbersApi = async (stockTransferId, serialNumbers) => {
    try {
        const response = await axios.post(
            `${config.inxOmsApiUrl}/inventory/api/stock_transfers/${stockTransferId}/record_serial_numbers/`,
            { serial_numbers: serialNumbers },
            {
                headers: { Authorization: `Token ${token}` }
            }
        );
        return {
            success: true,
            message: 'Serial numbers recorded successfully.'
        };
    } catch (error) {
        console.error('Error recording serial numbers:', error);
        return {
            success: false,
            message: error.response?.data?.detail || 'Failed to record serial numbers.'
        };
    }
};



export const executeStockTransferApi = async (stockTransferId, remarks = '') => {
    try {
        const response = await axios.post(
            `${config.inxOmsApiUrl}/inventory/api/stock_transfers/${stockTransferId}/execute/`,
            {remarks},
            {
                headers: { Authorization: `Token ${token}` },
            }
        );
        return {
            success: true,
            message: response.data.detail,
        };
    } catch (error) {
        console.error('Error executing stock transfer:', error.response?.data?.detail);
        return {
            success: false,
            message: error.response?.data?.detail || 'Failed to execute stock transfer.',
        };
    }
};


export const dispatchStockTransferApi = async (stockTransferId,remarks = '') => {
    try {
        const response = await axios.post(
            `${config.inxOmsApiUrl}/inventory/api/stock_transfers/${stockTransferId}/dispatch/`,
            { remarks },
            {
                headers: { Authorization: `Token ${token}` },
            }
        );
        return {
            success: true,
            message: response.data.detail,
        };
    } catch (error) {
        console.error('Error dispatching stock transfer:', error);
        return {
            success: false,
            message: error.response?.data?.detail || 'Failed to dispatch stock transfer.',
        };
    }
};


// **
//  * Marks a stock transfer as received.
//  * @param {number} stockTransferId - ID of the stock transfer to mark as received.
//  * @param {string} remarks - Optional remarks for the receiving action.
//  * @returns {Promise} A promise that resolves with the API response.
//  */
export const receiveStockTransferApi = async (stockTransferId, remarks = '') => {
  try {
    const response = await axios.post(
      `${config.inxOmsApiUrl}/inventory/api/stock_transfers/${stockTransferId}/receive/`,
      { remarks },
      {
        headers: { Authorization: `Token ${token}` },
      }
    );
    return {
      success: true,
      data: response.data,
      message: 'Stock transfer received successfully.'
    };
  } catch (error) {
    console.error('Error receiving stock transfer:', error);
    return {
      success: false,
      message: error.response?.data?.detail || 'Failed to receive stock transfer.'
    };
  }
};


/**
 * Closes a stock transfer.
 * @param {number} stockTransferId - The ID of the stock transfer to close.
 * @returns {Promise} A promise that resolves with the API response.
 */
export const closeStockTransferApi = async (stockTransferId) => {
  try {
    const response = await axios.post(
      `${config.inxOmsApiUrl}/inventory/api/stock_transfers/${stockTransferId}/close/`,
      {},
      {
        headers: { Authorization: `Token ${token}` },
      }
    );
    return {
      success: true,
      data: response.data,
      message: 'Stock transfer closed successfully.'
    };
  } catch (error) {
    console.error('Error closing stock transfer:', error);
    return {
      success: false,
      message: error.response?.data?.detail || 'Failed to close stock transfer.'
    };
  }
};


export const cancelStockTransferApi = async (stockTransferId) => {
  try {
    const response = await axios.post(
      `${config.inxOmsApiUrl}/inventory/api/stock_transfers/${stockTransferId}/cancel/`,
      {},
      {
        headers: { Authorization: `Token ${token}` },
      }
    );
    return {
      success: true,
      data: response.data,
      message: 'Stock transfer cancelled successfully.'
    };
  } catch (error) {
    console.error('Error cancelling stock transfer:', error);
    return {
      success: false,
      message: error.response?.data?.detail || 'Failed to cancel stock transfer.'
    };
  }
};



export const updateSourceBranchApi = async (stockTransferId, sourceBranchId) => {
  try {
    const response = await axios.patch(
      `${config.inxOmsApiUrl}/inventory/api/stock_transfers/${stockTransferId}/update-source-branch/`,
      { source_branch: sourceBranchId },
      {
        headers: { Authorization: `Token ${token}` },
      }
    );
    return {
      success: true,
      data: response.data,
      message: 'Stock transfer received successfully.'
    };
  } catch (error) {
    console.error('Error receiving stock transfer:', error);
    return {
      success: false,
      message: error.response?.data?.detail || 'Failed to receive stock transfer.'
    };
  }
};

import React, {useState} from 'react';
import {Button, Empty, Modal, Popconfirm, Popover, Space, Input, Table, Tag, Tooltip} from 'antd';
import {CheckOutlined, CloseOutlined, DollarOutlined, StopOutlined} from '@ant-design/icons';
import moment from 'moment';
import {approveExpenses, cancelExpenses, processPayout, rejectExpenses, reportExpenses} from "./expenseActions"; // Ensure you have moment installed or use another date library

const { TextArea } = Input;

const statusColors = {
    draft: 'gray',
    reported: 'blue',
    approved: 'green',
    rejected: 'red',
    processed: 'gold',
    cancelled: 'purple',
};

const ExpenseTable = ({
                          expenses, onView, loading, pagination,
                          onChange, setInfoMessage, setSuccessMessage,
                          setErrorMessage, setWarningMessage, refreshData
                      }) => {

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [rejectModalVisible, setRejectModalVisible] = useState(false);
    const [rejectReason, setRejectReason] = useState('');

    const onSelectChange = selectedKeys => {
        setSelectedRowKeys(selectedKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const handleReject = async () => {
        if (!rejectReason.trim()) {
            setWarningMessage('Please provide a reason for rejection.');
            return;
        }

        const rejections = selectedRowKeys.map(expenseId => ({
            expense_id: expenseId,
            reason: rejectReason
        }));

        try {
            await rejectExpenses(rejections);
            setSelectedRowKeys([]);
            setSuccessMessage('Expenses rejected successfully!');
            setRejectReason('');
            setRejectModalVisible(false);
            refreshData();
        } catch (error) {
            setErrorMessage(error.error || 'Error rejecting expenses.');
        }
    };

    const handleAction = async (actionType) => {
        if (selectedRowKeys.length === 0) {
            setWarningMessage('No expenses selected.');
            return;
        }

        let expensesToProcess = expenses.filter(expense => selectedRowKeys.includes(expense.id));


        switch (actionType) {
            case 'Report':
                try {
                    const response = await reportExpenses(selectedRowKeys);
                    setSelectedRowKeys([]);
                    setSuccessMessage(`Expenses ${actionType.toLowerCase()}ed successfully!`);
                    refreshData();
                } catch (error) {
                    setErrorMessage(error.error);
                }
                break;
            case 'Approve':
                try {
                    const response = await approveExpenses(expensesToProcess.map(expense => ({
                    expense_id: expense.id,
                    })));
                    setSelectedRowKeys([]);
                    setSuccessMessage(`Expenses ${actionType.toLowerCase()}ed successfully!`);
                    refreshData();
                } catch (error) {
                    setErrorMessage(error.error);
                }
                break;
            case 'Reject':
                setRejectModalVisible(true);
                break;
            case 'Process Payout':
                await processPayout(selectedRowKeys);
                break;
            case 'Cancel':
                try {
                    const response = await cancelExpenses(selectedRowKeys);
                    setSelectedRowKeys([]);
                    setSuccessMessage(`Expenses ${actionType.toLowerCase()}ed successfully!`);
                    refreshData();
                } catch (error) {
                    setErrorMessage(error.error);
                }
                break;
            default:
                setErrorMessage('Action not supported.');
        }
    };

    const columns = [
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            render: date => moment(date).format('DD-MM-YYYY'),  // Formatting the date
        },
        {
            title: 'Category / Sub-category',
            key: 'category',
            render: (_, record) => (
                <Tooltip title={`Category: ${record.category_name}`}>
                    <Popover
                        content={<p>{record.description}</p>}
                        title="Description"
                        trigger="click"
                    >
                        <>{record.sub_category_name}</>
                    </Popover>
                </Tooltip>
            ),
        },
        {
            title: 'Amount',
            dataIndex: 'submitted_amount',
            key: 'amount',
            align: 'right',
            render: amount => `₹${parseFloat(amount).toFixed(2)}`,  // Assuming amount is a string that can be parsed to a float
        },
        {
            title: 'Status',
            dataIndex: 'status_display',
            key: 'status',
            align: 'center',
            render: (status, record) => (
            <Tag color={statusColors[record.status] || 'default'}>
                {status}
            </Tag>
        ),
        },
        {
            title: 'Approved Amount',
            dataIndex: 'approved_amount',
            key: 'approved_amount',
            align: 'right',
            render: amount => `₹${parseFloat(amount).toFixed(2)}`,  // Assuming amount is a string that can be parsed to a float
        },
        {
            title: 'Mode of Payout',
            dataIndex: 'mode_of_payout_display',
            key: 'mode_of_payout',
            align: 'center',
        },
        {
            title: 'Reported Date',
            dataIndex: 'reported_at',
            key: 'reported_at',
            render: reportedAt => reportedAt ? moment(reportedAt).format('YYYY-MM-DD') : 'N/A',
        },
        {
            title: 'Requested By',
            dataIndex: 'requested_by_name',
            key: 'requested_by_name',
        },
        {
            title: 'Branch',
            dataIndex: 'branch_name',
            key: 'branch',
        },
        {
            title: 'Company Code',
            dataIndex: 'company_code',
            key: 'company_code',
        }
    ];

    // Custom pagination summary
    const showPaginationTotal = (total, range) => {
        return `${range[0]}-${range[1]} of ${total} records`;
    };

    return (
        <>
            <Space style={{marginBottom: 16}}>
                <Popconfirm
                    title={`Are you sure you want to report ${selectedRowKeys.length} expenses?`}
                    onConfirm={() => handleAction('Report')}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        hidden
                        icon={<CheckOutlined/>}
                        style={{backgroundColor: '#52c41a', borderColor: '#52c41a', color: '#ffffff'}}
                    >
                        Report
                    </Button>
                </Popconfirm>
                <Popconfirm
                    title={`Are you sure you want to approve ${selectedRowKeys.length} expenses?`}
                    onConfirm={() =>  handleAction('Approve')}
                    okText="Yes"
                    cancelText="No"
                >
                <Button
                    icon={<CheckOutlined/>}
                    style={{backgroundColor: '#1890ff', borderColor: '#1890ff', color: '#ffffff'}}
                >
                    Approve
                </Button>
                </Popconfirm>
                <Button
                    icon={<CloseOutlined/>}
                    onClick={() => handleAction('Reject')}
                    style={{backgroundColor: '#ff4d4f', borderColor: '#ff4d4f', color: '#ffffff'}}
                >
                    Reject
                </Button>
                <Button
                    icon={<DollarOutlined/>}
                    onClick={() => handleAction('Process Payout')}
                    style={{backgroundColor: '#faad14', borderColor: '#faad14', color: '#ffffff'}}
                >
                    Process Payout
                </Button>
                <Popconfirm
                    title={`Are you sure you want to cancel ${selectedRowKeys.length} expenses?`}
                    onConfirm={() => handleAction('Cancel')}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        icon={<StopOutlined/>}
                        style={{backgroundColor: '#d9d9d9', borderColor: '#d9d9d9'}}
                    >
                        Cancel
                    </Button>
                </Popconfirm>
            </Space>

            <Table
                columns={columns}
                dataSource={expenses}
                rowKey="id"
                pagination={{
                    ...pagination,
                    showTotal: showPaginationTotal,
                    // showSizeChanger: true,
                    // pageSizeOptions: ['10', '20', '50', '100'],
                }}
                loading={loading}
                onChange={onChange}
                rowSelection={rowSelection}
                size="small"
                scroll={{x: 'max-content'}}
                locale={{
                    emptyText: (
                        <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description="No records available."
                        />
                    ),
                }}
                onRow={(record, rowIndex) => {
                    return {
                        onClick: event => {
                            onView(record);
                        }, // click row
                    };
                }}
            />
            <Modal
                title="Reject Expenses"
                open={rejectModalVisible}
                onCancel={() => setRejectModalVisible(false)}
                onOk={handleReject}
                okText="Reject"
                okButtonProps={{ disabled: !rejectReason.trim() }}
            >
                <TextArea
                    rows={4}
                    placeholder="Enter reason for rejection"
                    value={rejectReason}
                    onChange={(e) => setRejectReason(e.target.value)}
                />
            </Modal>
        </>
    );
};

export default ExpenseTable;

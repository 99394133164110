// SerialNumbersList.js

import React, {useCallback, useEffect, useState} from 'react';
import {Button, Col, Input, Row, Select} from 'antd';
import SerialNumbersTable from './SerialNumbersTable';
import {fetchSerialNumbersApi} from "./inventoryAPI";
import {ClearOutlined, FilterOutlined} from "@ant-design/icons";
import {useDispatch, useSelector} from "react-redux";
import {fetchBrandsApi} from "../products/productSlice";

const {Search} = Input;
const {Option} = Select;

const SerialNumbersList = ({initialStatus = 'available'}) => {
    const dispatch = useDispatch();
    const [serialNumbers, setSerialNumbers] = useState([]);
    const {brands = [], brandsLoading, hasFetchedBrands} = useSelector((state) => state.products);

    const [loading, setLoading] = useState(false);
    // Consolidate all filter values into one state object
    const [userFilters, setUserFilters] = useState({
        status: initialStatus,
        serial_number: '',
        brand: '',
        item_code: ''
    });
    const [filters, setFilters] = useState(userFilters);
    const [pagination, setPagination] = useState({current: 1, pageSize: 10, total: 0});

    // Fetch companies and brands on mount
    useEffect(() => {
        if (!hasFetchedBrands) {
            const storedBrands = localStorage.getItem('brands');
            if (storedBrands) {
                try {
                    const parsedBrands = JSON.parse(storedBrands);
                    if (Array.isArray(parsedBrands) && parsedBrands.length > 0) {
                        return; // No need to dispatch if brands are already in localStorage
                    }
                } catch (error) {
                    console.error('Error parsing brands from localStorage', error);
                }
            }

            dispatch(fetchBrandsApi());
        }
    }, [dispatch, hasFetchedBrands]);


    /**
     * Build a combined brand list from Redux or localStorage
     */
    let brandList = brands; // default to Redux brand list
    if (!brands.length) {
        // If Redux has no brands, try localStorage
        const storedBrands = localStorage.getItem('brands');
        if (storedBrands) {
            try {
                const parsedBrands = JSON.parse(storedBrands);
                if (Array.isArray(parsedBrands) && parsedBrands.length > 0) {
                    brandList = parsedBrands;
                }
            } catch (error) {
                console.error('Error parsing stored brands:', error);
            }
        }
    }

    const fetchSerialNumbers = useCallback(async () => {
        setLoading(true);

        const params = {
            page: pagination.current,
            page_size: pagination.pageSize,
            status: filters.status !== 'all' ? filters.status : undefined,
            serial_number: filters.serial_number || undefined,
            brand: filters.brand || undefined,
            item_code: filters.item_code || undefined
        };


        try {
            // Pass the plain params object
            const data = await fetchSerialNumbersApi(params);
            setSerialNumbers(data.results);
            setPagination(prev => ({...prev, total: data.count}));
        } catch (error) {
            console.error('Error loading serial numbers:', error);
        } finally {
            setLoading(false);
        }
    }, [filters, pagination.current, pagination.pageSize]);

    useEffect(() => {
        fetchSerialNumbers();
    }, [fetchSerialNumbers]);

    const handleSerialNumberChange = e => setUserFilters(prev => ({...prev, serial_number: e.target.value}));
    const handleBrandChange = value => setUserFilters(prev => ({...prev, brand: value}));
    const handleItemCodeChange = e => setUserFilters(prev => ({...prev, item_code: e.target.value}));
    const handleStatusChange = value => setUserFilters(prev => ({...prev, status: value}));


    /**
     *  "Apply Filter" button:
     *  - Typically only necessary if you’re not fetching on every input change
     *  - In this example, we do fetch automatically on filter change
     *    but we’ll still show how to do an explicit apply
     */
    const handleApplyFilters = () => {
        setFilters(userFilters);
        // Force page to reset
        setPagination(prev => ({...prev, current: 1}));
        // Trigger the fetch
        // fetchSerialNumbers();
    };

    /**
     *  "Clear Filter" button
     */
    const handleClearFilters = () => {
        const resetFilters = {
            status: 'available',
            serial_number: '',
            brand: '',
            item_code: ''
        };
        setUserFilters(resetFilters);
        setFilters(resetFilters);
        setPagination({current: 1, pageSize: 10, total: 0});
    };

    /**
     *  Pagination change handler
     */
    const handlePageChange = (page, pageSize) => {
        setPagination(prev => ({...prev, current: page, pageSize}));
    };

    return (
        <div style={{padding: '24px'}}>
            {/* First Row: Four Filters */}
            <Row gutter={[16, 16]}>
                <Col xs={24} sm={12} md={6} lg={6}>
                    <Input
                        placeholder="Search by Serial #"
                        value={userFilters.serial_number}
                        onChange={handleSerialNumberChange}
                        style={{width: '100%'}}
                    />
                </Col>

                <Col xs={24} sm={12} md={6} lg={6}>
                    <Select
                        placeholder="Select Brand"
                        value={userFilters.brand || undefined}
                        onChange={handleBrandChange}
                        style={{width: '100%'}}
                        allowClear
                        showSearch
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {brandList.map((brand) => (
                            <Option key={brand.id} value={brand.id}>
                                {brand.brand_name}
                            </Option>
                        ))}
                    </Select>

                </Col>

                <Col xs={24} sm={12} md={6} lg={6}>
                    <Input
                        placeholder="Filter by Item Code"
                        value={userFilters.item_code}
                        onChange={handleItemCodeChange}
                        style={{width: '100%'}}
                    />
                </Col>

                <Col xs={24} sm={12} md={6} lg={6}>
                    <Select
                        style={{width: '100%'}}
                        value={userFilters.status}
                        onChange={handleStatusChange}
                    >
                        <Option value="all">All</Option>
                        <Option value="available">Available</Option>
                        <Option value="booked">Booked</Option>
                        <Option value="sold">Sold</Option>
                        <Option value="in_transit">In Transit</Option>
                        <Option value="damaged">Damaged</Option>
                        <Option value="new">New</Option>
                        <Option value="not_available">Not Available</Option>
                    </Select>
                </Col>
            </Row>

            {/* Second Row: Buttons aligned to the right */}
            <Row gutter={[16, 16]} justify="end" style={{marginTop: 16}}>
                <Col>
                    <Button
                        type="primary"
                        icon={<FilterOutlined/>}
                        onClick={handleApplyFilters}
                    >
                        Apply Filter
                    </Button>
                </Col>
                <Col>
                    <Button
                        icon={<ClearOutlined/>}
                        onClick={handleClearFilters}
                        danger
                    >
                        Clear Filter
                    </Button>
                </Col>
            </Row>

            <SerialNumbersTable
                data={serialNumbers}
                loading={loading}
                pagination={{
                    current: pagination.current,
                    pageSize: pagination.pageSize,
                    total: pagination.total,
                }}
                onPageChange={handlePageChange}
            />
        </div>
    );
};

export default SerialNumbersList;

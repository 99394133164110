// expenseActions.js
import {message} from 'antd';
import axios from 'axios';
import config from "../../config";
import {getAuthToken} from "../../utils/authUtils";


export const reportExpenses = async (expenseIds) => {
    const token = getAuthToken();
    try {
        const response = await axios.post(
            `${config.inxOmsApiUrl}/expenses/api/expenses/report/`,
            { expense_ids: expenseIds },
            {
                headers: {
                    Authorization: `Token ${token}`
                },
            });
        console.log('Expenses reported successfully!');
        return response.data;
    } catch (error) {
       console.log('Failed to report expenses.');
        throw error.response.data;  // Handle error as needed
    }
};

export const approveExpenses = async (expenses) => {
    const token = getAuthToken();
    console.log("Expenses Received: ", expenses);
    try {
        const response = await axios.patch(
            `${config.inxOmsApiUrl}/expenses/api/expenses/approve/`,
            { approvals: expenses},
            {
                headers: {
                    Authorization: `Token ${token}`
                },
            }
        );
        console.log('Expenses approved successfully!');
        return response.data;
    } catch (error) {

        console.log('Failed to approve expenses.', error.response.data);
        throw  error.response.data;  // Handle error as needed
    }
};

export const rejectExpenses = async (rejections) => {
    const token = getAuthToken();
    try {
        const response = await axios.patch(
            `${config.inxOmsApiUrl}/expenses/api/expenses/reject/`,
            {rejections: rejections },
            {
                headers: {
                    Authorization: `Token ${token}`
                },
            });
        console.log('Expenses rejected successfully!');
        return response.data;
    } catch (error) {
       console.log('Failed to reject expenses.');
        throw error.response.data;  // Handle error as needed
    }
};

export const processPayout = async (expenseIds) => {
    // Similar to reportExpenses
};

export const cancelExpenses = async (expenseIds) => {
    const token = getAuthToken();
    try {
        const response = await axios.patch(
            `${config.inxOmsApiUrl}/expenses/api/expenses/cancel/`,
            { expense_ids: expenseIds },
            {
                headers: {
                    Authorization: `Token ${token}`
                },
            });
        console.log('Expenses canceled successfully!');
        return response.data;
    } catch (error) {

        console.log('Failed to cancel expenses.', error.response.data);
        throw error.response.data;  // Handle error as needed
    }
};

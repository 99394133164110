import React, {useEffect, useState} from 'react';
import {Button, Col, Empty, Input, message, Modal, Row, Select, Table} from 'antd';
import {confirmCashTransaction, fetchCashTransactions, rejectCashTransaction} from './cashTransactionsAPI';
import CashTransactionForm from './CashTransactionForm';
import {useDispatch, useSelector} from "react-redux";
import {fetchCompanies} from "../../store/slices/companyBranchSlice";
import TransactionDetailsDrawer from "./TransactionDetailsDrawer";
import {formatRupee} from "../../utils/utils";
import {formatDate} from "../../utils/dateUtils";

const {Search} = Input;
const {Option} = Select;

const CashTransactionsList = () => {
    const dispatch = useDispatch();
    const [transactions, setTransactions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [editingTransaction, setEditingTransaction] = useState(null);

    const [pagination, setPagination] = useState({current: 1, pageSize: 10});
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedCompany, setSelectedCompany] = useState('');
    const [selectedBranch, setSelectedBranch] = useState('');
    const [selectedStatus, setSelectedStatus] = useState('draft');
    const [selectedType, setSelectedType] = useState('');

    const {companies, loading: companiesLoading} = useSelector((state) => state.companyBranch);
    const [branches, setBranches] = useState([]);

    const [successMessage, setSuccessMessage] = useState(null);
    const [infoMessage, setInfoMessage] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [messageApi, contextHolder] = message.useMessage();

    const [drawerVisible, setDrawerVisible] = useState(false);
    const [selectedTransaction, setSelectedTransaction] = useState(null);


    // Temporary state for filters
    const [tempSearchTerm, setTempSearchTerm] = useState('');
    const [tempSelectedStatus, setTempSelectedStatus] = useState('');
    const [tempSelectedCompany, setTempSelectedCompany] = useState('');
    const [tempSelectedBranch, setTempSelectedBranch] = useState('');

    const user = JSON.parse(localStorage.getItem('user'));
    const userRole = user?.user_role;

    const companyId = user?.company_id || '';
    const companyName = user?.company || '';
    const companyCode = user?.company_code
    const branchId = user?.branch_id || '';
    const branchName = user?.branch || '';
    const branchCode = user?.branch_code || '';

    // Fetch companies on component mount
    useEffect(() => {
        if (!companies || companies.length === 0) {
            dispatch(fetchCompanies());
        }
    }, [dispatch, companies]);

    // Update branches when a company is selected
    useEffect(() => {
        const company = companies.find((comp) => comp.id === tempSelectedCompany);
        setBranches(company ? company.branches : []);
    }, [tempSelectedCompany, companies]);

    // Handle displaying success and error messages using useEffect
    useEffect(() => {
        if (successMessage) {
            messageApi.open({
                type: 'success',
                content: successMessage,
                duration: 2.5,
            });
            setSuccessMessage(null);
        }
        if (errorMessage) {
            messageApi.open({
                type: 'error',
                content: errorMessage,
                duration: 2.5,
            });
            setErrorMessage(null);
        }
        if (infoMessage) {
            messageApi.open({
                type: 'info',
                content: infoMessage,
                duration: 2.5,
            });
            setInfoMessage(null);
        }
    }, [successMessage, errorMessage, infoMessage, messageApi]);

    useEffect(() => {
        loadTransactions();
    }, []);

    useEffect(() => {
        loadTransactions();
    }, [pagination.current, pagination.pageSize, selectedStatus, selectedType]); // Re-fetch when these values change


    const loadTransactions = async () => {
        setLoading(true);
        const params = {
            page: pagination.current,
            pageSize: pagination.pageSize,
            status: selectedStatus,
            transaction_type: selectedType,
        };
        try {
            const data = await fetchCashTransactions(params);
            setTransactions(data.results);
            setPagination({...pagination, total: data.count}); // Update pagination total
        } catch (error) {
            message.error('Failed to fetch transactions: ' + error.message);
        } finally {
            setLoading(false);
        }
    };


    const showModal = (transaction) => {
        setEditingTransaction(transaction);
        setIsModalVisible(true);
    };


    const handleCancel = () => {
        setIsModalVisible(false);
        setEditingTransaction(null);
    };



    const columns = [
        {title: '#', dataIndex: 'id', key: 'id'},
        {title: 'Source Branch', dataIndex: 'source_branch_name', key: 'source_branch_name'},
        {
        title: 'Transaction Date',
        dataIndex: 'transaction_date',
        key: 'transaction_date',
        render: (text) => formatDate(text) // Apply the formatDate function
    },
    {
        title: 'Amount',
        dataIndex: 'amount',
        key: 'amount',
        align: 'right',
        render: (value) => formatRupee(value) // Apply the formatRupee function
    },
        {title: 'Transaction Type', dataIndex: 'display_transaction_type', key: 'display_transaction_type', align: 'center'},
        {
            title: 'Account/Target Branch',
            dataIndex: 'transaction_type',
            key: 'account_target',
            render: (_, record) => record.transaction_type === 'bank_deposit' ? record.bank_account_name : record.target_branch_name,
        },
        {title: 'Status', dataIndex: 'display_status', key: 'display_status', align: 'center'},
        {title: 'Company Code', dataIndex: 'company_code', key: 'company_code', align: 'center'},
    ];


    // Custom pagination summary
    const showPaginationTotal = (total, range) => {
        return `${range[0]}-${range[1]} of ${total} orders`;
    };

    return (
        <div>
            {contextHolder}
            <Row justify="space-between" style={{marginBottom: 16}}>
                <Col>
                    <Select defaultValue="draft" style={{width: 120}} onChange={setSelectedStatus}>
                        <Option value="">All Statuses</Option>
                        <Option value="draft">Draft</Option>
                        <Option value="confirmed">Confirmed</Option>
                        <Option value="canceled">Canceled</Option>
                    </Select>
                    <Select defaultValue="" style={{width: 120, marginLeft: 8}} onChange={setSelectedType}>
                        <Option value="">All Types</Option>
                        <Option value="branch_transfer">Branch Transfer</Option>
                        <Option value="bank_deposit">Bank Deposit</Option>
                    </Select>
                </Col>
                <Col>
                    <Button type="primary" onClick={() => setIsModalVisible(true)}>
                        Add New Transaction
                    </Button>
                </Col>
            </Row>
            <Table
                dataSource={transactions}
                columns={columns}
                rowKey="id"
                loading={loading}
                pagination={{
                    current: pagination.current,
                    pageSize: pagination.pageSize,
                    total: pagination.total,
                    showTotal: showPaginationTotal,
                    // showSizeChanger: true,
                    // pageSizeOptions: ['10', '20', '50', '100'],
                }}
                size="small"
                scroll={{x: 'max-content'}}
                    onRow={(record) => {
            return {
                onClick: () => {
                    setSelectedTransaction(record);
                    setDrawerVisible(true);
                }
            };
        }}
                locale={{
                    emptyText: (
                        <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description="No orders available."
                        />
                    ),
                }}
            />

            <Modal width={800} title={editingTransaction ? "Edit Transaction" : "Add New Transaction"}
                   open={isModalVisible}
                   onCancel={handleCancel}
                   footer={null}>
                <CashTransactionForm
                    transaction={editingTransaction}
                    setSuccessMessage={setSuccessMessage}
                    setErrorMessage={setErrorMessage}
                    onCancel={handleCancel}
                    refreshTransactions={loadTransactions}
                    companies={companies}
                    companyId={companyId}
                    setTempSelectedCompany={setTempSelectedCompany}
                    branches={branches}
                    branchId={branchId}
                    userRole={userRole}
                />
            </Modal>
            <TransactionDetailsDrawer
            visible={drawerVisible}
            onClose={() => setDrawerVisible(false)}
            transaction={selectedTransaction}
            refreshTransactions={loadTransactions}
            setSuccessMessage={setSuccessMessage}
            setErrorMessage={setErrorMessage}
        />
        </div>
    );
};

export default CashTransactionsList;

import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {message} from "antd";
import InventoryStockList from "../features/inventory/InventoryStockList";

function InventoryPage() {

    // State for managing steps and receipt data
    const [messageApi, contextHolder] = message.useMessage(); // Initialize Ant Design message
    const [successMessage, setSuccessMessage] = useState(null);
    const [infoMessage, setInfoMessage] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    // Handle displaying success and error messages using useEffect
    useEffect(() => {
        if (successMessage) {
            console.log("Displaying success message:", successMessage);
            messageApi.open({
                type: 'success',
                content: successMessage,
                duration: 2.5,
            });
            setSuccessMessage(null);
        }
        if (errorMessage) {
            console.log("Displaying error message:", errorMessage);
            messageApi.open({
                type: 'error',
                content: errorMessage,
                duration: 2.5,
            });
            setErrorMessage(null);
        }
        if (infoMessage) {
            console.log("Displaying info message:", infoMessage);
            messageApi.open({
                type: 'info',
                content: infoMessage,
                duration: 2.5,
            });
            setInfoMessage(null);
        }
    }, [successMessage, errorMessage, infoMessage, messageApi]);

    return (
        <div style={{padding: 8}}>
            {contextHolder}
            <InventoryStockList
                        setErrorMessage={setErrorMessage}
                setSuccessMessage={setSuccessMessage}
            />
        </div>
    );
}

export default InventoryPage;
import React from 'react';
import {Empty, Table} from 'antd';
import moment from 'moment'; // Make sure to install moment for date formatting

// Utils for formatting text and numbers; implement these according to your needs
const truncateText = (text, length) => text.length > length ? `${text.substring(0, length)}...` : text;

// Formatting function for dates
const formatDate = (value) => value ? moment(value).format('DD-MM-YYYY') : '-';

const StockTransfersTable = ({data, loading, pagination, onTableChange, openDrawer}) => {
    const columns = [
        {title: 'ID', dataIndex: 'id', key: 'id'},
        {
            title: 'Created At',
            dataIndex: 'created_at',
            key: 'created_at',
            render: text => <span>{formatDate(text)}</span>
        },
        {title: 'Brand', dataIndex: 'brand_name', key: 'brand_name'},
        {title: 'Item Code', dataIndex: 'item_code', key: 'item_code'},
        {title: 'Quantity', dataIndex: 'quantity', key: 'quantity'},
        {title: 'Status', dataIndex: 'status_display', key: 'status_display'},
        {title: 'Company', dataIndex: 'company_code', key: 'company_code'},
        {title: 'Source Branch', dataIndex: 'source_branch_name', key: 'source_branch_name'},
        {title: 'Destination Branch', dataIndex: 'destination_branch_name', key: 'destination_branch_name'},

        {
            title: 'Updated At',
            dataIndex: 'updated_at',
            key: 'updated_at',
            render: text => <span>{formatDate(text)}</span>
        }
    ];

    // Custom pagination summary
    const showPaginationTotal = (total, range) => {
        return `${range[0]}-${range[1]} of ${total} requests`;
    };


    return (
        <Table
            columns={columns}
            dataSource={data}
            rowKey="id"
            loading={loading}
            pagination={{
                ...pagination,
                showTotal: showPaginationTotal,
                size: "middle",
                // showSizeChanger: true,
                // pageSizeOptions: ['10', '20', '50', '100'],
            }}
            onChange={onTableChange}
            bordered
            size="middle"
            scroll={{x: 'max-content'}}
            locale={{
                emptyText: (
                    <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description="No requests available."
                    />
                ),
            }}
            onRow={(record) => ({
                onClick: () => openDrawer(record)
            })}
        />
    );
};

export default StockTransfersTable;

import React, { useEffect, useState } from 'react';
import {Card, Col, Row, Statistic, Spin, message, Table, Empty, Tooltip} from 'antd';
import {DollarOutlined, WalletOutlined, BarChartOutlined, CreditCardOutlined} from '@ant-design/icons';
import axios from 'axios';
import config from "../../config";
import {formatRupee, truncateText} from "../../utils/utils";
import ExpensesOverview from "./ExpensesOverview";

const ExpensesDashboard = () => {
  const [loading, setLoading] = useState(true);
  const [financialData, setFinancialData] = useState([]);
  const [branchData, setBranchData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem('token'); // Assuming the token is stored in localStorage
      const headers = { Authorization: `Token ${token}` };
      try {
        const [finRes, branchRes, catRes] = await Promise.all([
          axios.get(`${config.inxOmsApiUrl}/expenses/api/expense-analytics/`, { headers }),
          axios.get(`${config.inxOmsApiUrl}/expenses/api/branch-wise-expense-analytics/`, { headers }),
          axios.get(`${config.inxOmsApiUrl}/expenses/api/expense-analytics/category-wise/`, { headers }),
        ]);
        setFinancialData(finRes.data);
        setBranchData(branchRes.data);
        setCategoryData(catRes.data);
      } catch (error) {
        message.error('Error loading data');
        console.error('ExpensesDashboard data fetch error:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  if (loading) {
    return <Spin size="large" style={{ display: 'block', textAlign: 'center', marginTop: 50 }} />;
  }

  return (
    <div>
      <Row gutter={16}>
        <Col span={24}>
          <ExpensesOverview  financialData={financialData} />
        </Col>
        <Col span={24}>
          <Card title="Branch Expenses" style={{ marginBottom: 16 }}>
            <Table
                dataSource={branchData}
                columns={branchColumns}
                pagination={false}
                rowKey="branch_id"
                size='small'
                scroll={{x: 'max-content'}}
                locale={{
                    emptyText: (
                        <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description="No records available."
                        />
                    ),
                }}
            />
          </Card>
        </Col>
        <Col span={24}>
          <Card title="Category wise Expenses">
            <Table
                dataSource={categoryData}
                columns={categoryColumns}
                pagination={false}
                rowKey={record => record.sub_category_id}
                size='middle'
                scroll={{x: 'max-content'}}
                locale={{
                    emptyText: (
                        <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description="No records available."
                        />
                    ),
                }}
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

const branchColumns = [
    {
      title: 'Company Code',
      dataIndex: 'company_code',
      key: 'company_code',
      align: 'left'
    },
    {
      title: 'Branch Code',
      dataIndex: 'branch_code',
      key: 'branch_code',
      align: 'left',
      render: (text, record) => (
        <Tooltip title={record.branch_name || 'No branch name'}>
          {text}
        </Tooltip>
      )
    },
    { title: 'Draft', dataIndex: 'draft_value', key: 'draft_value', align: 'right', render: (value, record) => <div>{formatRupee(value)}<br/><small>({record.draft_count})</small></div> },
    { title: 'Reported', dataIndex: 'reported_value', key: 'reported_value', align: 'right', render: (value, record) => <div>{formatRupee(value)}<br/><small>({record.reported_count})</small></div> },
    { title: 'Approved', dataIndex: 'approved_value', key: 'approved_value', align: 'right', render: (value, record) => <div>{formatRupee(value)}<br/><small>({record.approved_count})</small></div> },
    { title: 'Rejected', dataIndex: 'rejected_value', key: 'rejected_value', align: 'right', render: (value, record) => <div>{formatRupee(value)}<br/><small>({record.rejected_count})</small></div> },
    { title: 'Processed This Month', dataIndex: 'processed_current_month_value', key: 'processed_current_month_value', align: 'right', render: (value, record) => <div>{formatRupee(value)}<br/><small>({record.processed_current_month_count})</small></div> },
    { title: 'Processed Last Month', dataIndex: 'processed_last_month_value', key: 'processed_last_month_value', align: 'right', render: (value, record) => <div>{formatRupee(value)}<br/><small>({record.processed_last_month_count})</small></div> }
  ];

const categoryColumns = [
  {
    title: 'Category',
    dataIndex: 'category',
    key: 'category',
    align: 'left',
    render: text => truncateText(text, 30)
  },
  {
    title: 'Sub Category',
    dataIndex: 'sub_category',
    key: 'sub_category',
    align: 'left',
    render: text => truncateText(text, 30)
  },
  {
    title: 'Current Month',
    key: 'currentMonth',
    align: 'right',
    render: (_, record) => (
      <div>
        {formatRupee(record.current_month_value)} INR
        <br />
        <small>({record.current_month_count} transactions)</small>
      </div>
    )
  },
  {
    title: 'Last Month',
    key: 'lastMonth',
    align: 'right',
    render: (_, record) => (
      <div>
        {formatRupee(record.last_month_value)} INR
        <br />
        <small>({record.last_month_count} transactions)</small>
      </div>
    )
  }
];


export default ExpensesDashboard;

import React from 'react';
import { Card, Col, Row, Statistic, Tooltip } from 'antd';
import { DollarOutlined, WalletOutlined, CreditCardOutlined } from '@ant-design/icons';
import { formatRupee } from "../../utils/utils"; // Ensure this utility is correctly implemented for currency formatting

const ExpensesOverview = ({ financialData }) => {
  const {
    open_expenses_value,
    open_expenses_count,
    processed_this_month_value,
    processed_this_month_count,
    processed_last_month_value,
    processed_last_month_count,
    petty_cash_in_hand
  } = financialData;

  return (
    <Card title="Financial Overview" bordered={false} style={{ marginBottom: 20 }}>
      <Row gutter={[16, 16]}>
        <Col span={8}>
          <Statistic
            title="Open Expenses"
            prefix={<DollarOutlined style={{ color: '#52c41a' }} />}
            value={formatRupee(open_expenses_value || 0)}
            valueStyle={{ color: '#3f8600', fontSize: '1.5em' }}
          />
          <div style={{ fontSize: '1em', color: '#555' }}>{`Transactions: ${open_expenses_count}`}</div>
        </Col>
        <Col span={8}>
          <Tooltip title={`Last Month: ${formatRupee(processed_last_month_value)} (${processed_last_month_count} transactions)`}>
            <Statistic
              title="Processed This Month"
              prefix={<WalletOutlined style={{ color: '#1890ff' }} />}
              value={formatRupee(processed_this_month_value)}
              valueStyle={{ color: '#1890ff', fontSize: '1.5em' }}
            />
            <div style={{ fontSize: '1em', color: '#555' }}>{`Transactions: ${processed_this_month_count}`}</div>
          </Tooltip>
        </Col>
        <Col span={8}>
          <Statistic
            title="Petty Cash in Hand"
            prefix={<CreditCardOutlined style={{ color: '#faad14' }} />}
            value={formatRupee(petty_cash_in_hand || 0)}
            valueStyle={{ color: '#faad14', fontSize: '1.5em' }}
          />
          <div style={{ fontSize: '1em', color: '#555' }}>Accessible cash reserve</div>
        </Col>
      </Row>
    </Card>
  );
};

export default ExpensesOverview;

import React, {useState} from 'react';
import {Descriptions, Divider, Drawer, Tag, Typography} from 'antd';
import {formatDate} from "../../utils/dateUtils";
import StockTransferActions from "./StockTransferActions";
import {recordSerialNumbersApi} from "./stockTransfersApi";
import RecordSerialNumbersModal from "./RecordSerialNumbersModal";

const {Title, Text} = Typography;

StockTransferActions.propTypes = {};
const StockTransferDetailsDrawer = ({
                                        visible,
                                        refreshData,
                                        stockTransfer,
                                        onClose,
                                        handleConfirmTransfer,
                                        handleDispatchTransfer,
                                        handleReceiveTransfer,
                                        handleCloseTransfer,
                                        handleExecuteTransfer,
                                        onEditStockTransfer,
                                        setErrorMessage,
                                        setSuccessMessage,
    handleCancelTransfer,
                                        ...actionProps
                                    }) => {
    const [isSerialModalVisible, setSerialModalVisible] = useState(false);

    if (!stockTransfer) return null; // Ensure stockTransfer is not null before accessing properties


    const renderSerialNumbers = (serialNumbers) => (
        serialNumbers.length > 0 ?
            serialNumbers.map((serial, index) => (
                <Tag key={index}>{serial.serial_number_code}</Tag>
            )) :
            <Text type="secondary">No serial numbers</Text>
    );

    const handleSerialSubmit = async (stockTransferId, serialNumbers) => {
        const result = await recordSerialNumbersApi(stockTransferId, serialNumbers);
        if (!result.success) {
            setErrorMessage(result.message);  // Ensure this message is showing up
        } else {
            setSuccessMessage('Serial numbers recorded successfully');
            setSerialModalVisible(false);  // Close the modal
            await refreshData();  // Refresh data to get updated serial numbers
        }
    };




    return (
        <>

            <Drawer
                title={<Title level={4}>Stock Transfer Details - ID: {stockTransfer.id}</Title>}
                width={720}
                onClose={onClose}
                open={visible}
                extra={<StockTransferActions
                    stockTransfer={stockTransfer} {...actionProps}
                    refreshData={refreshData}
                    setSerialModalVisible={setSerialModalVisible}
                    setErrorMessage={setErrorMessage}
                    setSuccessMessage={setSuccessMessage}
                    setInfoMessage={setSuccessMessage}
                    handleConfirmTransfer={handleConfirmTransfer}
                    handleDispatchTransfer={handleDispatchTransfer}
                    handleReceiveTransfer={handleReceiveTransfer}
                    handleCloseTransfer={handleCloseTransfer}
                    handleExecuteTransfer={handleExecuteTransfer}
                    onEditStockTransfer={onEditStockTransfer}
                    handleCencelTransfer={handleCancelTransfer}
                        companyId={stockTransfer.company_id}
                />}
            >
                <Descriptions bordered column={1} size="small" title="General Info">
                    <Descriptions.Item label="Company">{stockTransfer.company_name}</Descriptions.Item>
                    <Descriptions.Item label="Source Branch">{stockTransfer.source_branch_name}</Descriptions.Item>
                    <Descriptions.Item
                        label="Destination Branch">{stockTransfer.destination_branch_name}</Descriptions.Item>
                    <Descriptions.Item label="Item Name">{stockTransfer.item_name}</Descriptions.Item>
                    <Descriptions.Item label="Item Code">{stockTransfer.item_code}</Descriptions.Item>
                    <Descriptions.Item label="Brand">{stockTransfer.brand_name}</Descriptions.Item>
                    <Descriptions.Item label="Quantity">{stockTransfer.quantity}</Descriptions.Item>
                    <Descriptions.Item label="Status">{stockTransfer.status_display}</Descriptions.Item>
                    <Descriptions.Item label="Serial Numbers">
                        {renderSerialNumbers(stockTransfer.serial_numbers)}
                    </Descriptions.Item>
                </Descriptions>

                <Divider/>

                <Descriptions bordered column={2} size="small" title="Dates & Identification">
                    <Descriptions.Item label="Created At">{formatDate(stockTransfer.created_at)}</Descriptions.Item>
                    <Descriptions.Item label="Updated At">{formatDate(stockTransfer.updated_at)}</Descriptions.Item>
                    <Descriptions.Item label="Created By">{stockTransfer.created_by}</Descriptions.Item>
                    <Descriptions.Item label="Updated By">{stockTransfer.updated_by}</Descriptions.Item>
                    <Descriptions.Item label="Closed At">{formatDate(stockTransfer.closed_at)}</Descriptions.Item>
                    <Descriptions.Item label="Closed By">{stockTransfer.closed_by}</Descriptions.Item>
                </Descriptions>

                <Divider/>

                <Descriptions bordered column={1} size="small">
                    <Descriptions.Item label="Remarks">
                        {stockTransfer.request_remarks || 'No remarks'}
                    </Descriptions.Item>
                </Descriptions>
            </Drawer>
            <RecordSerialNumbersModal
                visible={isSerialModalVisible}
                onClose={() => setSerialModalVisible(false)}
                onSubmit={handleSerialSubmit}
                stockTransferId={stockTransfer.id}
                setSuccessMessage={setSuccessMessage}
                setErrorMessage={setErrorMessage}
            />
        </>

    );
};

export default StockTransferDetailsDrawer;

import React, { useState, useEffect } from 'react';
import { Modal, Input, Button, Typography, List, Tag } from 'antd';

const { Text, Title } = Typography;

/**
 * @param {boolean}  visible
 * @param {Function} onClose
 * @param {Function} onSubmit -> (itemId, arrayOfSerialNumbers)
 * @param {Array}    initialSerialNumbers -> Already assigned serials
 * @param {number}   itemId -> The numeric ID of the sale_order_item
 * @param {string}   itemCode -> The item code of the product
 * @param {number}   quantity -> Total quantity for the sale order item
 */
const SerialNumberInputModal = ({
  visible,
  onClose,
  onSubmit,
  initialSerialNumbers = [],
  itemId,
  itemCode,
  quantity,
  setErrorMessage,
  setSuccessMessage,
}) => {
  const [newSerialNumbers, setNewSerialNumbers] = useState([]);
  const [currentSerial, setCurrentSerial] = useState('');

  useEffect(() => {
    if (visible) {
      setNewSerialNumbers([]);
      setCurrentSerial('');
    }
  }, [visible]);

  const handleAddSerial = () => {
    if (currentSerial.trim()) {
      if (newSerialNumbers.includes(currentSerial.trim()) || initialSerialNumbers.includes(currentSerial.trim())) {
        setErrorMessage('This serial number is already added.');
      } else if (newSerialNumbers.length + initialSerialNumbers.length >= quantity) {
        setErrorMessage(`You cannot add more than ${quantity} serial numbers.`);
      } else {
        setNewSerialNumbers((prev) => [...prev, currentSerial.trim()]);
        setCurrentSerial('');
      }
    } else {
      setErrorMessage('Serial number cannot be empty.');
    }
  };

  const handleRemoveSerial = (serial) => {
    setNewSerialNumbers((prev) => prev.filter((s) => s !== serial));
  };

  const handleSubmit = async () => {
    if (newSerialNumbers.length === 0) {
      setErrorMessage('Please add at least one serial number.');
      return;
    }
    try {
      // Submit only the new serial numbers
      await onSubmit(itemId, newSerialNumbers);
      setSuccessMessage('Serial numbers recorded successfully.');
      onClose();
    } catch (error) {
      setErrorMessage(error.message || 'Failed to record serial numbers.');
    }
  };

  return (
    <Modal
      title="Record Serial Numbers"
      open={visible}
      onCancel={onClose}
      footer={[
        <Button key="back" onClick={onClose}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={handleSubmit}>
          Submit
        </Button>,
      ]}
    >
      <div style={{ marginBottom: 16 }}>
        <Title level={5}>Item Details</Title>
        <Text strong>Item Code:</Text> <Text>{itemCode}</Text>
        <br />
        <Text strong>Quantity:</Text> <Text>{quantity}</Text>
        <br />
        <Text strong>Recorded Serial Numbers:</Text>{' '}
        <Text>
          {initialSerialNumbers.length + newSerialNumbers.length}/{quantity}
        </Text>
      </div>

      {initialSerialNumbers.length > 0 && (
        <>
          <Title level={5} style={{ marginTop: 20 }}>
            Already Recorded Serial Numbers
          </Title>
          <List
            bordered
            dataSource={initialSerialNumbers}
            renderItem={(serial) => (
              <List.Item>
                <Tag color="green">{serial}</Tag>
              </List.Item>
            )}
          />
        </>
      )}

      <Title level={5} style={{ marginTop: 20 }}>Add New Serial Numbers</Title>
      <div style={{ display: 'flex', marginBottom: 16 }}>
        <Input
          value={currentSerial}
          onChange={(e) => setCurrentSerial(e.target.value)}
          placeholder="Enter serial number"
          style={{ marginRight: 8 }}
        />
        <Button type="primary" onClick={handleAddSerial}>
          Add
        </Button>
      </div>

      <List
        bordered
        dataSource={newSerialNumbers}
        renderItem={(serial) => (
          <List.Item
            actions={[
              <Button
                type="link"
                danger
                onClick={() => handleRemoveSerial(serial)}
              >
                Remove
              </Button>,
            ]}
          >
            <Tag color="blue">{serial}</Tag>
          </List.Item>
        )}
        locale={{ emptyText: 'No new serial numbers added yet.' }}
      />
    </Modal>
  );
};

export default SerialNumberInputModal;

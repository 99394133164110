import React from 'react';
import {Button, Popconfirm, Space} from 'antd';
import {CarOutlined, CheckCircleOutlined, CloseCircleOutlined, DownloadOutlined, SendOutlined} from '@ant-design/icons';


const StockTransferActions = ({
                                  stockTransfer,
                                  refreshData,
                                  setSerialModalVisible,
                                  handleConfirmTransfer,
                                  handleDispatchTransfer,
                                  handleReceiveTransfer,
                                  handleCloseTransfer,
                                  handleExecuteTransfer,
    onEditStockTransfer,
    handleCancelTransfer,
    companyId,
                                  onClose,
                              }) => {
    if (!stockTransfer) return null;

    const user = JSON.parse(localStorage.getItem('user'));
    const userRole = user?.user_role;
    const accessible_branches = user?.accessible_branches;


    // Check if user has access to the source branch or is an elevated role
    const hasElevatedAccess = ['super_admin', 'admin', 'backoffice'].includes(userRole);
    const hasBranchAccess = accessible_branches.includes(stockTransfer.source_branch_id);
    const hasDestinationBranchAccess = accessible_branches.includes(stockTransfer.destination_branch_id);

    const canReceiveOrClose = hasElevatedAccess || hasDestinationBranchAccess;
    const canExecute = hasElevatedAccess || hasBranchAccess;

    const canRecordSerialNumbers =
        stockTransfer.has_serial_no &&
        ['draft', 'confirmed'].includes(stockTransfer.status) &&
    canExecute;

    return (
        <Space direction="vertical" size="middle" style={{display: 'flex'}}>
            {canRecordSerialNumbers &&(
                <Button
                    key="recordSerials"
                    onClick={() => setSerialModalVisible(true)}
                    icon={<CheckCircleOutlined/>}
                    type="default"
                    style={{backgroundColor: '#f0f2f5'}}>
                    Record Serial Numbers
                </Button>
            )}
            {stockTransfer.status === 'draft' && (
                <Popconfirm
                            title="Are you sure you want to confirm this tranasction?"
                            onConfirm={() => handleConfirmTransfer(stockTransfer.id)}
                            okText="Yes"
                            cancelText="No"
                        >
                <Button
                    key="confirm"
                    icon={<CheckCircleOutlined/>}
                    type="primary">
                    Confirm
                </Button>
                    </Popconfirm>
            )}
            {stockTransfer.status === 'confirmed' && canExecute && (
                <Popconfirm
                            title="Are you sure you want to execute this tranasction?"
                            onConfirm={() => handleExecuteTransfer(stockTransfer.id)}
                            okText="Yes"
                            cancelText="No"
                        >
                <Button
                    key="execute"
                    icon={<CarOutlined/>}
                    type="ghost"
                    style={{color: 'green', borderColor: 'green'}}>
                    Execute
                </Button>
                    </Popconfirm>
            )}
            {stockTransfer.status === 'ready_to_dispatch' && canExecute && (
                <Popconfirm
                            title="Are you sure you want to mark this tranasction as dispatched?"
                            onConfirm={() => handleDispatchTransfer(stockTransfer.id)}
                            okText="Yes"
                            cancelText="No"
                        >

                <Button
                    key="transport"
                    icon={<SendOutlined/>}
                    type="dashed">
                    Transport Details
                </Button>
                    </Popconfirm>
            )}
            {stockTransfer.status === 'dispatched' && canReceiveOrClose &&(
                <Popconfirm
                            title="Are you sure you want to mark this tranasction as received?"
                            onConfirm={() => handleReceiveTransfer(stockTransfer.id)}
                            okText="Yes"
                            cancelText="No"
                        >

                <Button
                    key="receive"
                    icon={<DownloadOutlined/>}
                    type="warning"
                    style={{backgroundColor: '#ffec3d', borderColor: '#ffec3d', color: '#000'}}>
                    Receive
                </Button>
                    </Popconfirm>
            )}
            {['dispatched', 'received'].includes(stockTransfer.status) && canReceiveOrClose && (
                <Popconfirm
                            title="Are you sure you want to close this tranasction?"
                            onConfirm={() => handleCloseTransfer(stockTransfer.id)}
                            okText="Yes"
                            cancelText="No"
                        >


                <Button
                    key="close"
                    icon={<CloseCircleOutlined/>}
                    danger>
                    Close
                </Button>
                    </Popconfirm>
            )}
            {['draft'].includes(stockTransfer.status) && (
                <Popconfirm
                            title="Are you sure you want to cancel this tranasction?"
                            onConfirm={() => handleCancelTransfer(stockTransfer.id)}
                            okText="Yes"
                            cancelText="No"
                        >
                <Button
                    key="cancel"
                    icon={<CloseCircleOutlined/>}
                    danger>
                    Cancel
                </Button>
                    </Popconfirm>
                )}
            {['confirmed'].includes(stockTransfer.status) && hasBranchAccess && (
                <Popconfirm
                            title="Are you sure you want to cancel this tranasction?"
                            onConfirm={() => handleCancelTransfer(stockTransfer.id)}
                            okText="Yes"
                            cancelText="No"
                        >
                <Button
                    key="cancel"
                    icon={<CloseCircleOutlined/>}
                    danger>
                    Cancel
                </Button>
                    </Popconfirm>
                )}
            {stockTransfer.status === 'confirmed' && hasElevatedAccess && (
                <Button
                    key="edit"
                    onClick={() => onEditStockTransfer(stockTransfer.id, companyId)}
                    icon={<CheckCircleOutlined />}
                    type="default"
                    style={{ backgroundColor: '#d9d9d9' }}>
                    Update Source Branch
                </Button>
            )}
            {stockTransfer.status === 'draft' && hasElevatedAccess && (
                <Button
                    key="edit"
                    onClick={() => onEditStockTransfer(stockTransfer.id, companyId)}
                    icon={<CheckCircleOutlined />}
                    type="default"
                    style={{ backgroundColor: '#d9d9d9' }}>
                    Update Source Branch
                </Button>
            )}

        </Space>
    );
};

export default StockTransferActions;

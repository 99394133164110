import React from 'react';
import {Empty, Table, Tooltip} from 'antd';
import {formatRupee, truncateText} from "../../utils/utils";
import {formatDate} from "../../utils/dateUtils";

const InvoicesTable = ({ invoices, loading, pagination, onPaginationChange, openDrawer }) => {
    const columns = [
        { title: '#', dataIndex: 'id', key: 'id' },
        {
            title: 'Invoice Number',
            dataIndex: 'invoice_number',
            key: 'invoice_number',
            render: text => <Tooltip title={text}>{truncateText(text, 20)}</Tooltip>
        },
        {
            title: 'Customer',
            dataIndex: 'customer_name',
            key: 'customer_name',
            render: text => <Tooltip title={text}>{truncateText(text, 20)}</Tooltip>
        },
        { title: 'Mobile Number', dataIndex: 'mobile_number', key: 'mobile_number' },
        {
            title: 'Date',
            dataIndex: 'invoice_date',
            key: 'invoice_date',
            render: value => formatDate(value)
        },
        {
            title: 'Due Date',
            dataIndex: 'due_date',
            key: 'due_date',
            render: value => formatDate(value)
        },
        {
            title: 'Invoice Amount',
            dataIndex: 'invoice_amount',
            key: 'invoice_amount',
            align: 'right',
            render: value => formatRupee(value)
        },
        // {
        //     title: 'Payment Made',
        //     dataIndex: 'payment_made',
        //     key: 'payment_made',
        //     align: 'right',
        //     render: value => formatRupee(value)
        // },
        {
            title: 'Payment Received',
            dataIndex: 'received_amount',
            key: 'received_amount',
            align: 'right',
            render: value => formatRupee(value)
        },
        {
            title: 'Payment Due',
            dataIndex: 'due_amount',
            key: 'due_amount',
            align: 'right',
            render: value => formatRupee(value)
        },
        // {
        //     title: 'Balance',
        //     dataIndex: 'balance',
        //     key: 'balance',
        //     align: 'right',
        //     render: value => formatRupee(value)
        // },
        { title: 'Status', dataIndex: 'status', key: 'status' },
        {
            title: 'Salesperson',
            dataIndex: 'salesperson_name',
            key: 'salesperson_name',
            render: text => <Tooltip title={text}>{truncateText(text, 15)}</Tooltip>
        },
        { title: 'Mode of Delivery', dataIndex: 'mode_of_delivery', key: 'mode_of_delivery' },
        { title: 'Transaction Reference', dataIndex: 'transaction_reference', key: 'transaction_reference' },
        { title: 'Hash', dataIndex: 'invoice_hash', key: 'invoice_hash' },
        { title: 'Invoice Type', dataIndex: 'invoice_type', key: 'invoice_type' }
    ];

    const handleTableChange = (pagination) => {
        onPaginationChange(pagination);
    };

    // Custom pagination summary
    const showPaginationTotal = (total, range) => {
        return `${range[0]}-${range[1]} of ${total} records`;
    };

    return (
        <Table
            columns={columns}
            dataSource={invoices}
            rowKey="id"
            loading={loading}
            pagination={{
                ...pagination,
                showTotal: showPaginationTotal,
                // showSizeChanger: true,
                // pageSizeOptions: ['10', '20', '50', '100'],
            }}
            onChange={handleTableChange}
            onRow={(record) => ({
                onClick: () => openDrawer(record)
            })}
            size="middle"
            scroll={{x: 'max-content'}}
            locale={{
                emptyText: (
                    <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description="No records available."
                    />
                ),
            }}
        />
    );
};

export default InvoicesTable;

import React, {useEffect, useState} from 'react';
import {Button, Form, Modal, Select, message, Spin} from 'antd';
import { updateSourceBranchApi } from './stockTransfersApi'; // Assume this API function exists

const { Option } = Select;

const SourceBranchUpdateModal = ({ visible, onClose, companies, stockTransferId, companyId, refreshData,
                                 setErrorMessage, setSuccessMessage}) => {
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const [branches, setBranches] = useState([]);
    const [branchesLoading, setBranchesLoading] = useState(false);

    useEffect(() => {
        if (companyId) {
            setBranchesLoading(true);
            const company = companies.find(c => c.id === companyId);
            setBranches(company ? company.branches : []);
            setBranchesLoading(false);
        }
    }, [companyId, companies]);

    const handleSubmit = async (values) => {
        setLoading(true);
        try {
            const response = await updateSourceBranchApi(stockTransferId, values.sourceBranch);
            if (response.success) {
                setSuccessMessage('Source branch updated successfully');
                refreshData(); // Refresh the list to show updated data
                onClose(); // Close the modal after successful update
            } else {
                setErrorMessage('Failed to update source branch');
            }
        } catch (error) {
            setErrorMessage('Error updating source branch: ' + error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal
            title="Update Source Branch"
            open={visible}
            onOk={form.submit}
            onCancel={onClose}
            confirmLoading={loading}
            footer={[
                <Button key="back" onClick={onClose}>
                    Cancel
                </Button>,
                <Button key="submit" type="primary" loading={loading} onClick={form.submit}>
                    Update
                </Button>,
            ]}
        >
            <Spin spinning={branchesLoading}>
                <Form form={form} layout="vertical" onFinish={handleSubmit}>
                    <Form.Item
                        name="sourceBranch"
                        label="Source Branch"
                        rules={[{ required: true, message: 'Please select the source branch' }]}
                    >
                        <Select placeholder="Select source branch">
                            {branches.map(branch => (
                                <Option key={branch.id} value={branch.id}>
                                    {branch.branch_name}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Form>
            </Spin>
        </Modal>
    );
};

export default SourceBranchUpdateModal;

import React, { useEffect, useState } from 'react';
import {AutoComplete, Button, Col, Input, message, Row} from 'antd';
import { fetchBranchesApi, createBranchApi, updateBranchApi } from './branchApi';
import { fetchCompaniesShortApi} from './companyApi';  // Import API call
import BranchesTable from './BranchesTable';
import BranchRegistration from './BranchRegistration';
import {useDispatch, useSelector} from "react-redux";
import {fetchCountriesWithStates} from "../../store/slices/locationSlice";

const { Search } = Input;

const BranchList = ({ companyId }) => {
    const dispatch = useDispatch();
    const {countries} = useSelector(state => state.configuration);

    const [branches, setBranches] = useState([]);
    const [companies, setCompanies] = useState([]);  // State to hold companies
    const [selectedCompanyId, setSelectedCompanyId] = useState(null);
    const [loading, setLoading] = useState(false);
    const [isRegistrationPage, setIsRegistrationPage] = useState(false);
    const [selectedBranch, setSelectedBranch] = useState(null);



    const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
    const [searchTerm, setSearchTerm] = useState('');
    const user = JSON.parse(localStorage.getItem('user')); // Retrieve user data
    const userRole = user?.user_role; // Extract user role

    const [successMessage, setSuccessMessage] = useState(null);
    const [infoMessage, setInfoMessage] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [messageApi, contextHolder] = message.useMessage(); // Initialize Ant Design message

    // Handle displaying success and error messages using useEffect
    useEffect(() => {
        if (successMessage) {
            messageApi.open({
                type: 'success',
                content: successMessage,
                duration: 2.5,
            });
            setSuccessMessage(null);
        }
        if (errorMessage) {
            messageApi.open({
                type: 'error',
                content: errorMessage,
                duration: 2.5,
            });
            setErrorMessage(null);
        }
        if (infoMessage) {
            messageApi.open({
                type: 'info',
                content: infoMessage,
                duration: 2.5,
            });
            setInfoMessage(null);
        }
    }, [successMessage, errorMessage, infoMessage, messageApi]);

    useEffect(() => {
        if (countries.length === 0) dispatch(fetchCountriesWithStates());

    }, [dispatch]);

    // Fetch branches from API
    const fetchBranches = async () => {
        setLoading(true);
        try {
            const data = await fetchBranchesApi();
            setBranches(data);
            setPagination(prev => ({ ...prev, total: data.count, current: 1 }));
        } catch (error) {
            setErrorMessage('Failed to load branches: ' + error.message);
        } finally {
            setLoading(false);
        }
    };

    // Fetch companies from API
    const fetchCompanies = async () => {
        try {
            const data = await fetchCompaniesShortApi();
            setCompanies(data.map(company => ({
                value: company.id.toString(),
                label: company.company_name,
                code_str: company.company_code,
            })));
        } catch (error) {
            setErrorMessage('Failed to load companies: ' + error.message);
        }
    };

   useEffect(() => {
        fetchCompanies();
        fetchBranches();
    }, []);

    const onCompanySelect = (value) => {
        setSelectedCompanyId(value);
        fetchBranches();
    };

    const handleSearch = (value) => {
        setSearchTerm(value);
        fetchBranches();  // Optionally add search functionality in API and call here
    };



    const handleTableChange = (pagination) => {
        setPagination(prev => ({
            ...prev,
            current: pagination.current,
            pageSize: pagination.pageSize,
        }));
    };

    const handleAddBranch = () => {


        if (userRole === 'super_admin') {
            console.log('Add Country Modal Triggered');
            setSelectedBranch(null);
        setIsRegistrationPage(true);
            // Add your modal logic here
        } else {
            setErrorMessage('You do not have permission to add a branch');
        }

    };

    const handleEditBranch = (branch) => {
        if (userRole === 'super_admin') {
            console.log('Add Country Modal Triggered');
            setSelectedBranch(branch);
        setIsRegistrationPage(true);
            // Add your modal logic here
        } else {
            setErrorMessage('You do not have permission to edit branch details');
        }
        };

    const handleRegistrationSubmit = async (branchData) => {
        console.log('Branch Data:', branchData);
        setLoading(true);
        try {
            const response = selectedBranch ? await updateBranchApi(selectedBranch.id, branchData) : await createBranchApi(branchData);
            if (response.success) {
                setSuccessMessage('Branch processed successfully');
                setIsRegistrationPage(false);
                fetchBranches();
            } else {
                setErrorMessage('Failed to process branch: ' + response.message);
            }
        } catch (error) {
            setErrorMessage('Failed to process branch data: ' + error.message);
        } finally {
            setLoading(false);
        }
    };

    if (isRegistrationPage) {
        return (
            <BranchRegistration
                onCancel={() => setIsRegistrationPage(false)}
                onSubmit={handleRegistrationSubmit}
                branch={selectedBranch}
                companies={companies} // Pass companies to the registration form for selection
                countries={countries}
                setSuccessMessage={setSuccessMessage}
                setErrorMessage={setErrorMessage}
                setInfoMessage={setInfoMessage}
            />
        );
    }

    return (
        <div style={{ padding: '24px' }}>
            {contextHolder}
            <Row justify="space-between" align="middle" style={{ marginBottom: 16 }}>
                <Col span={14}>
                    <Search
                        placeholder="Search by branch name"
                        onSearch={handleSearch}
                        style={{ width: 'calc(60% - 8px)', marginRight: 8 }}
                        enterButton
                    />
                    <AutoComplete
                        style={{ width: '40%' }}
                        options={companies}
                        onSelect={onCompanySelect}
                        placeholder="Select a company"
                        notFoundContent="No companies found"
                    />
                </Col>
                <Col>
                    <Button type="primary" onClick={handleAddBranch}>
                        Add Branch
                    </Button>
                </Col>
            </Row>
            <BranchesTable
                data={branches}
                loading={loading}
                pagination={pagination}
                onTableChange={handleTableChange}
                onEditBranch={handleEditBranch}
            />
        </div>
    );
};

export default BranchList;

import React from 'react';
import {Empty, Table, Typography, Tag} from 'antd';

const {Paragraph} = Typography;

const STATUS_COLOR_MAP = {
  available: 'green',       // success
  sold: 'red',              // danger
  damaged: 'orange',        // or 'volcano'
  new: 'blue',              // or 'geekblue'
  not_available: 'magenta', // or 'volcano'
  in_transit: 'gold',       // amber-like
  booked: 'cyan',           // or 'blue'
};

const SerialNumbersTable = ({data, loading, pagination, onTableChange, onPageChange}) => {
    const columns = [
        {title: '#', dataIndex: 'id', key: 'id'},
        {title: 'Brand', dataIndex: 'brand_name', key: 'brand_name'},
        {title: 'Item Code', dataIndex: 'item_code', key: 'item_code'},
        {
            title: 'Serial Number',
            dataIndex: 'serial_number',
            key: 'serial_number',
            // Use render to wrap the serial number in a copyable component
            render: (serialNumber) => (
                <Paragraph
                    copyable
                >
                    {serialNumber}
                </Paragraph>
            ),
        },
        {
      title: 'Status',
      dataIndex: 'status_display',
      key: 'status_display',
            align: 'center',
      render: (statusText) => {
        // Convert any spaces or uppercase to a consistent format, e.g. 'not_available'
        // If your backend returns something like 'Not Available', this helps match the map.
        const normalizedStatus = statusText
          .toLowerCase()
          .replace(/\s/g, '_');

        const color = STATUS_COLOR_MAP[normalizedStatus] || 'default';

        return (
          <Tag color={color}>
            {statusText}
          </Tag>
        );
      },
    },
        {title: 'Company', dataIndex: 'company_code', key: 'company_code'},
        {title: 'Branch', dataIndex: 'branch_name', key: 'branch_name'},

        // Add more columns as needed
    ];

    // Custom pagination summary
    const showPaginationTotal = (total, range) => {
        return `${range[0]}-${range[1]} of ${total} records`;
    };


    return (
        <Table
            columns={columns}
            dataSource={data}
            loading={loading}
            rowKey="id"
            pagination={{
                ...pagination,
                showTotal: showPaginationTotal,
                onChange: onPageChange,
            }}
            size="small"
            scroll={{x: 'max-content'}}
            locale={{
                emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No records available."/>
            }}
        />
    );
};

export default SerialNumbersTable;

import React, {useEffect, useState} from 'react';
import {Empty, Input, message, Table} from 'antd';
import {fetchCycleItemsApi} from "./partnerPaymentsApi";
import {formatRupee} from "../../utils/utils";
import {formatDate} from "../../utils/dateUtils";

const {Search} = Input;

const CycleItemsList = () => {
    const [cycleItems, setCycleItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const [cycleId, setCycleId] = useState('');
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        total: 0
    });

    useEffect(() => {
        fetchCycleItems(cycleId, pagination.current, pagination.pageSize);
    }, [cycleId, pagination.current, pagination.pageSize]);

    const fetchCycleItems = async (id, page, pageSize) => {
        setLoading(true);
        try {
            const data = await fetchCycleItemsApi(id, page, pageSize);
            setCycleItems(data.results);
            setPagination(prev => ({
                ...prev,
                total: data.count,
                current: page,
                pageSize: pageSize
            }));
        } catch (error) {
            message.error('Failed to fetch cycle items');
        } finally {
            setLoading(false);
        }
    };

    const columns = [
        {title: 'ID', dataIndex: 'id', key: 'id', align: 'center'},
        {
            title: 'Invoice Date', dataIndex: 'invoice_date', key: 'invoice_date', align: 'center',
            render: formatDate
        },
        {title: 'Brand', dataIndex: 'item_brand', key: 'item_brand'},
        {title: 'Item Code', dataIndex: 'item_code', key: 'item_code'},
        {title: 'Quantity', dataIndex: 'sold_quantity', key: 'sold_quantity'},
        {
            title: 'Sale Rate', dataIndex: 'sale_rate', key: 'sale_rate',
            render: value => formatRupee(value), align: 'right'
        },
        {
            title: 'Purchase Rate', dataIndex: 'purchase_rate', key: 'purchase_rate',
            render: value => formatRupee(value), align: 'right'
        },
        {title: 'Invoice Number', dataIndex: 'invoice_number', key: 'invoice_number'},
        {title: 'Supplier Code', dataIndex: 'supplier_partner_company_code', key: 'supplier_partner_company_code'},
        {title: 'Franchise Code', dataIndex: 'franchise_partner_company_code', key: 'franchise_partner_company_code'},
        {title: 'Cycle ID', dataIndex: 'payment_cycle_id', key: 'payment_cycle_id'},
        {
            title: 'Sale Value', dataIndex: 'sale_value', key: 'sale_value',
            render: value => formatRupee(value), align: 'right'
        },
        {
            title: 'Purchase Cost', dataIndex: 'purchase_cost', key: 'purchase_cost',
            render: value => formatRupee(value), align: 'right'
        },
        {
            title: 'Gross Margin', dataIndex: 'gross_margin', key: 'gross_margin',
            render: value => formatRupee(value), align: 'right'
        },
        {
            title: 'Gross Margin %', dataIndex: 'gross_margin_percentage', key: 'gross_margin_percentage',
            render: value => `${value.toFixed(2)}%`, align: 'right'
        },
        {
            title: 'Franchise Commission',
            dataIndex: 'ed_commission_on_sale',
            key: 'ed_commission_on_sale',
            render: value => formatRupee(value),
            align: 'right'
        },
        {
            title: 'Supplier Commission',
            dataIndex: 'ed_commission_on_purchase',
            key: 'ed_commission_on_purchase',
            render: value => formatRupee(value),
            align: 'right'
        },
        {
            title: 'Total Commission',
            dataIndex: 'total_ed_commission',
            key: 'total_ed_commission',
            render: value => formatRupee(value),
            align: 'right'
        },
        {title: 'Serial Number', dataIndex: 'serial_number', key: 'serial_number'},
        {title: 'Serial Number ID', dataIndex: 'serial_number_id', key: 'serial_number_id'},
    ];


    const handleSearch = value => {
        setCycleId(value.trim());  // Trim whitespace
        setPagination(prev => ({...prev, current: 1}));
    };

    const handleTableChange = newPagination => {
        fetchCycleItems(cycleId, newPagination.current, newPagination.pageSize);
    };

    // Custom pagination summary
    const showPaginationTotal = (total, range) => {
        return `${range[0]}-${range[1]} of ${total} records`;
    };

    return (
        <div>
            <Search
                placeholder="Enter cycle ID to filter"
                onSearch={handleSearch}
                style={{width: 200, marginBottom: 16}}
                enterButton
            />
            <Table
                columns={columns}
                dataSource={cycleItems}
                rowKey="id"
                loading={loading}
                pagination={{
                    ...pagination,
                    showTotal: showPaginationTotal,
                    // showSizeChanger: true,
                    // pageSizeOptions: ['10', '20', '50', '100'],
                }}
                onChange={handleTableChange}
                size="small"
                scroll={{x: 'max-content'}}
                locale={{
                    emptyText: (
                        <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description="No records available."
                        />
                    ),
                }}
            />
        </div>
    );
};

export default CycleItemsList;
